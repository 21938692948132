.dashboard-view {
  max-width: 400px;
  overflow: hidden;
  padding: 4px;
}

.dashboard-index-container {
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.dashboard-large .dashboard-index-container {
  padding: 15px;
}

.dashboard-index-container.no-border {
  border-bottom: none
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .button-container {
    justify-content: center;
  }
}