.account-discord-steps .ant-steps-item-content{
    padding-bottom:20px;
}
.account-discord-steps.aggrement-not-accepted .ant-steps-item-title{
    color: #aaa !important;
    cursor: default !important;
}
.account-discord-steps.aggrement-not-accepted .ant-steps-item-description a{
    color: #aaa !important;
    cursor: default !important;
}