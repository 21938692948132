/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/*
Author         : G-axon
Template Name  : Wieldy Antd React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
        02. Colors Class

    B. Base
        01. Base Styles
        02. Row Col Styles
        03. Margin Padding Styles
        04. Typography Styles
        05. Flex Styles

    C. Layouts
        01. Ant Layout Style
        02. Header Style
        03. Sidebar Style
        04. Navbar(Ant Menu) Style
        05. Footer Styles
        06. Customizer Style
        07. Drawer Style

    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. BackTop Styles
        04. Badges Styles
        05. Button Styles
        06. Cards Styles
        07. Cascader Styles
        08. Chart Styles
        09. Form Styles
        10. Icon Styles
        11. Input Styles
        12. List Styles
        13. Loader Styles
        14. Pagination Styles
        15. Pickers Styles
        16. Progress Styles
        17. Slider Styles
        18. Steps Styles
        19. Tables Styles
        20. Tabs Styles
        21. Time Lines Style

    E. Apps
        01. Apps Style
        02. Mails Apps Styles
        03. Chat Apps Styles
        04. Contact Apps Styles
        05. Calendar Apps Style
        06. Profile App Style
        07. Wall App Style

    F. Pages
        01. Callout Styles
        02. E-commerce Styles
        03. Pricing Tables Styles
        04. Login Styles
        05. Dashboard Styles
        06. Error Styles
        07. Editor Styles
        08. Testimonial Styles
        09. Algolia Styles

  =============================================================*/
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: #444;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: #f5f5f5;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #038fde;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #28aaeb;
}
a:active {
  color: #006eb8;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: #595959;
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #038fde;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #038fde;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #038fde;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #038fde;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 6px;
}
.ant-alert-content {
  flex: 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fff7e6;
  border: 1px solid #ffd591;
}
.ant-alert-warning .ant-alert-icon {
  color: #fa8c16;
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
  color: #f5222d;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: #595959;
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: #595959;
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #535353;
  font-size: 16px;
}
.ant-alert-message {
  color: #535353;
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #038fde;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.ant-anchor-ink-ball-visible {
  display: inline-block;
}
.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 4px 0 4px 16px;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: #444;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #038fde;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 6px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: #595959;
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: #444;
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f5222d;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #038fde;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #f5222d;
}
.ant-badge-status-warning {
  background-color: #fa8c16;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: #444;
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #038fde;
  border-radius: 4px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 4px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentcolor transparent transparent currentcolor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  color: #595959;
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-breadcrumb a {
  color: #595959;
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #444;
}
.ant-breadcrumb li:last-child {
  color: #444;
}
.ant-breadcrumb li:last-child a {
  color: #444;
}
li:last-child > .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: #595959;
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.3;
  position: relative;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  padding: 7.9px 15px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: solid 1px #d9d9d9;
  padding: 0 15px;
  height: 36px;
  font-size: 14px;
  line-height: 34px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 15px;
  color: #444;
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 8.6px 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 3.2px 7px;
  font-size: 12px;
  border-radius: 4px;
}
.ant-btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .ant-btn {
    margin-bottom: 10px;
  }
}
.ant-btn:focus,
.ant-btn:hover {
  text-decoration: none;
}
.ant-btn:focus,
.ant-btn.focus {
  outline: 0;
}
.ant-btn.disabled,
.ant-btn:disabled {
  opacity: 0.65;
}
.ant-btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.gx-btn-list .ant-btn {
  margin-bottom: 6px;
}
.gx-sub-popover .ant-btn {
  border: 0 none;
  margin-bottom: 5px;
}
.gx-sub-popover .ant-btn:focus,
.gx-sub-popover .ant-btn:hover {
  background-color: #f5f5f5;
}
.gx-sub-popover .ant-btn:not(:last-child) {
  margin-right: 5px;
}
.gx-login-content .ant-btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #006eb8;
  border-color: #006eb8;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #038fde;
  background: #038fde;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  color: #ffffff !important;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #28aaeb;
  background: #28aaeb;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #006eb8;
  background: #006eb8;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffffff !important;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-btn-primary.disabled,
.ant-btn-primary:disabled {
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #28aaeb;
  border-left-color: #28aaeb;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #28aaeb;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #28aaeb;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: #444;
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #006eb8;
  border-color: #006eb8;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: #444;
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #006eb8;
  border-color: #006eb8;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #f5222d;
  background: #f5222d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #cf1322;
  background: #cf1322;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #038fde;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #006eb8;
  border-color: #006eb8;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: #444;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #006eb8;
  border-color: #006eb8;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #444;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: #444;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #f5222d;
  border-color: #f5222d;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #cf1322;
  border-color: #cf1322;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #f5222d;
  background: #f5222d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #cf1322;
  background: #cf1322;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #f5222d;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #006eb8;
  border-color: #006eb8;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #cf1322;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #f5222d;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #28aaeb;
  border-color: #28aaeb;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #006eb8;
  border-color: #006eb8;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff4d4f;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #cf1322;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 36px;
  height: 36px;
  padding: 6.6px 0;
  font-size: 16px;
  border-radius: 4px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 7.3px 0;
  font-size: 18px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 1.9px 0;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 36px;
  padding: 7.9px 18px;
  font-size: 14px;
  border-radius: 36px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 8.6px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 1.9px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 36px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 4px 4px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 4px 0 0 4px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 4px 4px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 4px 0 0 4px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #28aaeb;
  border-color: #28aaeb;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #006eb8;
  border-color: #006eb8;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #038fde;
  border-color: #038fde;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #28aaeb;
  border-color: #28aaeb;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #006eb8;
  border-color: #006eb8;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #f5222d;
  border-color: #f5222d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #cf1322;
  border-color: #cf1322;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #f5222d;
  border-color: #f5222d;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #cf1322;
  border-color: #cf1322;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #f5222d;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff4d4f;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #cf1322;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 34px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}
.ant-btn-compact-item:hover,
.ant-btn-compact-item:focus,
.ant-btn-compact-item:active {
  z-index: 2;
}
.ant-btn-compact-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: #28aaeb;
  content: ' ';
}
.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])::after {
  right: -1px;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.ant-btn-compact-vertical-item:hover,
.ant-btn-compact-vertical-item:focus,
.ant-btn-compact-vertical-item:active {
  z-index: 2;
}
.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: #28aaeb;
  content: ' ';
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #28aaeb;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #28aaeb;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 6px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 6px 6px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e6faff;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #038fde;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #e8e8e8;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: #444;
  line-height: 1.3;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #038fde;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #444;
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 6px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: #444;
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: #444;
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid .ant-card-body {
  display: flex;
  flex-wrap: wrap;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 6px 6px 0 0;
}
.ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  margin: 12px 0;
  color: #595959;
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.3;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #038fde;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: #595959;
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #038fde;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #e8e8e8;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  display: flex;
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  flex: 1;
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: #595959;
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  user-select: none;
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li button::after {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  content: '';
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 0;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #038fde;
}
.ant-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
  visibility: visible;
}
.ant-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-cascader-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}
.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-checkbox-rtl {
  direction: rtl;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 8px;
}
.ant-cascader {
  width: 184px;
}
.ant-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.ant-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}
.ant-cascader-menu {
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -4px 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #e6faff;
}
.ant-cascader-menu-item-content {
  flex: auto;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: #595959;
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item-keyword {
  color: #f5222d;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  margin-right: -1px;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-last-item).ant-cascader-compact-item-rtl {
  margin-left: -1px;
}
.ant-cascader-compact-item:hover,
.ant-cascader-compact-item:focus,
.ant-cascader-compact-item:active {
  z-index: 2;
}
.ant-cascader-compact-item[disabled] {
  z-index: 0;
}
.ant-cascader-compact-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-last-item).ant-cascader {
  border-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item):not(.ant-cascader-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item):not(.ant-cascader-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-first-item:not(.ant-cascader-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-cascader-compact-item.ant-cascader.ant-cascader-compact-item-rtl.ant-cascader-compact-last-item:not(.ant-cascader-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-cascader-rtl .ant-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #038fde;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 6px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 6px 6px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: #535353;
  line-height: 1.3;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
  flex: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: none;
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-icon-collapsible-only .ant-collapse-expand-icon {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: #444;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 6px 6px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: #595959;
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: #595959;
}
.ant-comment-content-author-name > *:hover {
  color: #595959;
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: #595959;
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: #595959;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not(.ant-picker-disabled):hover {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff4d4f;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not(.ant-picker-disabled):hover {
  background-color: #fff;
  border-color: #fa8c16;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffa940;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 8px 11px 8px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 1.9px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 9px 11px 9px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 2px 7px 2px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: #595959;
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: #595959;
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #038fde;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #038fde;
  background: #e6faff;
  border-color: #7ddcff;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #e8e8e8;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}
.ant-picker-compact-item:hover,
.ant-picker-compact-item:focus,
.ant-picker-compact-item:active {
  z-index: 2;
}
.ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}
.ant-picker-compact-item[disabled] {
  z-index: 0;
}
.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item:not(.ant-picker-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item:not(.ant-picker-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #038fde;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: #535353;
  border-bottom: 1px solid #e8e8e8;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: #444;
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #038fde;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: #444;
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: #444;
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 6px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #038fde;
  border-radius: 6px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6faff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #038fde;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6faff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #4abcfd;
  border-bottom: 1px dashed #4abcfd;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #96d8fe;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 6px 0 0 6px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 6px 6px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #96d8fe;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #4abcfd;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #4abcfd;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #038fde;
}
.ant-picker-today-btn:hover {
  color: #28aaeb;
}
.ant-picker-today-btn:active {
  color: #006eb8;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #4abcfd;
  border-radius: 6px 0 0 6px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #4abcfd;
  border-radius: 0 6px 6px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #4abcfd;
  border-radius: 0 6px 6px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #4abcfd;
  border-radius: 6px 0 0 6px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #038fde;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #e8e8e8;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #e8e8e8;
}
.ant-picker-time-panel-column-active {
  background: rgba(230, 250, 255, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: #444;
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6faff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 6px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 6px 6px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 6px 0 0 6px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #4abcfd;
  border-left: none;
  border-radius: 0 6px 6px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #4abcfd;
  border-radius: 6px 0 0 6px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #4abcfd;
  border-left: 1px dashed #4abcfd;
  border-radius: 6px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: #535353;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: #444;
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 6px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: #535353;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #e8e8e8;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: #444;
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}
.ant-drawer-inline {
  position: absolute;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: auto;
}
.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all 0.3s;
}
.ant-drawer-content-wrapper-hidden {
  display: none;
}
.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom > .ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ant-drawer-header {
  display: flex;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 22px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.ant-drawer-extra {
  flex: none;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: #595959;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-title {
  flex: 1;
  margin: 0;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 24px;
  overflow: auto;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
}
.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}
.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all 0.3s;
}
.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}
.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}
.ant-drawer-mask-motion-leave {
  opacity: 1;
}
.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}
.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}
.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}
.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}
.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all 0.3s;
}
.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}
.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}
.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #f5222d;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #f5222d;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #595959;
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #038fde;
  background-color: #e6faff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: #595959;
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #038fde;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #013;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #038fde;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 20px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.3;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #f5222d;
}
.ant-form-item-explain-warning {
  color: #fa8c16;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #fa8c16;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #f5222d;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: #595959;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 20px;
  vertical-align: top;
}
.ant-form-item-with-help {
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.05em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 36px;
  color: #535353;
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: #595959;
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: #595959;
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 36px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: #595959;
  font-size: 14px;
  line-height: 1.3;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item-explain-connected {
  width: 100%;
}
.ant-form-item-extra {
  min-height: 20px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.ant-form-item-feedback-icon-error {
  color: #f5222d;
}
.ant-form-item-feedback-icon-warning {
  color: #fa8c16;
}
.ant-form-item-feedback-icon-validating {
  color: #038fde;
}
.ant-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-appear,
.ant-show-help-enter {
  opacity: 0;
}
.ant-show-help-appear-active,
.ant-show-help-enter-active {
  opacity: 1;
}
.ant-show-help-leave {
  opacity: 1;
}
.ant-show-help-leave-active {
  opacity: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1081;
  width: 100%;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  font-feature-settings: 'tnum';
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-image-preview-operations-operation:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: fixed;
  top: 50%;
  right: 8px;
  z-index: 1081;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.3s;
  pointer-events: auto;
}
.ant-image-preview-switch-left:hover,
.ant-image-preview-switch-right:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left-disabled:hover,
.ant-image-preview-switch-right-disabled:hover {
  color: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon,
.ant-image-preview-switch-left-disabled:hover > .anticon,
.ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 8px;
}
.ant-image-preview-switch-right {
  right: 8px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 1.9px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #28aaeb;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: rgba(255, 255, 255, 0);
}
.ant-input-affix-wrapper > .ant-input {
  font-size: inherit;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 0;
}
.ant-input-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: #595959;
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: #595959;
}
.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: #444;
}
.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #f5222d;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #f5222d;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #fa8c16;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #fa8c16;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #f5222d;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #f5222d;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #fa8c16;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #fa8c16;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #fa8c16;
  border-color: #fa8c16;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}
.ant-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 1.9px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -8.9px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #038fde;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 1.9px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-input-number-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 6px 0 0 6px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 6px 6px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 6px 0 0 6px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 6px 0 0 6px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 6px 6px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 6px 6px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 6px 0 0 6px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 6px 6px 0;
}
.ant-input-group > .ant-input-rtl:first-child {
  border-radius: 0 6px 6px 0;
}
.ant-input-group > .ant-input-rtl:last-child {
  border-radius: 6px 0 0 6px;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 6px 6px 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: #595959;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 36px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: #595959;
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-compact-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-compact-item:hover,
.ant-input-compact-item:focus,
.ant-input-compact-item:active {
  z-index: 2;
}
.ant-input-compact-item[disabled] {
  z-index: 0;
}
.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item).ant-input {
  border-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-first-item:not(.ant-input-compact-last-item):not(.ant-input-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-last-item:not(.ant-input-compact-first-item):not(.ant-input-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-first-item:not(.ant-input-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-compact-item.ant-input.ant-input-compact-item-rtl.ant-input-compact-last-item:not(.ant-input-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #28aaeb;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #28aaeb;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.2998;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 6px 6px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: #595959;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 36px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-item-rtl):not(.ant-input-compact-last-item) .ant-input-group-addon .ant-input-search-button {
  margin-right: -1px;
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input,
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover,
.ant-input-search.ant-input-compact-item > .ant-input:hover,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus,
.ant-input-search.ant-input-compact-item > .ant-input:focus,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active,
.ant-input-search.ant-input-compact-item > .ant-input:active,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}
.ant-input-search.ant-input-compact-item-rtl:not(.ant-input-compact-last-item) .ant-input-group-addon:last-child .ant-input-search-button {
  margin-left: -1px;
  border-radius: 0;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover,
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary):hover {
  border-left-color: #28aaeb;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #28aaeb;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 6px 0 0 6px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 36px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  padding-inline-start: 11px;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-affix-wrapper:focus,
.ant-input-rtl .ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 1.9px 7px;
}
.ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #28aaeb;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-input-number-prefix,
.ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.ant-input-number-prefix {
  margin-inline-end: 4px;
}
.ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #f5222d;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #f5222d;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #fa8c16;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #fa8c16;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #f5222d;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #f5222d;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #fa8c16;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #fa8c16;
}
.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #fa8c16;
  border-color: #fa8c16;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 1.9px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -8.9px -11px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #038fde;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 1.9px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group > .ant-input-number-rtl:first-child,
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 6px 6px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 6px 6px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 6px 6px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 6px 6px 0;
}
.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 6px 6px 0;
}
.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 6px 6px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: #595959;
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #28aaeb;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: #595959;
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 34px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 6px;
  outline: 0;
  transition: all 0.3s linear;
  appearance: textfield !important;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 6px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down,
.ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
  border-top: 1px solid #d9d9d9;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #f5222d;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  margin-right: -1px;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-last-item).ant-input-number-compact-item-rtl {
  margin-left: -1px;
}
.ant-input-number-compact-item:hover,
.ant-input-number-compact-item:focus,
.ant-input-number-compact-item:active {
  z-index: 2;
}
.ant-input-number-compact-item.ant-input-number-focused {
  z-index: 2;
}
.ant-input-number-compact-item[disabled] {
  z-index: 0;
}
.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-last-item).ant-input-number {
  border-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item):not(.ant-input-number-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item):not(.ant-input-number-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-first-item:not(.ant-input-number-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-compact-item.ant-input-number.ant-input-number-compact-item-rtl.ant-input-number-compact-last-item:not(.ant-input-number-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f5f5f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 76px;
  padding: 0 32px;
  color: #444;
  line-height: 76px;
  background: #fefefe;
}
.ant-layout-footer {
  padding: 34.5px 32px;
  color: #444;
  font-size: 14px;
  background: #fffffe;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #013;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 30px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 76px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 28px;
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  background: #013;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 6px 0 0 6px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: #444;
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #444;
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: #444;
}
.ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: #444;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
}
.ant-list-item-meta-title > a {
  color: #444;
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #038fde;
}
.ant-list-item-meta-description {
  color: #595959;
  font-size: 14px;
  line-height: 1.3;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: #595959;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: #595959;
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: #535353;
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #f5222d;
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-error .ant-input-prefix {
  color: #f5222d;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #fa8c16;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-warning .ant-input-prefix {
  color: #fa8c16;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.3;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 1.9px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 34px;
  margin: 0;
  padding: 7.9px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: #444;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: #444;
  font-weight: normal;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 6px 6px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 6px 6px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: #444;
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #f5222d;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #f5222d;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #f5222d;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #f5222d;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #f5222d;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #f5222d;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fa8c15;
  background-color: #f5222d;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.3;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: #444;
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #a6eaff;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.3;
  padding: 8px 16px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6faff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: #444;
}
.ant-menu-item a:hover {
  color: #038fde;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: #444;
}
.ant-menu-item > .ant-badge a:hover {
  color: #038fde;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #038fde;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #038fde;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6faff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
.ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
  box-shadow: 0 0 0 2px #a6eaff;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 6px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 6px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #444;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #038fde;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #038fde;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: #444;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #038fde;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #038fde;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 42px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 42px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 42px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 42px;
  line-height: 42px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #038fde;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #006eb8;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #006eb8;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #e2ecf1;
  background: #013;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #e2ecf1;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #013;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #ffffff;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #e2ecf1;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fa8c15;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fa8c15;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #ffffff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #e8e8e8;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #f5222d;
}
.ant-message-warning .anticon {
  color: #fa8c16;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #595959;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 54px;
  height: 54px;
  font-size: 16px;
  font-style: normal;
  line-height: 54px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: #444;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.3;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: #444;
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #fa8c16;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-confirm .ant-zoom-leave .ant-modal-confirm-btns {
  pointer-events: none;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.3;
  word-wrap: break-word;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: #535353;
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #fa8c16;
}
.anticon.ant-notification-notice-icon-error {
  color: #f5222d;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #595959;
  outline: none;
}
.ant-notification-notice-close:hover {
  color: #353535;
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #038fde;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  color: #000;
}
.ant-page-header-back-button:focus-visible,
.ant-page-header-back-button:hover {
  color: #28aaeb;
}
.ant-page-header-back-button:active {
  color: #006eb8;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: #535353;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: #595959;
  font-size: 14px;
  line-height: 1.3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #444;
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: #038fde;
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: #038fde;
}
.ant-pagination-item:focus-visible {
  border-color: #038fde;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #038fde;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #038fde;
}
.ant-pagination-item-active a {
  color: #038fde;
}
.ant-pagination-item-active:hover {
  border-color: #28aaeb;
}
.ant-pagination-item-active:focus-visible {
  border-color: #28aaeb;
}
.ant-pagination-item-active:hover a {
  color: #28aaeb;
}
.ant-pagination-item-active:focus-visible a {
  color: #28aaeb;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #038fde;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: #444;
  font-family: 'Lato', sans-serif;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: #444;
  cursor: pointer;
  user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #28aaeb;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #038fde;
  border-color: #038fde;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #038fde;
  border-color: #038fde;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 36px;
  margin-left: 8px;
  line-height: 36px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 7.9px 11px;
  color: #444;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  width: 50px;
  height: 36px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 8.6px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 1.9px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #038fde;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  padding: 1.9px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  max-width: 100vw;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #535353;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-popover-inner-content {
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
  color: #444;
}
.ant-popover-message {
  display: flex;
  padding: 4px 0 12px;
  color: #444;
  font-size: 14px;
}
.ant-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: #fa8c16;
  font-size: 14px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #038fde;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #038fde;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #038fde;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #444;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #038fde;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.12);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #038fde;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #038fde;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 36px;
  margin: 0;
  padding: 0 15px;
  color: #444;
  font-size: 14px;
  line-height: 34px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: #444;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 6px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #038fde;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #038fde;
  background: #fff;
  border-color: #038fde;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #038fde;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #038fde;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #28aaeb;
  border-color: #28aaeb;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #28aaeb;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #006eb8;
  border-color: #006eb8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #006eb8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #038fde;
  border-color: #038fde;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #28aaeb;
  border-color: #28aaeb;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #006eb8;
  border-color: #006eb8;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.12);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 6px 6px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #28aaeb;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 6px 0 0 6px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #f5222d;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #fa8c16;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: #535353;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: #595959;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.ant-segmented.ant-segmented-block {
  display: flex;
}
.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}
.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}
.ant-segmented-item:hover,
.ant-segmented-item:focus {
  color: #262626;
}
.ant-segmented-item-label {
  min-height: 32px;
  padding: 0 11px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented-item-icon + * {
  margin-left: 6px;
}
.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}
.ant-segmented-item-disabled,
.ant-segmented-item-disabled:hover,
.ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}
.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 34px;
  transition: all 0.3s, visibility 0s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 36px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 34px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 34px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2.9px 7.9px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 4px 0;
  line-height: 20.2px;
  visibility: hidden;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 20.2px;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 18.2px;
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
  margin-inline-end: 7.9px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  align-items: center;
  color: #595959;
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 3.1px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 20.2px;
  font-family: 'Lato', sans-serif;
  line-height: 20.2px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 24.2px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 24.2px;
  line-height: 22.2px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 24.2px;
  line-height: 24.2px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 24.2px;
  line-height: 22.2px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 8.2px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 8.2px;
  line-height: 6.2px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 8.2px;
  line-height: 8.2px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 8.2px;
  line-height: 6.2px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #f5222d !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #fff;
  border-color: #fa8c16 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  border-color: #ffa940;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-flex;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
  margin-inline-end: 8px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: #595959;
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: #444;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: #595959;
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #444;
  font-weight: 600;
  background-color: #e6faff;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #038fde;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
  width: 100%;
}
.ant-select-compact-item:not(.ant-select-compact-last-item) {
  margin-right: -1px;
}
.ant-select-compact-item:not(.ant-select-compact-last-item).ant-select-compact-item-rtl {
  margin-right: 0;
  margin-left: -1px;
}
.ant-select-compact-item:hover > *,
.ant-select-compact-item:focus > *,
.ant-select-compact-item:active > * {
  z-index: 2;
}
.ant-select-compact-item.ant-select-focused > * {
  z-index: 2;
}
.ant-select-compact-item[disabled] > * {
  z-index: 0;
}
.ant-select-compact-item:not(.ant-select-compact-first-item):not(.ant-select-compact-last-item).ant-select > .ant-select-selector {
  border-radius: 0;
}
.ant-select-compact-item.ant-select-compact-first-item.ant-select:not(.ant-select-compact-last-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-compact-item.ant-select-compact-last-item.ant-select:not(.ant-select-compact-first-item):not(.ant-select-compact-item-rtl) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-first-item.ant-select-compact-item-rtl:not(.ant-select-compact-last-item) > .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-select-compact-item.ant-select.ant-select-compact-last-item.ant-select-compact-item-rtl:not(.ant-select-compact-first-item) > .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 7.9px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 6px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 6px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  /* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
  z-index: 0;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 6px;
  width: 72px;
  min-width: 72px;
  height: 36px;
  line-height: 36px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-square {
  width: 36px;
  min-width: 36px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 36px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-square {
  width: 40px;
  min-width: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-square {
  width: 24px;
  min-width: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 180px;
  min-width: 180px;
  height: 36px;
  line-height: 36px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #7ddcff;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #7ddcff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #35a5e5;
  outline: none;
  box-shadow: 0 0 0 5px rgba(3, 143, 222, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #038fde;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #52c6f7;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #52c6f7;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #595959;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: #444;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #81c7ef;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-compact {
  display: inline-flex;
}
.ant-space-compact-block {
  display: flex;
  width: 100%;
}
.ant-space-compact-vertical {
  flex-direction: column;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-compact-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #038fde;
  font-size: 0;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: #595959;
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #038fde;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: #595959;
  font-size: 14px;
}
.ant-statistic-skeleton {
  padding-top: 16px;
}
.ant-statistic-content {
  color: #535353;
  font-size: 24px;
  font-family: 'Lato', sans-serif;
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #038fde;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #444;
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: #595959;
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: #595959;
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #595959;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #595959;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #038fde;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #038fde;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #038fde;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #535353;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #444;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #038fde;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #038fde;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #038fde;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #038fde;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #444;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #038fde;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #595959;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #038fde;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #f5222d;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #038fde;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #038fde;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #038fde;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #038fde;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: #595959;
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 11px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.3;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.3;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #038fde;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-right: 4px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 19px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 15px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 4px;
  left: 19px;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child,
.ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-small > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px;
}
.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e6faff;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #038fde;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #038fde;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}
.ant-table.ant-table-middle .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}
.ant-table.ant-table-small .ant-table-selection-column {
  padding-inline-start: 2px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #e8e8e8;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #e8e8e8;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #e8e8e8;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #e8e8e8;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #e8e8e8;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #e8e8e8;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 6px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 6px 6px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: #535353;
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: #535353;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6faff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcf8ff;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #e8e8e8;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #038fde;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #038fde;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: #595959;
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #038fde;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #a6eaff;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #e8e8e8 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #e8e8e8;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #038fde;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  transform: scale(0.94117647);
  transition: all 0.3s;
  user-select: none;
}
.ant-table-row-expand-icon:focus-visible,
.ant-table-row-expand-icon:hover {
  color: #28aaeb;
}
.ant-table-row-expand-icon:active {
  color: #006eb8;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 0.6px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-left-all::after {
  display: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: calc(calc(2 + 1) + 1);
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #e8e8e8;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 6px 6px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 6px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
.ant-table-footer {
  border-radius: 0 0 6px 6px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #e8e8e8;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item > span {
  flex: 1;
  white-space: nowrap;
}
.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: #595959;
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown-menu-item-remove:hover {
  color: #28aaeb;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 10px 16px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #038fde;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 6px 6px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 6px 6px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 6px 0 0 6px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 6px 6px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 10px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #28aaeb;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #006eb8;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #038fde;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #006eb8;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: #595959;
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: #535353;
}
.ant-tabs-tab:hover {
  color: #28aaeb;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #038fde;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  position: relative;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-tabpane {
  outline: none;
}
.ant-tabs-tabpane-hidden {
  display: none;
}
.ant-tabs-switch-appear,
.ant-tabs-switch-enter {
  transition: none;
}
.ant-tabs-switch-appear-start,
.ant-tabs-switch-enter-start {
  opacity: 0;
}
.ant-tabs-switch-appear-active,
.ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.ant-tabs-switch-leave {
  position: absolute;
  transition: none;
  inset: 0;
}
.ant-tabs-switch-leave-start {
  opacity: 1;
}
.ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #444;
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: #595959;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: #535353;
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #038fde;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #038fde;
}
.ant-tag-checkable:active {
  background-color: #006eb8;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #e8e8e8;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #038fde;
  border-color: #038fde;
}
.ant-timeline-item-head-red {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -3.2px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -3.2px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #e8e8e8;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #e8e8e8;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #e8e8e8;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer-status-error .ant-transfer-list {
  border-color: #f5222d;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list {
  border-color: #fa8c16;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #28aaeb;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #28aaeb;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 10px 12px 11px;
  color: #444;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  position: relative;
  color: #d9d9d9;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-transfer-list-content-item-remove:hover {
  color: #28aaeb;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #dcf8ff;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #e6faff;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #e8e8e8;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #038fde;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #038fde;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e6faff;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #038fde;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree .ant-tree-treenode-draggable.ant-tree-treenode-disabled .ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  visibility: hidden;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #038fde;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #a6eaff;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #038fde;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #038fde;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #038fde;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl .ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #038fde;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e6faff;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #038fde;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  visibility: visible;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-select-tree-treenode:hover .ant-select-tree .ant-select-tree-treenode-draggable .ant-select-tree-draggable-icon {
  opacity: 0.45;
}
.ant-select-tree .ant-select-tree-treenode-draggable.ant-select-tree-treenode-disabled .ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-draggable-icon {
  visibility: hidden;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #038fde;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #a6eaff;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper {
  line-height: 24px;
  user-select: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #038fde;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #038fde;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #038fde;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: #444;
  word-break: break-word;
}
.ant-typography.ant-typography-secondary {
  color: #595959;
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.ant-typography.ant-typography-warning {
  color: #fa8c16;
}
.ant-typography.ant-typography-danger {
  color: #f5222d;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus {
  color: #cf1322;
}
a.ant-typography.ant-typography-danger:hover {
  color: #ff4d4f;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.ant-typography,
.ant-typography a {
  color: #038fde;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus-visible,
.ant-typography a:focus-visible,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #28aaeb;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #006eb8;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #038fde;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus-visible,
.ant-typography-edit:focus-visible,
.ant-typography-copy:focus-visible,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #28aaeb;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #006eb8;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -8.9px;
  margin-bottom: calc(1em - 7.9px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: #595959;
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  height: 1em;
  margin: 0 !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-single-line {
  white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #038fde;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #006eb8;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #28aaeb;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #28aaeb;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: #535353;
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #595959;
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: #595959;
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: #595959;
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.3;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 18.2px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 18.2px;
  line-height: 1;
  vertical-align: top;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: #595959;
  transition: all 0.3s;
}
.ant-upload-list-item-card-actions:hover .anticon {
  color: #444;
}
.ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: #595959;
  font-size: 14px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff1f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.3;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
/* color classes */
.gx-bg-primary {
  background-color: #038fde !important;
}
a.gx-bg-primary:hover,
a.gx-bg-primary:focus {
  background-color: #038fde !important;
}
.gx-bg-primary-light {
  background-color: #52c6f7 !important;
}
.gx-bg-secondary {
  background-color: #fa8c15 !important;
}
a.gx-bg-secondary:hover,
a.gx-bg-secondary:focus {
  background-color: #fa8c15 !important;
}
.gx-bg-success {
  background-color: #52c41a !important;
}
a.gx-bg-success:hover,
a.gx-bg-success:focus {
  background-color: #3f9714 !important;
}
.gx-bg-success-dark {
  background-color: #368011 !important;
}
a.gx-bg-success-dark:hover,
a.gx-bg-success-dark:focus {
  background-color: #2c6a0e !important;
}
.gx-bg-info {
  background-color: #1890ff !important;
}
a.gx-bg-info:hover,
a.gx-bg-info:focus {
  background-color: #0076e4 !important;
}
.gx-bg-warning {
  background-color: #fa8c16 !important;
}
a.gx-bg-warning:hover,
a.gx-bg-warning:focus {
  background-color: #d87205 !important;
}
.gx-bg-danger {
  background-color: #f44336 !important;
}
a.gx-bg-danger:hover,
a.gx-bg-danger:focus {
  background-color: #ea1c0d !important;
}
.gx-bg-light {
  background-color: #e8e8e8 !important;
}
a.gx-bg-light:hover,
a.gx-bg-light:focus {
  background-color: #d9d9d9 !important;
}
.gx-bg-dark {
  background-color: #000000 !important;
}
a.gx-bg-dark:hover,
a.gx-bg-dark:focus {
  background-color: #1a1a1a !important;
}
.gx-bg-white {
  background-color: #ffffff !important;
}
a.gx-bg-white:hover,
a.gx-bg-white:focus {
  background-color: #f2f2f2 !important;
}
.gx-bg-grey {
  background-color: #cccccc !important;
}
a.gx-bg-grey:hover,
a.gx-bg-grey:focus {
  background-color: #b2b2b2 !important;
}
.gx-bg-light-grey {
  background-color: #fafafa !important;
}
.gx-bg-transparent {
  background-color: transparent !important;
}
.gx-bg-pink {
  background-color: #eb2f96 !important;
}
a.gx-bg-pink:hover,
a.gx-bg-pink:focus {
  background-color: #d3147d !important;
}
.gx-bg-green {
  background-color: #52c41a !important;
}
a.gx-bg-green:hover,
a.gx-bg-green:focus {
  background-color: #3f9714 !important;
}
.gx-bg-green-light {
  background-color: #caf5b5 !important;
}
a.gx-bg-green-light:hover,
a.gx-bg-green-light:focus {
  background-color: #8ae95b !important;
}
.gx-bg-red {
  background-color: #f5222d !important;
}
a.gx-bg-red:hover,
a.gx-bg-red:focus {
  background-color: #da0a15 !important;
}
.gx-bg-amber {
  background-color: #fadb14 !important;
}
a.gx-bg-amber:hover,
a.gx-bg-amber:focus {
  background-color: #d6ba05 !important;
}
.gx-bg-amber-light {
  background-color: #fdf2aa !important;
}
a.gx-bg-amber-light:hover,
a.gx-bg-amber-light:focus {
  background-color: #fcea78 !important;
}
.gx-bg-blue {
  background-color: #1890ff !important;
}
a.gx-bg-blue:hover,
a.gx-bg-blue:focus {
  background-color: #0076e4 !important;
}
.gx-bg-light-blue {
  background-color: #329cff !important;
}
a.gx-bg-light-blue:hover,
a.gx-bg-light-blue:focus {
  background-color: #4ba9ff !important;
}
.gx-bg-indigo {
  background-color: #3c1991 !important;
}
a.gx-bg-indigo:hover,
a.gx-bg-indigo:focus {
  background-color: #2a1166 !important;
}
.gx-bg-purple {
  background-color: #722ed1 !important;
}
a.gx-bg-purple:hover,
a.gx-bg-purple:focus {
  background-color: #5b25a7 !important;
}
.gx-bg-orange {
  background-color: #fa8c16 !important;
}
a.gx-bg-orange:hover,
a.gx-bg-orange:focus {
  background-color: #d87205 !important;
}
.gx-bg-yellow {
  background-color: #fadb14 !important;
}
a.gx-bg-yellow:hover,
a.gx-bg-yellow:focus {
  background-color: #d6ba05 !important;
}
.gx-bg-teal {
  background-color: #20c997 !important;
}
a.gx-bg-teal:hover,
a.gx-bg-teal:focus {
  background-color: #199d76 !important;
}
.gx-bg-cyan {
  background-color: #13c2c2 !important;
}
a.gx-bg-cyan:hover,
a.gx-bg-cyan:focus {
  background-color: #0e9494 !important;
}
.gx-bg-sepia {
  background-color: #7c3535 !important;
}
a.gx-bg-sepia:hover,
a.gx-bg-sepia:focus {
  background-color: #582626 !important;
}
.gx-bg-geekblue {
  background-color: #030852 !important;
}
a.gx-bg-geekblue:hover,
a.gx-bg-geekblue:focus {
  background-color: #010321 !important;
}
.gx-text-white {
  color: #ffffff !important;
}
.gx-text-primary {
  color: #038fde !important;
}
a.gx-text-primary:hover,
a.gx-text-primary:focus {
  color: #038fde !important;
}
.gx-text-secondary {
  color: #fa8c15 !important;
}
a.gx-text-secondary:hover,
a.gx-text-secondary:focus {
  color: #fa8c15 !important;
}
.gx-text-success {
  color: #52c41a !important;
}
a.gx-text-success:hover,
a.gx-text-success:focus {
  color: #49ad17 !important;
}
.gx-text-success-dark {
  color: #368011 !important;
}
a.gx-text-success-dark:hover,
a.gx-text-success-dark:focus {
  color: #2c6a0e !important;
}
.gx-text-info {
  color: #1890ff !important;
}
a.gx-text-info:hover,
a.gx-text-info:focus {
  color: #0084fe !important;
}
.gx-text-warning {
  color: #fa8c16 !important;
}
a.gx-text-warning:hover,
a.gx-text-warning:focus {
  color: #f17f05 !important;
}
.gx-text-danger {
  color: #f44336 !important;
}
a.gx-text-danger:hover,
a.gx-text-danger:focus {
  color: #f32c1e !important;
}
.gx-text-light {
  color: #ababab !important;
}
a.gx-text-light:hover,
a.gx-text-light:focus {
  color: #787878 !important;
}
.gx-text-light-grey {
  color: #cccccc !important;
}
a.gx-text-light-grey:hover,
a.gx-text-light-grey:focus {
  color: #bfbfbf !important;
}
.gx-text-grey {
  color: #8c8c8c !important;
}
a.gx-text-grey:hover,
a.gx-text-grey:focus {
  color: #262626 !important;
}
.gx-text-dark {
  color: #595959 !important;
}
a.gx-text-dark:hover,
a.gx-text-dark:focus {
  color: #4c4c4c !important;
}
.gx-text-black {
  color: #000000 !important;
}
a.gx-text-black:hover,
a.gx-text-black:focus {
  color: #4c4c4c !important;
}
.gx-text-pink {
  color: #eb2f96 !important;
}
a.gx-text-pink:hover,
a.gx-text-pink:focus {
  color: #e9188a !important;
}
.gx-text-green {
  color: #52c41a !important;
}
a.gx-text-green:hover,
a.gx-text-green:focus {
  color: #49ad17 !important;
}
.gx-text-red {
  color: #f5222d !important;
}
a.gx-text-red:hover,
a.gx-text-red:focus {
  color: #f30b17 !important;
}
.gx-text-amber {
  color: #fadb14 !important;
}
a.gx-text-amber:hover,
a.gx-text-amber:focus {
  color: #efd005 !important;
}
.gx-text-blue {
  color: #1890ff !important;
}
a.gx-text-blue:hover,
a.gx-text-blue:focus {
  color: #0084fe !important;
}
.gx-text-indigo {
  color: #3c1991 !important;
}
a.gx-text-indigo:hover,
a.gx-text-indigo:focus {
  color: #33157b !important;
}
.gx-text-purple {
  color: #722ed1 !important;
}
a.gx-text-purple:hover,
a.gx-text-purple:focus {
  color: #6729bc !important;
}
.gx-text-orange {
  color: #fa8c16 !important;
}
a.gx-text-orange:hover,
a.gx-text-orange:focus {
  color: #f17f05 !important;
}
.gx-text-yellow {
  color: #fadb14 !important;
}
a.gx-text-yellow:hover,
a.gx-text-yellow:focus {
  color: #efd005 !important;
}
.gx-text-teal {
  color: #20c997 !important;
}
a.gx-text-teal:hover,
a.gx-text-teal:focus {
  color: #1cb386 !important;
}
.gx-text-cyan {
  color: #13c2c2 !important;
}
a.gx-text-cyan:hover,
a.gx-text-cyan:focus {
  color: #11abab !important;
}
.gx-text-muted {
  color: #8c8c8c !important;
}
.gx-text-geekblue {
  color: #030852 !important;
}
a.gx-text-geekblue:hover,
a.gx-text-geekblue:focus {
  color: #10239e !important;
}
.gx-text-sepia {
  color: #7c3535 !important;
}
a.gx-text-sepia:hover,
a.gx-text-sepia:focus {
  color: #7c3535 !important;
}
.gx-icon-white {
  color: #ffffff !important;
}
.gx-border-primary {
  border-color: #038fde !important;
}
.gx-border-secondary {
  border-color: #fa8c15 !important;
}
.gx-border-success {
  border-color: #52c41a !important;
}
.gx-border-success-dark {
  border-color: #368011 !important;
}
.gx-border-info {
  border-color: #1890ff !important;
}
.gx-border-warning {
  border-color: #fa8c16 !important;
}
.gx-border-danger {
  border-color: #f44336 !important;
}
.gx-border-light {
  border-color: #bfbfbf !important;
}
.gx-border-dark {
  border-color: #595959 !important;
}
.gx-border-white {
  border-color: #ffffff !important;
}
.gx-border-grey {
  border-color: #bfbfbf !important;
}
.gx-border-pink {
  border-color: #eb2f96 !important;
}
.gx-border-green {
  border-color: #52c41a !important;
}
.gx-border-red {
  border-color: #f5222d !important;
}
.gx-border-amber {
  border-color: #fadb14 !important;
}
.gx-border-blue {
  border-color: #1890ff !important;
}
.gx-border-indigo {
  border-color: #3c1991 !important;
}
.gx-border-purple {
  border-color: #722ed1 !important;
}
.gx-border-orange {
  border-color: #fa8c16 !important;
}
.gx-border-yellow {
  border-color: #fadb14 !important;
}
.gx-border-teal {
  border-color: #1890ff !important;
}
.gx-border-cyan {
  border-color: #13c2c2 !important;
}
.gx-border-sepia {
  border-color: #7c3535 !important;
}
.gx-border-geekblue {
  border-color: #030852 !important;
}
.gx-btn-primary,
a.gx-btn-primary,
.btn-primary {
  color: #ffffff !important;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-primary:hover,
a.gx-btn-primary:hover,
.btn-primary:hover,
.gx-btn-primary:focus,
a.gx-btn-primary:focus,
.btn-primary:focus {
  color: #ffffff !important;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-primary.disabled,
a.gx-btn-primary.disabled,
.btn-primary.disabled,
.gx-btn-primary:disabled,
a.gx-btn-primary:disabled,
.btn-primary:disabled {
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-primary:not([disabled]):not(.disabled):active,
a.gx-btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled):active,
.gx-btn-primary:not([disabled]):not(.disabled).active,
a.gx-btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-primary-light,
a.gx-btn-primary-light,
.btn-primary-light {
  color: #595959 !important;
  background-color: #c8ebfe !important;
  border-color: #c8ebfe !important;
}
.gx-btn-primary-light:hover,
a.gx-btn-primary-light:hover,
.btn-primary-light:hover,
.gx-btn-primary-light:focus,
a.gx-btn-primary-light:focus,
.btn-primary-light:focus {
  color: #595959 !important;
  background-color: #7dcffd !important;
  border-color: #7dcffd !important;
}
.gx-btn-primary-light.disabled,
a.gx-btn-primary-light.disabled,
.btn-primary-light.disabled,
.gx-btn-primary-light:disabled,
a.gx-btn-primary-light:disabled,
.btn-primary-light:disabled {
  background-color: #e1f4ff !important;
  border-color: #e1f4ff !important;
}
.gx-btn-primary-light:not([disabled]):not(.disabled):active,
a.gx-btn-primary-light:not([disabled]):not(.disabled):active,
.btn-primary-light:not([disabled]):not(.disabled):active,
.gx-btn-primary-light:not([disabled]):not(.disabled).active,
a.gx-btn-primary-light:not([disabled]):not(.disabled).active,
.btn-primary-light:not([disabled]):not(.disabled).active {
  color: #595959;
  background-color: #afe1fe !important;
  border-color: #afe1fe !important;
}
.gx-btn-secondary,
a.gx-btn-secondary {
  color: #ffffff !important;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-secondary:hover,
a.gx-btn-secondary:hover,
.gx-btn-secondary:focus,
a.gx-btn-secondary:focus {
  color: #ffffff;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-secondary.disabled,
a.gx-btn-secondary.disabled,
.gx-btn-secondary:disabled,
a.gx-btn-secondary:disabled {
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-secondary:not([disabled]):not(.disabled):active,
a.gx-btn-secondary:not([disabled]):not(.disabled):active,
.gx-btn-secondary:not([disabled]):not(.disabled).active,
a.gx-btn-secondary:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-success,
a.gx-btn-success {
  color: #ffffff !important;
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-success:hover,
a.gx-btn-success:hover,
.gx-btn-success:focus,
a.gx-btn-success:focus {
  color: #ffffff !important;
  background-color: #3f9714 !important;
  border-color: #3f9714 !important;
}
.gx-btn-success.disabled,
a.gx-btn-success.disabled,
.gx-btn-success:disabled,
a.gx-btn-success:disabled {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-success:not([disabled]):not(.disabled):active,
a.gx-btn-success:not([disabled]):not(.disabled):active,
.gx-btn-success:not([disabled]):not(.disabled).active,
a.gx-btn-success:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #3f9714 !important;
  border-color: #3f9714 !important;
}
.gx-btn-success-dark,
a.gx-btn-success-dark {
  color: #ffffff !important;
  background-color: #368011 !important;
  border-color: #368011 !important;
}
.gx-btn-success-dark:hover,
a.gx-btn-success-dark:hover,
.gx-btn-success-dark:focus,
a.gx-btn-success-dark:focus {
  color: #ffffff !important;
  background-color: #2c6a0e !important;
  border-color: #2c6a0e !important;
}
.gx-btn-success-dark.disabled,
a.gx-btn-success-dark.disabled,
.gx-btn-success-dark:disabled,
a.gx-btn-success-dark:disabled {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-success-dark:not([disabled]):not(.disabled):active,
a.gx-btn-success-dark:not([disabled]):not(.disabled):active,
.gx-btn-success-dark:not([disabled]):not(.disabled).active,
a.gx-btn-success-dark:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #2c6a0e !important;
  border-color: #2c6a0e !important;
}
.gx-btn-info,
a.gx-btn-info {
  color: #ffffff !important;
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.gx-btn-info:hover,
a.gx-btn-info:hover,
.gx-btn-info:focus,
a.gx-btn-info:focus {
  color: #ffffff !important;
  background-color: #0076e4 !important;
  border-color: #0076e4 !important;
}
.gx-btn-info.disabled,
a.gx-btn-info.disabled,
.gx-btn-info:disabled,
a.gx-btn-info:disabled {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.gx-btn-info:not([disabled]):not(.disabled):active,
a.gx-btn-info:not([disabled]):not(.disabled):active,
.gx-btn-info:not([disabled]):not(.disabled).active,
a.gx-btn-info:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #0076e4 !important;
  border-color: #0076e4 !important;
}
.gx-btn-warning,
a.gx-btn-warning {
  color: #ffffff !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-warning:hover,
a.gx-btn-warning:hover,
.gx-btn-warning:focus,
a.gx-btn-warning:focus {
  color: #ffffff !important;
  background-color: #d87205 !important;
  border-color: #d87205 !important;
}
.gx-btn-warning.disabled,
a.gx-btn-warning.disabled,
.gx-btn-warning:disabled,
a.gx-btn-warning:disabled {
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-warning:not([disabled]):not(.disabled):active,
a.gx-btn-warning:not([disabled]):not(.disabled):active,
.gx-btn-warning:not([disabled]):not(.disabled).active,
a.gx-btn-warning:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #d87205 !important;
  border-color: #d87205 !important;
}
.gx-btn-yellow,
a.gx-btn-yellow {
  color: #262626 !important;
  background-color: #fadb14 !important;
  border-color: #fadb14 !important;
}
.gx-btn-yellow:hover,
a.gx-btn-yellow:hover,
.gx-btn-yellow:focus,
a.gx-btn-yellow:focus {
  color: #ffffff !important;
  background-color: #d6ba05 !important;
  border-color: #d6ba05 !important;
}
.gx-btn-yellow.disabled,
a.gx-btn-yellow.disabled,
.gx-btn-yellow:disabled,
a.gx-btn-yellow:disabled {
  background-color: #fadb14 !important;
  border-color: #fadb14 !important;
}
.gx-btn-yellow:not([disabled]):not(.disabled):active,
a.gx-btn-yellow:not([disabled]):not(.disabled):active,
.gx-btn-yellow:not([disabled]):not(.disabled).active,
a.gx-btn-yellow:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #d6ba05 !important;
  border-color: #d6ba05 !important;
}
.gx-btn-danger,
a.gx-btn-danger,
.gx-btn-red,
a.gx-btn-red {
  color: #ffffff !important;
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.gx-btn-danger:hover,
a.gx-btn-danger:hover,
.gx-btn-red:hover,
a.gx-btn-red:hover,
.gx-btn-danger:focus,
a.gx-btn-danger:focus,
.gx-btn-red:focus,
a.gx-btn-red:focus {
  color: #ffffff !important;
  background-color: #ea1c0d !important;
  border-color: #ea1c0d !important;
}
.gx-btn-danger.disabled,
a.gx-btn-danger.disabled,
.gx-btn-red.disabled,
a.gx-btn-red.disabled,
.gx-btn-danger:disabled,
a.gx-btn-danger:disabled,
.gx-btn-red:disabled,
a.gx-btn-red:disabled {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.gx-btn-danger:not([disabled]):not(.disabled):active,
a.gx-btn-danger:not([disabled]):not(.disabled):active,
.gx-btn-red:not([disabled]):not(.disabled):active,
a.gx-btn-red:not([disabled]):not(.disabled):active,
.gx-btn-danger:not([disabled]):not(.disabled).active,
a.gx-btn-danger:not([disabled]):not(.disabled).active,
.gx-btn-red:not([disabled]):not(.disabled).active,
a.gx-btn-red:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #ea1c0d !important;
  border-color: #ea1c0d !important;
}
.gx-btn-light,
a.gx-btn-light {
  color: #8c8c8c !important;
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}
.gx-btn-light:hover,
a.gx-btn-light:hover,
.gx-btn-light:focus,
a.gx-btn-light:focus {
  color: #8c8c8c !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.gx-btn-light.disabled,
a.gx-btn-light.disabled,
.gx-btn-light:disabled,
a.gx-btn-light:disabled {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.gx-btn-light:not([disabled]):not(.disabled):active,
a.gx-btn-light:not([disabled]):not(.disabled):active,
.gx-btn-light:not([disabled]):not(.disabled).active,
a.gx-btn-light:not([disabled]):not(.disabled).active {
  color: #262626 !important;
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}
.gx-btn-dark,
a.gx-btn-dark {
  color: #ffffff !important;
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.gx-btn-dark:hover,
a.gx-btn-dark:hover,
.gx-btn-dark:focus,
a.gx-btn-dark:focus {
  color: #ffffff !important;
  background-color: #0c0c0c !important;
  border-color: #0c0c0c !important;
}
.gx-btn-dark.disabled,
a.gx-btn-dark.disabled,
.gx-btn-dark:disabled,
a.gx-btn-dark:disabled {
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.gx-btn-dark:not([disabled]):not(.disabled):active,
a.gx-btn-dark:not([disabled]):not(.disabled):active,
.gx-btn-dark:not([disabled]):not(.disabled).active,
a.gx-btn-dark:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #0c0c0c !important;
  border-color: #0c0c0c !important;
}
.gx-btn-purple,
a.gx-btn-purple {
  color: #ffffff !important;
  background-color: #722ed1 !important;
  border-color: #722ed1 !important;
}
.gx-btn-purple:hover,
a.gx-btn-purple:hover,
.gx-btn-purple:focus,
a.gx-btn-purple:focus {
  color: #ffffff !important;
  background-color: #5b25a7 !important;
  border-color: #5b25a7 !important;
}
.gx-btn-purple.disabled,
a.gx-btn-purple.disabled,
.gx-btn-purple:disabled,
a.gx-btn-purple:disabled {
  background-color: #722ed1 !important;
  border-color: #722ed1 !important;
}
.gx-btn-purple:not([disabled]):not(.disabled):active,
a.gx-btn-purple:not([disabled]):not(.disabled):active,
.gx-btn-purple:not([disabled]):not(.disabled).active,
a.gx-btn-purple:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #5b25a7 !important;
  border-color: #5b25a7 !important;
}
.gx-btn-sepia,
a.gx-btn-sepia {
  color: #ffffff !important;
  background-color: #7c3535 !important;
  border-color: #7c3535 !important;
}
.gx-btn-sepia:hover,
a.gx-btn-sepia:hover,
.gx-btn-sepia:focus,
a.gx-btn-sepia:focus {
  color: #ffffff !important;
  background-color: #461e1e !important;
  border-color: #461e1e !important;
}
.gx-btn-sepia.disabled,
a.gx-btn-sepia.disabled,
.gx-btn-sepia:disabled,
a.gx-btn-sepia:disabled {
  background-color: #7c3535 !important;
  border-color: #7c3535 !important;
}
.gx-btn-sepia:not([disabled]):not(.disabled):active,
a.gx-btn-sepia:not([disabled]):not(.disabled):active,
.gx-btn-sepia:not([disabled]):not(.disabled).active,
a.gx-btn-sepia:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #461e1e !important;
  border-color: #461e1e !important;
}
.gx-btn-geekblue,
a.gx-btn-geekblue {
  color: #ffffff !important;
  background-color: #10239e !important;
  border-color: #10239e !important;
}
.gx-btn-geekblue:hover,
a.gx-btn-geekblue:hover,
.gx-btn-geekblue:focus,
a.gx-btn-geekblue:focus {
  color: #ffffff !important;
  background-color: #0b1970 !important;
  border-color: #0b1970 !important;
}
.gx-btn-geekblue.disabled,
a.gx-btn-geekblue.disabled,
.gx-btn-geekblue:disabled,
a.gx-btn-geekblue:disabled {
  background-color: #10239e !important;
  border-color: #10239e !important;
}
.gx-btn-geekblue:not([disabled]):not(.disabled):active,
a.gx-btn-geekblue:not([disabled]):not(.disabled):active,
.gx-btn-geekblue:not([disabled]):not(.disabled).active,
a.gx-btn-geekblue:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #091459 !important;
  border-color: #091459 !important;
}
.gx-btn-cyan,
a.gx-btn-cyan {
  color: #ffffff !important;
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.gx-btn-cyan:hover,
a.gx-btn-cyan:hover,
.gx-btn-cyan:focus,
a.gx-btn-cyan:focus {
  color: #ffffff !important;
  background-color: #0e9494 !important;
  border-color: #0e9494 !important;
}
.gx-btn-cyan.disabled,
a.gx-btn-cyan.disabled,
.gx-btn-cyan:disabled,
a.gx-btn-cyan:disabled {
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.gx-btn-cyan:not([disabled]):not(.disabled):active,
a.gx-btn-cyan:not([disabled]):not(.disabled):active,
.gx-btn-cyan:not([disabled]):not(.disabled).active,
a.gx-btn-cyan:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #0e8a8a !important;
  border-color: #0e8a8a !important;
}
.gx-btn-white,
a.gx-btn-white {
  color: #444 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.gx-btn-white:hover,
a.gx-btn-white:hover,
.gx-btn-white:focus,
a.gx-btn-white:focus {
  color: #444 !important;
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}
.gx-btn-white.disabled,
a.gx-btn-white.disabled,
.gx-btn-white:disabled,
a.gx-btn-white:disabled {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.gx-btn-white:not([disabled]):not(.disabled):active,
a.gx-btn-white:not([disabled]):not(.disabled):active,
.gx-btn-white:not([disabled]):not(.disabled).active,
a.gx-btn-white:not([disabled]):not(.disabled).active {
  color: #444 !important;
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}
.gx-btn-orange,
a.gx-btn-orange {
  color: #ffffff !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-orange:hover,
a.gx-btn-orange:hover,
.gx-btn-orange:focus,
a.gx-btn-orange:focus {
  color: #ffffff !important;
  background-color: #d87205 !important;
  border-color: #d87205 !important;
}
.gx-btn-orange.disabled,
a.gx-btn-orange.disabled,
.gx-btn-orange:disabled,
a.gx-btn-orange:disabled {
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-orange:not([disabled]):not(.disabled):active,
a.gx-btn-orange:not([disabled]):not(.disabled):active,
.gx-btn-orange:not([disabled]):not(.disabled).active,
a.gx-btn-orange:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #ce6d04 !important;
  border-color: #ce6d04 !important;
}
.gx-btn-outline-primary,
a.gx-btn-outline-primary {
  color: #038fde !important;
  background-color: transparent !important;
  border-color: #038fde !important;
}
.gx-btn-outline-primary:hover,
a.gx-btn-outline-primary:hover,
.gx-btn-outline-primary:focus,
a.gx-btn-outline-primary:focus {
  color: #ffffff !important;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-outline-primary.disabled,
a.gx-btn-outline-primary.disabled,
.gx-btn-outline-primary:disabled,
a.gx-btn-outline-primary:disabled {
  color: #038fde !important;
  background-color: transparent !important;
}
.gx-btn-outline-secondary,
a.gx-btn-outline-secondary {
  color: #fa8c15 !important;
  background-color: transparent !important;
  border-color: #fa8c15 !important;
}
.gx-btn-outline-secondary:hover,
a.gx-btn-outline-secondary:hover,
.gx-btn-outline-secondary:focus,
a.gx-btn-outline-secondary:focus {
  color: #ffffff !important;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-outline-secondary.disabled,
a.gx-btn-outline-secondary.disabled,
.gx-btn-outline-secondary:disabled,
a.gx-btn-outline-secondary:disabled {
  color: #fa8c15 !important;
  background-color: transparent !important;
}
.gx-btn-outline-success,
a.gx-btn-outline-success {
  color: #52c41a !important;
  background-color: transparent !important;
  border-color: #52c41a !important;
}
.gx-btn-outline-success:hover,
a.gx-btn-outline-success:hover,
.gx-btn-outline-success:focus,
a.gx-btn-outline-success:focus {
  color: #ffffff !important;
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-outline-success.disabled,
a.gx-btn-outline-success.disabled,
.gx-btn-outline-success:disabled,
a.gx-btn-outline-success:disabled {
  color: #52c41a !important;
  background-color: transparent !important;
}
.gx-btn-outline-success-dark,
a.gx-btn-outline-success-dark {
  color: #52c41a !important;
  background-color: transparent !important;
  border-color: #368011 !important;
}
.gx-btn-outline-success-dark:hover,
a.gx-btn-outline-success-dark:hover,
.gx-btn-outline-success-dark:focus,
a.gx-btn-outline-success-dark:focus {
  color: #ffffff !important;
  background-color: #2c6a0e !important;
  border-color: #2c6a0e !important;
}
.gx-btn-outline-success-dark.disabled,
a.gx-btn-outline-success-dark.disabled,
.gx-btn-outline-success-dark:disabled,
a.gx-btn-outline-success-dark:disabled {
  color: #2c6a0e !important;
  background-color: transparent !important;
}
.gx-btn-outline-info,
a.gx-btn-outline-info {
  color: #1890ff !important;
  background-color: transparent !important;
  border-color: #1890ff !important;
}
.gx-btn-outline-info:hover,
a.gx-btn-outline-info:hover,
.gx-btn-outline-info:focus,
a.gx-btn-outline-info:focus {
  color: #ffffff !important;
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.gx-btn-outline-info.disabled,
a.gx-btn-outline-info.disabled,
.gx-btn-outline-info:disabled,
a.gx-btn-outline-info:disabled {
  color: #1890ff !important;
  background-color: transparent !important;
}
.gx-btn-outline-warning,
a.gx-btn-outline-warning {
  color: #fa8c16 !important;
  background-color: transparent !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-warning:hover,
a.gx-btn-outline-warning:hover,
.gx-btn-outline-warning:focus,
a.gx-btn-outline-warning:focus {
  color: #262626 !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-warning.disabled,
a.gx-btn-outline-warning.disabled,
.gx-btn-outline-warning:disabled,
a.gx-btn-outline-warning:disabled {
  color: #fa8c16 !important;
  background-color: transparent !important;
}
.gx-btn-outline-danger,
a.gx-btn-outline-danger,
.gx-btn-outline-red,
a.gx-btn-outline-red {
  color: #f44336 !important;
  background-color: transparent !important;
  border-color: #f44336 !important;
}
.gx-btn-outline-danger:hover,
a.gx-btn-outline-danger:hover,
.gx-btn-outline-red:hover,
a.gx-btn-outline-red:hover,
.gx-btn-outline-danger:focus,
a.gx-btn-outline-danger:focus,
.gx-btn-outline-red:focus,
a.gx-btn-outline-red:focus {
  color: #ffffff !important;
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.gx-btn-outline-danger.disabled,
a.gx-btn-outline-danger.disabled,
.gx-btn-outline-red.disabled,
a.gx-btn-outline-red.disabled,
.gx-btn-outline-danger:disabled,
a.gx-btn-outline-danger:disabled,
.gx-btn-outline-red:disabled,
a.gx-btn-outline-red:disabled {
  color: #f44336 !important;
  background-color: transparent !important;
}
.gx-btn-outline-light,
a.gx-btn-outline-light {
  color: #8c8c8c !important;
  background-color: transparent !important;
  border-color: #d9d9d9 !important;
}
.gx-btn-outline-light:hover,
a.gx-btn-outline-light:hover,
.gx-btn-outline-light:focus,
a.gx-btn-outline-light:focus {
  color: #8c8c8c !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.gx-btn-outline-light.disabled,
a.gx-btn-outline-light.disabled,
.gx-btn-outline-light:disabled,
a.gx-btn-outline-light:disabled {
  color: #e8e8e8 !important;
  background-color: transparent !important;
}
.gx-btn-outline-dark,
a.gx-btn-outline-dark {
  color: #262626 !important;
  background-color: transparent !important;
  border-color: #262626 !important;
}
.gx-btn-outline-dark:hover,
a.gx-btn-outline-dark:hover,
.gx-btn-outline-dark:focus,
a.gx-btn-outline-dark:focus {
  color: #ffffff !important;
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.gx-btn-outline-dark.disabled,
a.gx-btn-outline-dark.disabled,
.gx-btn-outline-dark:disabled,
a.gx-btn-outline-dark:disabled {
  color: #262626 !important;
  background-color: transparent !important;
}
.gx-btn-outline-sepia,
a.gx-btn-outline-sepia {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #7c3535 !important;
}
.gx-btn-outline-sepia:hover,
a.gx-btn-outline-sepia:hover,
.gx-btn-outline-sepia:focus,
a.gx-btn-outline-sepia:focus {
  color: #ffffff !important;
  background-color: #7c3535 !important;
  border-color: #7c3535 !important;
}
.gx-btn-outline-sepia.disabled,
a.gx-btn-outline-sepia.disabled,
.gx-btn-outline-sepia:disabled,
a.gx-btn-outline-sepia:disabled {
  color: #7c3535 !important;
  background-color: transparent !important;
}
.gx-btn-outline-geekblue,
a.gx-btn-outline-geekblue {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #030852 !important;
}
.gx-btn-outline-geekblue:hover,
a.gx-btn-outline-geekblue:hover,
.gx-btn-outline-geekblue:focus,
a.gx-btn-outline-geekblue:focus {
  color: #ffffff !important;
  background-color: #030852 !important;
  border-color: #030852 !important;
}
.gx-btn-outline-geekblue.disabled,
a.gx-btn-outline-geekblue.disabled,
.gx-btn-outline-geekblue:disabled,
a.gx-btn-outline-geekblue:disabled {
  color: #2f54eb !important;
  background-color: transparent !important;
}
.gx-btn-outline-orange,
a.gx-btn-outline-orange {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-orange:hover,
a.gx-btn-outline-orange:hover,
.gx-btn-outline-orange:focus,
a.gx-btn-outline-orange:focus {
  color: #ffffff !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-orange.disabled,
a.gx-btn-outline-orange.disabled,
.gx-btn-outline-orange:disabled,
a.gx-btn-outline-orange:disabled {
  color: #fa8c16 !important;
  background-color: transparent !important;
}
.gx-badge-primary {
  background-color: #038fde !important;
}
.gx-badge-primary-light {
  background-color: #52c6f7 !important;
}
.gx-badge-secondary {
  background-color: #fa8c15 !important;
}
.gx-badge-success {
  background-color: #52c41a !important;
}
.gx-badge-success-dark {
  background-color: #368011 !important;
}
.gx-badge-info {
  background-color: #1890ff !important;
}
.gx-badge-warning {
  background-color: #fa8c16 !important;
}
.gx-badge-danger {
  background-color: #f44336 !important;
}
.gx-badge-light {
  background-color: #e8e8e8 !important;
}
.gx-badge-dark {
  background-color: #000000 !important;
}
.gx-badge-white {
  background-color: #ffffff !important;
}
.gx-badge-grey {
  background-color: #bfbfbf !important;
}
.gx-badge-light-grey {
  background-color: #d9d9d9 !important;
}
.gx-badge-pink {
  background-color: #eb2f96 !important;
}
.gx-badge-green {
  background-color: #52c41a !important;
}
.gx-badge-green-light {
  background-color: #caf5b5 !important;
}
.gx-badge-red {
  background-color: #f5222d !important;
}
.gx-badge-amber {
  background-color: #fadb14 !important;
}
.gx-badge-amber-light {
  background-color: #fdf2aa !important;
}
.gx-badge-blue {
  background-color: #1890ff !important;
}
.gx-badge-light-blue {
  background-color: #329cff !important;
}
.gx-badge-indigo {
  background-color: #3c1991 !important;
}
.gx-badge-purple {
  background-color: #722ed1 !important;
}
.gx-badge-orange {
  background-color: #fa8c16 !important;
}
.gx-badge-yellow {
  background-color: #fadb14 !important;
}
.gx-badge-teal {
  background-color: #20c997 !important;
}
.gx-badge-cyan {
  background-color: #13c2c2 !important;
}
.gx-badge-sepia {
  background-color: #7c3535 !important;
}
.gx-badge-geekblue {
  background-color: #030852 !important;
}
/* Ripple magic */
.gx-ripple-effect {
  position: relative;
  overflow: hidden;
}
.gx-ripple-effect:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}
.gx-ripple-effect:hover:after {
  animation: ripple 1s ease-out;
}
@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(60, 60);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(100, 100);
  }
}
@-webkit-keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(60, 60);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(100, 100);
  }
}
.gx-pulse-effect {
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  -moz-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}
.gx-online-effect {
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  -moz-box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  animation: online 2s infinite;
}
@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}
@keyframes online {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
    box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}
.gx-away-effect {
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  -moz-box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  animation: away 2s infinite;
}
@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 219, 20, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(250, 219, 20, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 219, 20, 0);
  }
}
@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 219, 20, 0.4);
    box-shadow: 0 0 0 0 rgba(250, 219, 20, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(250, 219, 20, 0);
    box-shadow: 0 0 0 10px rgba(250, 219, 20, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 219, 20, 0);
    box-shadow: 0 0 0 0 rgba(250, 219, 20, 0);
  }
}
.gx-orange-effect {
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  -moz-box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  animation: away 2s infinite;
}
@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
  }
}
@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
    box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
  }
}
@-webkit-keyframes fxicon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fxicon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fxicon-hc-spin {
  -webkit-animation: fxicon-spin 1.5s infinite linear;
  animation: fxicon-spin 1.5s infinite linear;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  -webkit-animation-name: fadein;
  animation-name: fadein;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeout {
  -webkit-animation-name: fadeout;
  animation-name: fadeout;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
/*Base Styles*/
body {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 100% !important;
  position: relative;
  font-weight: 400;
}
body.framed-layout {
  padding: 20px;
  background-color: #038fde;
}
@media screen and (max-width: 991px) {
  body.framed-layout {
    padding: 0;
  }
}
body.boxed-layout {
  background-color: #038fde;
  max-width: 1280px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
@media screen and (min-width: 1600px) {
  body.boxed-layout {
    max-width: 1500px;
  }
}
body.full-scroll #root {
  overflow: auto;
}
#root {
  height: 100vh;
}
.framed-layout #root {
  height: calc(100vh - 2 * 20px);
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
@media screen and (max-width: 991px) {
  .framed-layout #root {
    height: 100vh;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.gx-pointer,
.gx-link {
  cursor: pointer;
}
a,
.gx-link {
  color: #038fde;
}
a:hover,
.gx-link:hover,
a:focus,
.gx-link:focus {
  color: #038fde;
}
a:focus {
  text-decoration: none;
}
ol,
ul,
dl {
  padding-left: 30px;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
}
.gx-w-25 {
  width: 25% !important;
}
.gx-w-50 {
  width: 50% !important;
}
.gx-w-75 {
  width: 75% !important;
}
.gx-w-100 {
  width: 100% !important;
}
.gx-h-25 {
  height: 25% !important;
}
.gx-h-50 {
  height: 50% !important;
}
.gx-h-75 {
  height: 75% !important;
}
.gx-h-100 {
  height: 100% !important;
}
.gx-mw-100 {
  max-width: 100% !important;
}
.gx-mh-100 {
  max-height: 100% !important;
}
.gx-minw50 {
  min-width: 50px;
}
.gx-border {
  border: solid 1px #e8e8e8;
}
.gx-border-2 {
  border: solid 2px #e8e8e8;
}
.gx-border-top {
  border-top: solid 1px #e8e8e8;
}
.gx-border-bottom {
  border-bottom: solid 1px #e8e8e8;
}
.gx-border-bottom-2 {
  border-bottom: solid 2px #e8e8e8;
}
.gx-border-left {
  border-left: solid 1px #e8e8e8;
}
.gx-border-right {
  border-right: solid 1px #e8e8e8;
}
.gx-border-0 {
  border: 0 none !important;
}
.gx-page-heading {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .gx-page-heading {
    margin-bottom: 20px;
  }
}
.gx-page-title {
  font-size: 20px;
  margin-bottom: 0;
}
.gx-size-200 {
  height: 200px !important;
  width: 200px !important;
  line-height: 200px !important;
}
.gx-size-150 {
  height: 150px !important;
  width: 150px !important;
  line-height: 150px !important;
}
.gx-size-120 {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px !important;
}
.gx-size-100 {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px !important;
}
.gx-size-90 {
  height: 90px !important;
  width: 90px !important;
  line-height: 90px !important;
}
.gx-size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px !important;
}
.gx-size-60 {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px !important;
}
.gx-size-50 {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px !important;
}
.gx-size-40 {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
}
.gx-size-36 {
  height: 36px !important;
  width: 36px !important;
  line-height: 36px !important;
}
.gx-size-30 {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}
.gx-size-24 {
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
}
.gx-size-20 {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
}
.gx-size-15 {
  height: 15px !important;
  width: 15px !important;
  line-height: 14px !important;
}
.gx-size-10 {
  height: 10px !important;
  width: 10px !important;
  line-height: 10px !important;
}
.gx-size-8 {
  height: 8px !important;
  width: 8px !important;
  line-height: 8px !important;
}
.gx-width-175 {
  width: 175px !important;
}
.gx-min-width-175 {
  min-width: 175px !important;
}
.gx-img-fluid,
.gx-img-thumbnail {
  max-width: 100%;
  height: auto;
}
.gx-img-fluid-full {
  width: 100%;
}
/*Social Style*/
.gx-social-link {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -5px 10px;
}
.gx-social-link li {
  padding: 0 5px;
  margin-bottom: 5px;
  line-height: 1;
}
.gx-social-link li a,
.gx-social-link li .gx-link,
.gx-social-link li i,
.gx-social-link li .anticon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 34px;
  font-size: 16px;
  text-align: center;
  color: #038fde;
  border: solid 1px #038fde;
  border-radius: 50%;
}
.gx-social-link li.active a,
.gx-social-link li.active .gx-link,
.gx-social-link li.active i,
.gx-social-link li.active .anticon,
.gx-social-link li a:hover,
.gx-social-link li .gx-link:hover,
.gx-social-link li i:hover,
.gx-social-link li .anticon:hover,
.gx-social-link li a:focus,
.gx-social-link li .gx-link:focus,
.gx-social-link li i:focus .gx-social-link li .anticon:focus {
  color: #ffffff;
  background: #038fde;
}
.gx-card-social .gx-social-link {
  margin-bottom: 0;
  -webkit-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
}
.gx-float-left {
  float: left !important;
}
.gx-float-right {
  float: right !important;
}
.gx-float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .gx-float-sm-left {
    float: left !important;
  }
  .gx-float-sm-right {
    float: right !important;
  }
  .gx-float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .gx-float-md-left {
    float: left !important;
  }
  .gx-float-md-right {
    float: right !important;
  }
  .gx-float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .gx-float-lg-left {
    float: left !important;
  }
  .gx-float-lg-right {
    float: right !important;
  }
  .gx-float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .gx-float-xl-left {
    float: left !important;
  }
  .gx-float-xl-right {
    float: right !important;
  }
  .gx-float-xl-none {
    float: none !important;
  }
}
.gx-position-static {
  position: static !important;
}
.gx-position-relative {
  position: relative !important;
}
.gx-position-absolute {
  position: absolute !important;
}
.gx-position-fixed {
  position: fixed !important;
}
.gx-position-sticky {
  position: sticky !important;
}
.gx-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.gx-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.gx-top-0 {
  top: 0 !important;
}
.gx-bottom-0 {
  bottom: 0 !important;
}
.gx-left-0 {
  left: 0 !important;
}
.gx-right-0 {
  right: 0 !important;
}
/*Embed Responsive Classes*/
.gx-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.gx-embed-responsive:before {
  display: block;
  content: "";
}
.gx-embed-responsive .gx-embed-responsive-item,
.gx-embed-responsive iframe,
.gx-embed-responsive embed,
.gx-embed-responsive object,
.gx-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.gx-embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}
.gx-embed-responsive-16by9:before {
  padding-top: 56.25%;
}
.gx-embed-responsive-4by3:before {
  padding-top: 75%;
}
.gx-embed-responsive-1by1:before {
  padding-top: 100%;
}
/*Media Object Classes*/
.gx-media {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.gx-media-body {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
/*Overflow styles*/
.gx-overflow-auto {
  overflow: auto;
}
.gx-z-index-20 {
  position: relative;
  z-index: 20;
}
/*Hr style*/
hr {
  border-color: rgba(250, 250, 250, 0.2);
  margin: 15px 0;
}
/*Status style*/
.gx-status-pos {
  position: relative;
}
.gx-status-pos .gx-status {
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 1;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.gx-status-pos .gx-status.gx-small {
  width: 8px;
  height: 8px;
}
.gx-status-pos .gx-status.gx-status-rtl {
  top: -4px;
  left: auto;
  right: -4px;
}
.gx-status-pos .gx-status.gx-online {
  background-color: #52c41a;
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  -moz-box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  animation: online 2s infinite;
}
.gx-status-pos .gx-status.gx-offline {
  background-color: #ffffff;
  border: solid 1px #52c41a;
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  -moz-box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  animation: online 2s infinite;
}
.gx-status-pos .gx-status.gx-away {
  background-color: #fadb14;
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  -moz-box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  animation: away 2s infinite;
}
.gx-status-pos .gx-status.gx-orange {
  background-color: #fa8c16;
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  -moz-box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  animation: away 2s infinite;
}
.gx-box-shadow {
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
.gx-no-box-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.gx-rounded-xs {
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
}
.gx-rounded-sm {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}
.gx-rounded-base {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
}
.gx-rounded-lg {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}
.gx-rounded-xxl {
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
}
.gx-rounded-circle {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
.gx-border-radius-top-left-0 {
  -moz-border-top-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
}
.gx-border-radius-top-right-0 {
  -moz-border-top-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
}
.gx-border-radius-bottom-left-0 {
  -moz-border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
.gx-border-radius-bottom-right-0 {
  -moz-border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
.gx-vertical-align-top {
  vertical-align: top;
}
.gx-vertical-align-middle {
  vertical-align: middle;
}
.gx-vertical-align-bottom {
  vertical-align: bottom;
}
.gx-separator {
  background-color: #038fde;
  width: 20px;
  height: 2px;
  margin: 0 auto 10px;
}
.gx-loader-400 {
  height: 400px;
}
@media screen and (max-width: 1199px) {
  .gx-loader-400 {
    height: 300px;
  }
}
.gx-object-cover {
  object-fit: cover;
}
.cke_editable,
.cke_editor_editor2 {
  font-family: 'Lato', sans-serif !important;
}
.gx-top2 {
  position: relative;
  top: 2px;
}
.ant-collapse-content-box > :last-child {
  margin-bottom: 0;
}
.react-stickies-wrapper {
  padding-bottom: 16px;
}
@media screen and (max-width: 1366px) {
  .gx-hide-1366 {
    display: none;
  }
}
body > iframe {
  display: none;
}
/*flex Styles*/
.gx-d-flex {
  display: flex !important;
}
.gx-d-none {
  display: none !important;
}
.gx-d-inline-block {
  display: inline-block !important;
}
.gx-d-block {
  display: block !important;
}
.gx-d-inline-flex {
  display: inline-flex !important;
}
.gx-flex-1 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
/*Display Flex Classes*/
.gx-flex-row {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.gx-flex-column {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: -ms-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.gx-flex-nowrap {
  flex-wrap: nowrap !important;
}
.gx-flex-row-reverse {
  flex-direction: row-reverse !important;
}
.gx-flex-column-reverse {
  flex-direction: column-reverse !important;
}
.gx-justify-content-start {
  -webkit-justify-content: flex-start !important;
  -ms-justify-content: flex-start !important;
  justify-content: flex-start !important;
}
.gx-justify-content-end {
  -webkit-justify-content: flex-end !important;
  -ms-justify-content: flex-end !important;
  justify-content: flex-end !important;
}
.gx-justify-content-center {
  -webkit-justify-content: center !important;
  -ms-justify-content: center !important;
  justify-content: center !important;
}
.gx-justify-content-between {
  -webkit-justify-content: space-between !important;
  -ms-justify-content: space-between !important;
  justify-content: space-between !important;
}
.gx-justify-content-around {
  -webkit-justify-content: space-around !important;
  -ms-justify-content: space-around !important;
  justify-content: space-around !important;
}
.gx-align-items-start {
  -webkit-align-items: flex-start !important;
  -ms-align-items: flex-start !important;
  align-items: flex-start !important;
}
.gx-align-items-end {
  -webkit-align-items: flex-end !important;
  -ms-align-items: flex-end !important;
  align-items: flex-end !important;
}
.gx-align-items-center {
  -webkit-align-items: center !important;
  -ms-align-items: center !important;
  align-items: center !important;
}
.gx-align-items-stretch {
  -webkit-align-items: stretch !important;
  -ms-align-items: stretch !important;
  align-items: stretch !important;
}
.gx-align-content-start {
  -webkit-align-content: flex-start !important;
  -ms-align-content: flex-start !important;
  align-content: flex-start !important;
}
.gx-align-content-end {
  -webkit-align-content: flex-end !important;
  -ms-align-content: flex-end !important;
  align-content: flex-end !important;
}
.gx-align-content-center {
  -webkit-align-content: center !important;
  -ms-align-content: center !important;
  align-content: center !important;
}
.gx-align-content-between {
  -webkit-align-content: space-between !important;
  -ms-align-content: space-between !important;
  align-content: space-between !important;
}
.gx-align-content-around {
  -webkit-align-content: space-around !important;
  -ms-align-content: space-around !important;
  align-content: space-around !important;
}
.gx-align-content-stretch {
  -webkit-align-content: stretch !important;
  -ms-align-content: stretch !important;
  align-content: stretch !important;
}
.gx-align-self-auto {
  -webkit-align-self: auto !important;
  -ms-align-self: auto !important;
  align-self: auto !important;
}
.gx-align-self-start {
  -webkit-align-self: flex-start !important;
  -ms-align-self: flex-start !important;
  align-self: flex-start !important;
}
.gx-align-self-end {
  -webkit-align-self: flex-end !important;
  -ms-align-self: flex-end !important;
  align-self: flex-end !important;
}
.gx-align-self-center {
  -webkit-align-self: center !important;
  -ms-align-self: center !important;
  align-self: center !important;
}
.gx-align-self-baseline {
  -webkit-align-self: baseline !important;
  -ms-align-self: baseline !important;
  align-self: baseline !important;
}
.gx-align-self-stretch {
  -webkit-align-self: stretch !important;
  -ms-align-self: stretch !important;
  align-self: stretch !important;
}
.gx-order-first {
  -ms-flex-order: -1;
  order: -1;
}
.gx-order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.gx-order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.gx-order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.gx-order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.gx-order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.gx-order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.gx-order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.gx-order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.gx-order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.gx-order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.gx-order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.gx-order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.gx-offset-1 {
  margin-left: 8.333333%;
}
.gx-offset-2 {
  margin-left: 16.666667%;
}
.gx-offset-3 {
  margin-left: 25%;
}
.gx-offset-4 {
  margin-left: 33.333333%;
}
.gx-offset-5 {
  margin-left: 41.666667%;
}
.gx-offset-6 {
  margin-left: 50%;
}
.gx-offset-7 {
  margin-left: 58.333333%;
}
.gx-offset-8 {
  margin-left: 66.666667%;
}
.gx-offset-9 {
  margin-left: 75%;
}
.gx-offset-10 {
  margin-left: 83.333333%;
}
.gx-offset-11 {
  margin-left: 91.666667%;
}
@media screen and (min-width: 576px) {
  .gx-d-sm-none {
    display: none !important;
  }
  .gx-d-sm-inline-block {
    display: inline-block !important;
  }
  .gx-d-sm-block {
    display: block !important;
  }
  .gx-d-sm-flex {
    display: flex !important;
  }
  .gx-d-sm-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-sm-row {
    flex-direction: row !important;
  }
  .gx-flex-sm-column {
    flex-direction: column !important;
  }
  .gx-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-sm-center {
    justify-content: center !important;
  }
  .gx-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .gx-align-items-sm-start {
    align-items: flex-start !important;
  }
  .gx-align-items-sm-end {
    align-items: flex-end !important;
  }
  .gx-align-items-sm-center {
    align-items: center !important;
  }
  .gx-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-sm-start {
    align-content: flex-start !important;
  }
  .gx-align-content-sm-end {
    align-content: flex-end !important;
  }
  .gx-align-content-sm-center {
    align-content: center !important;
  }
  .gx-align-content-sm-between {
    align-content: space-between !important;
  }
  .gx-align-content-sm-around {
    align-content: space-around !important;
  }
  .gx-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-sm-auto {
    align-self: auto !important;
  }
  .gx-align-self-sm-start {
    align-self: flex-start !important;
  }
  .gx-align-self-sm-end {
    align-self: flex-end !important;
  }
  .gx-align-self-sm-center {
    align-self: center !important;
  }
  .gx-align-self-sm-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-sm-stretch {
    align-self: stretch !important;
  }
  .gx-order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .gx-order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .gx-order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .gx-order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .gx-order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .gx-order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .gx-order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .gx-order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .gx-order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .gx-order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .gx-order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .gx-order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .gx-order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .gx-offset-sm-0 {
    margin-left: 0;
  }
  .gx-offset-sm-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-sm-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-sm-3 {
    margin-left: 25%;
  }
  .gx-offset-sm-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-sm-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-sm-6 {
    margin-left: 50%;
  }
  .gx-offset-sm-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-sm-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-sm-9 {
    margin-left: 75%;
  }
  .gx-offset-sm-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 768px) {
  .gx-d-md-none {
    display: none !important;
  }
  .gx-d-md-inline-block {
    display: inline-block !important;
  }
  .gx-d-md-block {
    display: block !important;
  }
  .gx-d-md-flex {
    display: flex !important;
  }
  .gx-d-md-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-md-row {
    flex-direction: row !important;
  }
  .gx-flex-md-column {
    flex-direction: column !important;
  }
  .gx-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-md-center {
    justify-content: center !important;
  }
  .gx-justify-content-md-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-md-around {
    justify-content: space-around !important;
  }
  .gx-align-items-md-start {
    align-items: flex-start !important;
  }
  .gx-align-items-md-end {
    align-items: flex-end !important;
  }
  .gx-align-items-md-center {
    align-items: center !important;
  }
  .gx-align-items-md-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-md-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-md-start {
    align-content: flex-start !important;
  }
  .gx-align-content-md-end {
    align-content: flex-end !important;
  }
  .gx-align-content-md-center {
    align-content: center !important;
  }
  .gx-align-content-md-between {
    align-content: space-between !important;
  }
  .gx-align-content-md-around {
    align-content: space-around !important;
  }
  .gx-align-content-md-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-md-auto {
    align-self: auto !important;
  }
  .gx-align-self-md-start {
    align-self: flex-start !important;
  }
  .gx-align-self-md-end {
    align-self: flex-end !important;
  }
  .gx-align-self-md-center {
    align-self: center !important;
  }
  .gx-align-self-md-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-md-stretch {
    align-self: stretch !important;
  }
  .gx-order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .gx-order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .gx-order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .gx-order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .gx-order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .gx-order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .gx-order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .gx-order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .gx-order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .gx-order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .gx-order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .gx-order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .gx-order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .gx-offset-md-0 {
    margin-left: 0;
  }
  .gx-offset-md-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-md-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-md-3 {
    margin-left: 25%;
  }
  .gx-offset-md-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-md-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-md-6 {
    margin-left: 50%;
  }
  .gx-offset-md-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-md-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-md-9 {
    margin-left: 75%;
  }
  .gx-offset-md-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 992px) {
  .gx-d-lg-none {
    display: none !important;
  }
  .gx-d-lg-inline-block {
    display: inline-block !important;
  }
  .gx-d-lg-block {
    display: block !important;
  }
  .gx-d-lg-flex {
    display: flex !important;
  }
  .gx-d-lg-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-lg-row {
    flex-direction: row !important;
  }
  .gx-flex-lg-column {
    flex-direction: column !important;
  }
  .gx-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-lg-center {
    justify-content: center !important;
  }
  .gx-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .gx-align-items-lg-start {
    align-items: flex-start !important;
  }
  .gx-align-items-lg-end {
    align-items: flex-end !important;
  }
  .gx-align-items-lg-center {
    align-items: center !important;
  }
  .gx-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-lg-start {
    align-content: flex-start !important;
  }
  .gx-align-content-lg-end {
    align-content: flex-end !important;
  }
  .gx-align-content-lg-center {
    align-content: center !important;
  }
  .gx-align-content-lg-between {
    align-content: space-between !important;
  }
  .gx-align-content-lg-around {
    align-content: space-around !important;
  }
  .gx-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-lg-auto {
    align-self: auto !important;
  }
  .gx-align-self-lg-start {
    align-self: flex-start !important;
  }
  .gx-align-self-lg-end {
    align-self: flex-end !important;
  }
  .gx-align-self-lg-center {
    align-self: center !important;
  }
  .gx-align-self-lg-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-lg-stretch {
    align-self: stretch !important;
  }
  .gx-order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .gx-order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .gx-order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .gx-order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .gx-order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .gx-order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .gx-order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .gx-order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .gx-order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .gx-order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .gx-order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .gx-order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .gx-order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .gx-offset-lg-0 {
    margin-left: 0;
  }
  .gx-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-lg-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-lg-3 {
    margin-left: 25%;
  }
  .gx-offset-lg-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-lg-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-lg-6 {
    margin-left: 50%;
  }
  .gx-offset-lg-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-lg-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-lg-9 {
    margin-left: 75%;
  }
  .gx-offset-lg-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 1200px) {
  .gx-d-xl-none {
    display: none !important;
  }
  .gx-d-xl-inline-block {
    display: inline-block !important;
  }
  .gx-d-xl-block {
    display: block !important;
  }
  .gx-d-xl-flex {
    display: flex !important;
  }
  .gx-d-xl-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-xl-row {
    flex-direction: row !important;
  }
  .gx-flex-xl-column {
    flex-direction: column !important;
  }
  .gx-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .gx-align-items-xl-start {
    align-items: flex-start !important;
  }
  .gx-align-items-xl-end {
    align-items: flex-end !important;
  }
  .gx-align-items-xl-center {
    align-items: center !important;
  }
  .gx-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-xl-start {
    align-content: flex-start !important;
  }
  .gx-align-content-xl-end {
    align-content: flex-end !important;
  }
  .gx-align-content-xl-center {
    align-content: center !important;
  }
  .gx-align-content-xl-between {
    align-content: space-between !important;
  }
  .gx-align-content-xl-around {
    align-content: space-around !important;
  }
  .gx-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-xl-auto {
    align-self: auto !important;
  }
  .gx-align-self-xl-start {
    align-self: flex-start !important;
  }
  .gx-align-self-xl-end {
    align-self: flex-end !important;
  }
  .gx-align-self-xl-center {
    align-self: center !important;
  }
  .gx-align-self-xl-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-xl-stretch {
    align-self: stretch !important;
  }
  .gx-order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .gx-order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .gx-order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .gx-order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .gx-order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .gx-order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .gx-order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .gx-order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .gx-order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .gx-order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .gx-order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .gx-order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .gx-order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .gx-offset-xl-0 {
    margin-left: 0;
  }
  .gx-offset-xl-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-xl-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-xl-3 {
    margin-left: 25%;
  }
  .gx-offset-xl-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-xl-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-xl-6 {
    margin-left: 50%;
  }
  .gx-offset-xl-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-xl-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-xl-9 {
    margin-left: 75%;
  }
  .gx-offset-xl-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.ant-row {
  margin-left: -16px;
  margin-right: -16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ant-row > [class*=ant-col-] {
  margin: 0 -0.3px;
}
.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  padding-left: 16px;
  padding-right: 16px;
  float: none !important;
}
.gx-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (max-width: 480px) {
  .gx-col-full {
    width: 100%;
  }
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
/*Margin Padding Styles*/
.gx-mb-30 {
  margin-bottom: 30px !important;
}
.gx-mb-10 {
  margin-bottom: 10px !important;
}
.gx-m-0 {
  margin: 0 !important;
}
.gx-mt-0,
.gx-my-0 {
  margin-top: 0 !important;
}
.gx-mr-0,
.gx-mx-0 {
  margin-right: 0 !important;
}
.gx-mb-0,
.gx-my-0 {
  margin-bottom: 0 !important;
}
.gx-ml-0,
.gx-mx-0 {
  margin-left: 0 !important;
}
.gx-m-1 {
  margin: 0.25rem !important;
}
.gx-mt-1,
.gx-my-1 {
  margin-top: 0.25rem !important;
}
.gx-mr-1,
.gx-mx-1 {
  margin-right: 0.25rem !important;
}
.gx-mb-1,
.gx-my-1 {
  margin-bottom: 0.25rem !important;
}
.gx-ml-1,
.gx-mx-1 {
  margin-left: 0.25rem !important;
}
.gx-m-2 {
  margin: 0.5rem !important;
}
.gx-mt-2,
.gx-my-2 {
  margin-top: 0.5rem !important;
}
.gx-mr-2,
.gx-mx-2 {
  margin-right: 0.5rem !important;
}
.gx-mb-2,
.gx-my-2 {
  margin-bottom: 0.5rem !important;
}
.gx-ml-2,
.gx-mx-2 {
  margin-left: 0.5rem !important;
}
.gx-m-3 {
  margin: 1rem !important;
}
.gx-mt-3,
.gx-my-3 {
  margin-top: 1rem !important;
}
.gx-mr-3,
.gx-mx-3 {
  margin-right: 1rem !important;
}
.gx-mb-3,
.gx-my-3 {
  margin-bottom: 1rem !important;
}
.gx-ml-3,
.gx-mx-3 {
  margin-left: 1rem !important;
}
.gx-m-4 {
  margin: 1.5rem !important;
}
.gx-mt-4,
.gx-my-4 {
  margin-top: 1.5rem !important;
}
.gx-mr-4,
.gx-mx-4 {
  margin-right: 1.5rem !important;
}
.gx-mb-4,
.gx-my-4 {
  margin-bottom: 1.5rem !important;
}
.gx-ml-4,
.gx-mx-4 {
  margin-left: 1.5rem !important;
}
.gx-m-5 {
  margin: 3rem !important;
}
.gx-mt-5,
.gx-my-5 {
  margin-top: 3rem !important;
}
.gx-mr-5,
.gx-mx-5 {
  margin-right: 3rem !important;
}
.gx-mb-5,
.gx-my-5 {
  margin-bottom: 3rem !important;
}
.gx-ml-5,
.gx-mx-5 {
  margin-left: 3rem !important;
}
.gx-p-0 {
  padding: 0 !important;
}
.gx-pt-0,
.gx-py-0 {
  padding-top: 0 !important;
}
.gx-pr-0,
.gx-px-0 {
  padding-right: 0 !important;
}
.gx-pb-0,
.gx-py-0 {
  padding-bottom: 0 !important;
}
.gx-pl-0,
.gx-px-0 {
  padding-left: 0 !important;
}
.gx-p-1 {
  padding: 0.25rem !important;
}
.gx-pt-1,
.gx-py-1 {
  padding-top: 0.25rem !important;
}
.gx-pr-1,
.gx-px-1 {
  padding-right: 0.25rem !important;
}
.gx-pb-1,
.gx-py-1 {
  padding-bottom: 0.25rem !important;
}
.gx-pl-1,
.gx-px-1 {
  padding-left: 0.25rem !important;
}
.gx-p-2 {
  padding: 0.5rem !important;
}
.gx-pt-2,
.gx-py-2 {
  padding-top: 0.5rem !important;
}
.gx-pr-2,
.gx-px-2 {
  padding-right: 0.5rem !important;
}
.gx-pb-2,
.gx-py-2 {
  padding-bottom: 0.5rem !important;
}
.gx-pl-2,
.gx-px-2 {
  padding-left: 0.5rem !important;
}
.gx-p-3 {
  padding: 1rem !important;
}
.gx-pt-3,
.gx-py-3 {
  padding-top: 1rem !important;
}
.gx-pr-3,
.gx-px-3 {
  padding-right: 1rem !important;
}
.gx-pb-3,
.gx-py-3 {
  padding-bottom: 1rem !important;
}
.gx-pl-3,
.gx-px-3 {
  padding-left: 1rem !important;
}
.gx-p-4 {
  padding: 1.5rem !important;
}
.gx-pt-4,
.gx-py-4 {
  padding-top: 1.5rem !important;
}
.gx-pr-4,
.gx-px-4 {
  padding-right: 1.5rem !important;
}
.gx-pb-4,
.gx-py-4 {
  padding-bottom: 1.5rem !important;
}
.gx-pl-4,
.gx-px-4 {
  padding-left: 1.5rem !important;
}
.gx-p-5 {
  padding: 2rem !important;
}
.gx-pt-5,
.gx-py-5 {
  padding-top: 2rem !important;
}
.gx-pr-5,
.gx-px-5 {
  padding-right: 2rem !important;
}
.gx-pb-5,
.gx-py-5 {
  padding-bottom: 2rem !important;
}
.gx-pl-5,
.gx-px-5 {
  padding-left: 2rem !important;
}
.gx-m-auto {
  margin: auto !important;
}
.gx-mt-auto,
.gx-my-auto {
  margin-top: auto !important;
}
.gx-mr-auto,
.gx-mx-auto {
  margin-right: auto !important;
}
.gx-mb-auto,
.gx-my-auto {
  margin-bottom: auto !important;
}
.gx-ml-auto,
.gx-mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .gx-m-sm-0 {
    margin: 0 !important;
  }
  .gx-mt-sm-0,
  .gx-my-sm-0 {
    margin-top: 0 !important;
  }
  .gx-mr-sm-0,
  .gx-mx-sm-0 {
    margin-right: 0 !important;
  }
  .gx-mb-sm-0,
  .gx-my-sm-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-sm-0,
  .gx-mx-sm-0 {
    margin-left: 0 !important;
  }
  .gx-m-sm-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-sm-1,
  .gx-my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-sm-1,
  .gx-mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-sm-1,
  .gx-my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-sm-1,
  .gx-mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-sm-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-sm-2,
  .gx-my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-sm-2,
  .gx-mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-sm-2,
  .gx-my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-sm-2,
  .gx-mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-sm-3 {
    margin: 1rem !important;
  }
  .gx-mt-sm-3,
  .gx-my-sm-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-sm-3,
  .gx-mx-sm-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-sm-3,
  .gx-my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-sm-3,
  .gx-mx-sm-3 {
    margin-left: 1rem !important;
  }
  .gx-m-sm-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-sm-4,
  .gx-my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-sm-4,
  .gx-mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-sm-4,
  .gx-my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-sm-4,
  .gx-mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-sm-5 {
    margin: 3rem !important;
  }
  .gx-mt-sm-5,
  .gx-my-sm-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-sm-5,
  .gx-mx-sm-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-sm-5,
  .gx-my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-sm-5,
  .gx-mx-sm-5 {
    margin-left: 3rem !important;
  }
  .gx-p-sm-0 {
    padding: 0 !important;
  }
  .gx-pt-sm-0,
  .gx-py-sm-0 {
    padding-top: 0 !important;
  }
  .gx-pr-sm-0,
  .gx-px-sm-0 {
    padding-right: 0 !important;
  }
  .gx-pb-sm-0,
  .gx-py-sm-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-sm-0,
  .gx-px-sm-0 {
    padding-left: 0 !important;
  }
  .gx-p-sm-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-sm-1,
  .gx-py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-sm-1,
  .gx-px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-sm-1,
  .gx-py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-sm-1,
  .gx-px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-sm-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-sm-2,
  .gx-py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-sm-2,
  .gx-px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-sm-2,
  .gx-py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-sm-2,
  .gx-px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-sm-3 {
    padding: 1rem !important;
  }
  .gx-pt-sm-3,
  .gx-py-sm-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-sm-3,
  .gx-px-sm-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-sm-3,
  .gx-py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-sm-3,
  .gx-px-sm-3 {
    padding-left: 1rem !important;
  }
  .gx-p-sm-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-sm-4,
  .gx-py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-sm-4,
  .gx-px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-sm-4,
  .gx-py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-sm-4,
  .gx-px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-sm-5 {
    padding: 2rem !important;
  }
  .gx-pt-sm-5,
  .gx-py-sm-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-sm-5,
  .gx-px-sm-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-sm-5,
  .gx-py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-sm-5,
  .gx-px-sm-5 {
    padding-left: 2rem !important;
  }
  .gx-m-sm-auto {
    margin: auto !important;
  }
  .gx-mt-sm-auto,
  .gx-my-sm-auto {
    margin-top: auto !important;
  }
  .gx-mr-sm-auto,
  .gx-mx-sm-auto {
    margin-right: auto !important;
  }
  .gx-mb-sm-auto,
  .gx-my-sm-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-sm-auto,
  .gx-mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .gx-m-md-0 {
    margin: 0 !important;
  }
  .gx-mt-md-0,
  .gx-my-md-0 {
    margin-top: 0 !important;
  }
  .gx-mr-md-0,
  .gx-mx-md-0 {
    margin-right: 0 !important;
  }
  .gx-mb-md-0,
  .gx-my-md-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-md-0,
  .gx-mx-md-0 {
    margin-left: 0 !important;
  }
  .gx-m-md-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-md-1,
  .gx-my-md-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-md-1,
  .gx-mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-md-1,
  .gx-my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-md-1,
  .gx-mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-md-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-md-2,
  .gx-my-md-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-md-2,
  .gx-mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-md-2,
  .gx-my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-md-2,
  .gx-mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-md-3 {
    margin: 1rem !important;
  }
  .gx-mt-md-3,
  .gx-my-md-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-md-3,
  .gx-mx-md-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-md-3,
  .gx-my-md-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-md-3,
  .gx-mx-md-3 {
    margin-left: 1rem !important;
  }
  .gx-m-md-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-md-4,
  .gx-my-md-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-md-4,
  .gx-mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-md-4,
  .gx-my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-md-4,
  .gx-mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-md-5 {
    margin: 3rem !important;
  }
  .gx-mt-md-5,
  .gx-my-md-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-md-5,
  .gx-mx-md-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-md-5,
  .gx-my-md-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-md-5,
  .gx-mx-md-5 {
    margin-left: 3rem !important;
  }
  .gx-p-md-0 {
    padding: 0 !important;
  }
  .gx-pt-md-0,
  .gx-py-md-0 {
    padding-top: 0 !important;
  }
  .gx-pr-md-0,
  .gx-px-md-0 {
    padding-right: 0 !important;
  }
  .gx-pb-md-0,
  .gx-py-md-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-md-0,
  .gx-px-md-0 {
    padding-left: 0 !important;
  }
  .gx-p-md-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-md-1,
  .gx-py-md-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-md-1,
  .gx-px-md-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-md-1,
  .gx-py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-md-1,
  .gx-px-md-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-md-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-md-2,
  .gx-py-md-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-md-2,
  .gx-px-md-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-md-2,
  .gx-py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-md-2,
  .gx-px-md-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-md-3 {
    padding: 1rem !important;
  }
  .gx-pt-md-3,
  .gx-py-md-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-md-3,
  .gx-px-md-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-md-3,
  .gx-py-md-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-md-3,
  .gx-px-md-3 {
    padding-left: 1rem !important;
  }
  .gx-p-md-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-md-4,
  .gx-py-md-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-md-4,
  .gx-px-md-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-md-4,
  .gx-py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-md-4,
  .gx-px-md-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-md-5 {
    padding: 2rem !important;
  }
  .gx-pt-md-5,
  .gx-py-md-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-md-5,
  .gx-px-md-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-md-5,
  .gx-py-md-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-md-5,
  .gx-px-md-5 {
    padding-left: 2rem !important;
  }
  .gx-m-md-auto {
    margin: auto !important;
  }
  .gx-mt-md-auto,
  .gx-my-md-auto {
    margin-top: auto !important;
  }
  .gx-mr-md-auto,
  .gx-mx-md-auto {
    margin-right: auto !important;
  }
  .gx-mb-md-auto,
  .gx-my-md-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-md-auto,
  .gx-mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .gx-m-lg-0 {
    margin: 0 !important;
  }
  .gx-mt-lg-0,
  .gx-my-lg-0 {
    margin-top: 0 !important;
  }
  .gx-mr-lg-0,
  .gx-mx-lg-0 {
    margin-right: 0 !important;
  }
  .gx-mb-lg-0,
  .gx-my-lg-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-lg-0,
  .gx-mx-lg-0 {
    margin-left: 0 !important;
  }
  .gx-m-lg-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-lg-1,
  .gx-mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-lg-1,
  .gx-my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-lg-1,
  .gx-mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-lg-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-lg-2,
  .gx-my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-lg-2,
  .gx-mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-lg-2,
  .gx-my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-lg-2,
  .gx-mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-lg-3 {
    margin: 1rem !important;
  }
  .gx-mt-lg-3,
  .gx-my-lg-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-lg-3,
  .gx-mx-lg-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-lg-3,
  .gx-my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-lg-3,
  .gx-mx-lg-3 {
    margin-left: 1rem !important;
  }
  .gx-m-lg-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-lg-4,
  .gx-my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-lg-4,
  .gx-mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-lg-4,
  .gx-my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-lg-4,
  .gx-mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-lg-5 {
    margin: 3rem !important;
  }
  .gx-mt-lg-5,
  .gx-my-lg-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-lg-5,
  .gx-mx-lg-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-lg-5,
  .gx-my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-lg-5,
  .gx-mx-lg-5 {
    margin-left: 3rem !important;
  }
  .gx-p-lg-0 {
    padding: 0 !important;
  }
  .gx-pt-lg-0,
  .gx-py-lg-0 {
    padding-top: 0 !important;
  }
  .gx-pr-lg-0,
  .gx-px-lg-0 {
    padding-right: 0 !important;
  }
  .gx-pb-lg-0,
  .gx-py-lg-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-lg-0,
  .gx-px-lg-0 {
    padding-left: 0 !important;
  }
  .gx-p-lg-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-lg-1,
  .gx-py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-lg-1,
  .gx-px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-lg-1,
  .gx-py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-lg-1,
  .gx-px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-lg-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-lg-2,
  .gx-py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-lg-2,
  .gx-px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-lg-2,
  .gx-py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-lg-2,
  .gx-px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-lg-3 {
    padding: 1rem !important;
  }
  .gx-pt-lg-3,
  .gx-py-lg-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-lg-3,
  .gx-px-lg-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-lg-3,
  .gx-py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-lg-3,
  .gx-px-lg-3 {
    padding-left: 1rem !important;
  }
  .gx-p-lg-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-lg-4,
  .gx-py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-lg-4,
  .gx-px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-lg-4,
  .gx-py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-lg-4,
  .gx-px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-lg-5 {
    padding: 2rem !important;
  }
  .gx-pt-lg-5,
  .gx-py-lg-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-lg-5,
  .gx-px-lg-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-lg-5,
  .gx-py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-lg-5,
  .gx-px-lg-5 {
    padding-left: 2rem !important;
  }
  .gx-m-lg-auto {
    margin: auto !important;
  }
  .gx-mt-lg-auto,
  .gx-my-lg-auto {
    margin-top: auto !important;
  }
  .gx-mr-lg-auto,
  .gx-mx-lg-auto {
    margin-right: auto !important;
  }
  .gx-mb-lg-auto,
  .gx-my-lg-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-lg-auto,
  .gx-mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .gx-m-xl-0 {
    margin: 0 !important;
  }
  .gx-mt-xl-0,
  .gx-my-xl-0 {
    margin-top: 0 !important;
  }
  .gx-mr-xl-0,
  .gx-mx-xl-0 {
    margin-right: 0 !important;
  }
  .gx-mb-xl-0,
  .gx-my-xl-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-xl-0,
  .gx-mx-xl-0 {
    margin-left: 0 !important;
  }
  .gx-m-xl-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-xl-1,
  .gx-my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-xl-1,
  .gx-mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-xl-1,
  .gx-my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-xl-1,
  .gx-mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-xl-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-xl-2,
  .gx-my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-xl-2,
  .gx-mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-xl-2,
  .gx-my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-xl-2,
  .gx-mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-xl-3 {
    margin: 1rem !important;
  }
  .gx-mt-xl-3,
  .gx-my-xl-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-xl-3,
  .gx-mx-xl-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-xl-3,
  .gx-my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-xl-3,
  .gx-mx-xl-3 {
    margin-left: 1rem !important;
  }
  .gx-m-xl-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-xl-4,
  .gx-my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-xl-4,
  .gx-mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-xl-4,
  .gx-my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-xl-4,
  .gx-mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-xl-5 {
    margin: 3rem !important;
  }
  .gx-mt-xl-5,
  .gx-my-xl-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-xl-5,
  .gx-mx-xl-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-xl-5,
  .gx-my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-xl-5,
  .gx-mx-xl-5 {
    margin-left: 3rem !important;
  }
  .gx-p-xl-0 {
    padding: 0 !important;
  }
  .gx-pt-xl-0,
  .gx-py-xl-0 {
    padding-top: 0 !important;
  }
  .gx-pr-xl-0,
  .gx-px-xl-0 {
    padding-right: 0 !important;
  }
  .gx-pb-xl-0,
  .gx-py-xl-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-xl-0,
  .gx-px-xl-0 {
    padding-left: 0 !important;
  }
  .gx-p-xl-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-xl-1,
  .gx-py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-xl-1,
  .gx-px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-xl-1,
  .gx-py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-xl-1,
  .gx-px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-xl-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-xl-2,
  .gx-py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-xl-2,
  .gx-px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-xl-2,
  .gx-py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-xl-2,
  .gx-px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-xl-3 {
    padding: 1rem !important;
  }
  .gx-pt-xl-3,
  .gx-py-xl-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-xl-3,
  .gx-px-xl-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-xl-3,
  .gx-py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-xl-3,
  .gx-px-xl-3 {
    padding-left: 1rem !important;
  }
  .gx-p-xl-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-xl-4,
  .gx-py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-xl-4,
  .gx-px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-xl-4,
  .gx-py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-xl-4,
  .gx-px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-xl-5 {
    padding: 2rem !important;
  }
  .gx-pt-xl-5,
  .gx-py-xl-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-xl-5,
  .gx-px-xl-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-xl-5,
  .gx-py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-xl-5,
  .gx-px-xl-5 {
    padding-left: 2rem !important;
  }
  .gx-m-xl-auto {
    margin: auto !important;
  }
  .gx-mt-xl-auto,
  .gx-my-xl-auto {
    margin-top: auto !important;
  }
  .gx-mr-xl-auto,
  .gx-mx-xl-auto {
    margin-right: auto !important;
  }
  .gx-mb-xl-auto,
  .gx-my-xl-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-xl-auto,
  .gx-mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1368px) {
  .gx-m-xxl-0 {
    margin: 0 !important;
  }
  .gx-mt-xxl-0,
  .gx-my-xxl-0 {
    margin-top: 0 !important;
  }
  .gx-mr-xxl-0,
  .gx-mx-xxl-0 {
    margin-right: 0 !important;
  }
  .gx-mb-xxl-0,
  .gx-my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-xxl-0,
  .gx-mx-xxl-0 {
    margin-left: 0 !important;
  }
  .gx-m-xxl-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-xxl-1,
  .gx-my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-xxl-1,
  .gx-mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-xxl-1,
  .gx-my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-xxl-1,
  .gx-mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-xxl-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-xxl-2,
  .gx-my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-xxl-2,
  .gx-mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-xxl-2,
  .gx-my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-xxl-2,
  .gx-mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-xxl-3 {
    margin: 1rem !important;
  }
  .gx-mt-xxl-3,
  .gx-my-xxl-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-xxl-3,
  .gx-mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-xxl-3,
  .gx-my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-xxl-3,
  .gx-mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .gx-m-xxl-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-xxl-4,
  .gx-my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-xxl-4,
  .gx-mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-xxl-4,
  .gx-my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-xxl-4,
  .gx-mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-xxl-5 {
    margin: 3rem !important;
  }
  .gx-mt-xxl-5,
  .gx-my-xxl-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-xxl-5,
  .gx-mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-xxl-5,
  .gx-my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-xxl-5,
  .gx-mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .gx-p-xxl-0 {
    padding: 0 !important;
  }
  .gx-pt-xxl-0,
  .gx-py-xxl-0 {
    padding-top: 0 !important;
  }
  .gx-pr-xxl-0,
  .gx-px-xxl-0 {
    padding-right: 0 !important;
  }
  .gx-pb-xxl-0,
  .gx-py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-xxl-0,
  .gx-px-xxl-0 {
    padding-left: 0 !important;
  }
  .gx-p-xxl-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-xxl-1,
  .gx-py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-xxl-1,
  .gx-px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-xxl-1,
  .gx-py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-xxl-1,
  .gx-px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-xxl-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-xxl-2,
  .gx-py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-xxl-2,
  .gx-px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-xxl-2,
  .gx-py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-xxl-2,
  .gx-px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-xxl-3 {
    padding: 1rem !important;
  }
  .gx-pt-xxl-3,
  .gx-py-xxl-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-xxl-3,
  .gx-px-xxl-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-xxl-3,
  .gx-py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-xxl-3,
  .gx-px-xxl-3 {
    padding-left: 1rem !important;
  }
  .gx-p-xxl-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-xxl-4,
  .gx-py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-xxl-4,
  .gx-px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-xxl-4,
  .gx-py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-xxl-4,
  .gx-px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-xxl-5 {
    padding: 2rem !important;
  }
  .gx-pt-xxl-5,
  .gx-py-xxl-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-xxl-5,
  .gx-px-xxl-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-xxl-5,
  .gx-py-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-xxl-5,
  .gx-px-xxl-5 {
    padding-left: 2rem !important;
  }
  .gx-m-xxl-auto {
    margin: auto !important;
  }
  .gx-mt-xxl-auto,
  .gx-my-xxl-auto {
    margin-top: auto !important;
  }
  .gx-mr-xxl-auto,
  .gx-mx-xxl-auto {
    margin-right: auto !important;
  }
  .gx-mb-xxl-auto,
  .gx-my-xxl-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-xxl-auto,
  .gx-mx-xxl-auto {
    margin-left: auto !important;
  }
}
/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262626;
  font-weight: 400;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 22px;
}
.framed-layout h1,
.framed-layout .h1 {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .framed-layout h1,
  .framed-layout .h1 {
    font-size: 18px;
  }
}
.boxed-layout h1,
.boxed-layout .h1 {
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) {
  h1,
  .h1 {
    font-size: 28px;
  }
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 18px;
  }
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
.gx-h1-lg {
  font-size: 28px;
}
.gx-text-strikethrough {
  text-decoration: line-through !important;
}
.gx-text-underline {
  text-decoration: underline !important;
}
/*Font size Classes*/
/*8px*/
.gx-fs-xxs {
  font-size: 8px;
}
/*10px*/
.gx-fs-xs {
  font-size: 10px;
}
/*11px*/
.gx-fs-11 {
  font-size: 11px;
}
/*12px*/
.gx-fs-sm {
  font-size: 12px;
  line-height: 1.5;
}
/*14px*/
.gx-fs-md {
  font-size: 14px;
  line-height: 1;
}
/*16px*/
.gx-fs-lg {
  font-size: 16px;
  line-height: 1;
}
/*20px*/
.gx-fs-xl {
  font-size: 20px;
  line-height: 1;
}
/*24px*/
.gx-fs-xxl {
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 1199px) {
  .gx-fs-xxl {
    font-size: 20px;
  }
}
/*28px*/
.gx-fs-2xl {
  font-size: 28px !important;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-2xl {
    font-size: 20px !important;
  }
}
/*30px*/
.gx-fs-xxxl {
  font-size: 30px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-xxxl {
    font-size: 28px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-fs-xxxl {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xxxl {
    font-size: 24px;
  }
}
/*40px*/
.gx-fs-xlxl {
  font-size: 40px;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-xlxl {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xlxl {
    font-size: 28px;
  }
}
/*50px*/
.gx-fs-icon-lg {
  font-size: 50px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-icon-lg {
    font-size: 38px;
  }
}
@media screen and (max-width: 575px) {
  .gx-fs-icon-lg {
    font-size: 30px;
  }
}
/*54px*/
.gx-fs-iconcard {
  font-size: 54px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-iconcard {
    font-size: 34px;
  }
}
.gx-text-lowercase {
  text-transform: lowercase;
}
.gx-text-uppercase {
  text-transform: uppercase;
}
.gx-text-capitalize {
  text-transform: capitalize;
}
.gx-font-weight-light {
  font-weight: 300;
}
.gx-font-weight-normal {
  font-weight: 400;
}
.gx-font-weight-medium {
  font-weight: 500;
}
.gx-font-weight-semi-bold {
  font-weight: 600;
}
.gx-font-weight-bold {
  font-weight: 700;
}
.gx-font-weight-heavy {
  font-weight: 900;
}
.gx-font-italic {
  font-style: italic;
}
.gx-letter-spacing-base {
  letter-spacing: 3px;
}
.gx-letter-spacing-lg {
  letter-spacing: 6px;
}
.gx-letter-spacing-xl {
  letter-spacing: 8px;
}
.gx-text-justify {
  text-align: justify !important;
}
.gx-text-nowrap {
  white-space: nowrap !important;
}
.gx-text-left {
  text-align: left !important;
}
.gx-text-right {
  text-align: right !important;
}
.gx-text-center {
  text-align: center !important;
}
.gx-text-truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: none;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout {
    height: 100vh;
  }
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout > .ant-layout {
    height: 100vh;
  }
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.gx-main-content-wrapper {
  padding: 18px 24px 0;
  flex: 1;
}
@media screen and (max-width: 575px) {
  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
.gx-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  flex: 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
.ant-layout-rtl .dashboard-widget-stat {
  left: 1em;
  right: auto !important;
}
/* Header Style */
.ant-layout-header {
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 32px;
  height: 76px;
  position: relative;
  z-index: 10;
  color: #262626;
}
@media screen and (max-width: 991px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: 50px;
  }
}
.gx-nav-header {
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #fefefe;
}
.gx-nav-header .ant-menu-horizontal {
  margin-bottom: -1px;
  border-bottom-color: transparent;
}
.gx-nav-header-below {
  -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 999;
}
.ant-dropdown-menu-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-linebar {
  font-size: 20px;
}
.gx-header-horizontal-dark .gx-linebar {
  color: #ffffff;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 50px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #444;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #fa8c15;
}
.gx-popover-scroll {
  height: 280px !important;
  width: 300px !important;
  margin: 0 -16px;
}
@media screen and (max-width: 575px) {
  .gx-popover-scroll {
    height: 280px !important;
    width: 200px !important;
  }
}
.gx-popover-lang-scroll {
  height: 220px !important;
  width: 160px !important;
  margin: 0 -16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 10px;
}
.gx-popover-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: solid 1px #e8e8e8;
  margin: 0 -16px;
  padding: 0 16px 12px;
}
.gx-sub-popover {
  list-style: none;
  margin: 0 16px;
  padding-left: 0;
}
.gx-sub-popover li {
  padding: 20px 0 14px;
}
.gx-popover-lang-scroll .gx-sub-popover li {
  padding: 5px 0;
}
.gx-sub-popover li:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 3px 15px;
  width: 150px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 575px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-header-horizontal {
  position: relative;
  color: #fa8c15;
  background-color: #ffffff;
}
.gx-header-horizontal-dark {
  position: relative;
  background-color: #013;
  color: #fa8c15;
}
.gx-header-horizontal-dark:before {
  content: '';
  position: absolute;
  right: 169px;
  top: -212px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 280px;
  height: 350px;
  -webkit-border-radius: 0 0 135px 135px;
  -moz-border-radius: 0 0 135px 135px;
  border-radius: 0 0 135px 135px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
@media screen and (max-width: 1049px) {
  .gx-header-horizontal-dark:before {
    right: 110px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-dark:before {
    top: -184px;
    right: 80px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-dark:before {
    top: -154px;
    right: 140px;
    height: 240px;
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-dark:before {
    right: 60px;
    display: none;
  }
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 14px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
.gx-header-horizontal-nav {
  background-color: #013;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  background-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav,
.gx-above-header-horizontal .gx-header-horizontal-nav {
  background-color: #fefefe;
  color: #6a6a6a;
}
.gx-inside-header-horizontal .gx-logo {
  margin-right: 25px;
}
.gx-inside-header-horizontal:before {
  top: -230px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal:before {
    top: -190px;
  }
}
@media screen and (max-width: 991px) {
  .gx-inside-header-horizontal:before {
    top: -154px;
  }
}
.gx-above-header-horizontal:before {
  top: -165px;
}
@media screen and (max-width: 991px) {
  .gx-above-header-horizontal:before {
    top: -154px;
  }
}
.gx-login-list {
  list-style: none;
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
}
.gx-login-list li {
  padding: 0 10px;
  text-transform: uppercase;
  color: #fa8c15;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.gx-login-list li:not(:first-child) {
  border-left: solid 1px #e8e8e8;
}
.gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
@media screen and (max-width: 767px) {
  .gx-login-list {
    display: none;
  }
}
.gx-header-search {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: 28px;
}
.gx-header-search .ant-select-selection,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  background-color: #013;
  border-color: #013;
  color: #fffffd;
  margin-bottom: 0;
}
.gx-header-search .ant-select-selection:focus,
.gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  background-color: #013;
  border-color: #013;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection:focus,
.gx-header-horizontal-dark .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.gx-header-search .ant-select-arrow {
  color: #fffffd;
}
.gx-popover-horizantal .ant-btn {
  margin: 0 !important;
  border: 0 none;
  border-right: solid 1px #e8e8e8;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 24px;
  line-height: 24px;
}
.gx-popover-horizantal .gx-popover-search-bar {
  margin: 0;
}
.gx-popover-horizantal .gx-popover-search-bar .ant-input {
  height: 24px;
  line-height: 24px;
}
@media screen and (max-width: 575px) {
  .gx-popover-horizantal {
    left: 0 !important;
    padding-top: 0;
    margin-top: -9px;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: 100vw;
  }
  .gx-popover-horizantal .ant-popover-arrow {
    display: none;
  }
  .gx-popover-horizantal .gx-popover-search-bar {
    width: 100%;
  }
  .gx-popover-horizantal .gx-popover-scroll,
  .gx-popover-horizantal .gx-popover-lang-scroll {
    width: 100% !important;
    margin: 0;
  }
  .gx-popover-horizantal .gx-sub-popover {
    margin: 0;
    padding-left: 5px;
  }
}
.gx-no-header-horizontal {
  padding: 14px 18px 13px 51px;
  text-align: center;
  position: relative;
}
.gx-no-header-horizontal-top {
  padding: 10px 20px;
  background-color: #d9f7be;
  color: #237804;
  border-radius: 4px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal-top {
    width: 100%;
    font-size: 12px;
  }
}
.gx-no-header-horizontal-top-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-no-header-horizontal .gx-linebar {
  position: absolute;
  left: 18px;
  top: 8px;
  z-index: 1;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal .gx-linebar {
    left: 15px;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal {
    padding: 14px 15px 13px 60px;
  }
}
/* Sidebar Style */
.gx-app-sidebar {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.notification-count-badge {
  background-color: #e91a1a;
  color: #fff !important;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  min-width: 15px;
  min-height: 15px;
  font-size: 9px;
  display: flex;
}
.ant-layout-sider {
  background-color: #fffffc;
  color: #444;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 280px !important;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  .ant-layout-sider {
    width: 240px !important;
  }
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed.gx-mini-custom-sidebar {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.ant-layout-sider.gx-collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.gx-layout-sider-dark {
  background-color: #013;
  color: #e2ecf1;
}
.gx-layout-sider-header {
  padding: 13px 30px 10px 40px;
  height: 76px;
  -webkit-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  justify-content: space-between;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-header {
    height: 50px;
  }
}
.gx-layout-sider-header .gx-site-logo {
  display: block;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-site-logo {
  display: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-layout-sider-header .gx-linebar {
  left: 20px;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: 20px;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header .gx-linebar {
    display: none;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.gx-drawer-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-right: 1px solid #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header {
    padding-left: 30px;
  }
}
.gx-sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  flex: 1;
  border-right: 1px solid #e8e8e8;
}
.gx-layout-sider-dark .gx-sidebar-content,
.gx-drawer-sidebar-dark .gx-sidebar-content {
  border-right: 0 none;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-content {
    display: block;
  }
}
.gx-layout-sider-scrollbar {
  flex: 1;
  height: calc(100vh - 76px - 145px) !important;
}
.framed-layout .gx-layout-sider-scrollbar {
  height: calc(100vh - 76px - 2 * 20px - 145px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-layout-sider-scrollbar {
    height: calc(100vh - 76px) !important;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 76px - 91px) !important;
}
.framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 76px - 2 * 20px - 91px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 76px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 76px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 76px - 206px) !important;
}
.framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 76px - 2 * 20px - 206px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
    height: calc(100vh - 76px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 76px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 76px - 145px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px - 145px) !important;
  }
}
.gx-sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #e2ecf1;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications.gx-no-header-notifications {
    display: block;
  }
}
.gx-app-nav {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #038fde;
}
.ant-layout-sider-dark .gx-app-nav,
.gx-drawer-sidebar-dark .gx-app-nav {
  color: #e2ecf1;
}
.gx-app-nav li {
  padding: 0 20px;
  font-size: 20px;
}
.gx-app-nav li .icon {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-app-nav {
  display: none;
}
.gx-mini-custom-sidebar .gx-app-nav {
  display: block;
  margin-top: 15px;
}
.gx-mini-custom-sidebar .gx-app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.gx-avatar-name {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-avatar-name {
  display: none;
}
.gx-avatar-row {
  padding: 25px 45px;
  border-top: rgba(0, 0, 0, 0.1) solid 1px;
  gap: 5px;
}
.ant-layout-sider-collapsed .gx-avatar-row {
  margin-bottom: 0 !important;
}
.gx-avatar-row > span {
  display: flex;
  flex-direction: column;
}
.gx-avatar-row > .ant-popover-open {
  display: flex;
  flex-direction: column;
}
.gx-avatar-row .ant-avatar {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.gx-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.gx-layouts-view .gx-pointer {
  display: block;
  margin-bottom: 15px;
}
.gx-layouts-view .gx-pointer img {
  width: 100%;
}
.gx-layouts-view .gx-pointer.active {
  box-shadow: 0 0 10px 0 #038fde;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
/* Ant Menu Style */
.ant-menu {
  color: #444;
  background: #fff;
}
.ant-menu-horizontal {
  background: none;
}
.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 10px 30px;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 20px;
}
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 40px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6faff;
}
.ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-item .icon[class*=" icon-"]::before {
  position: relative;
  top: 2px;
}
.ant-menu-sub .ant-menu-item .icon {
  font-size: 12px;
}
.ant-menu-item > a {
  color: #444;
}
.ant-menu-item > a:hover {
  color: #038fde;
}
.ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-submenu-inline {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #038fde;
}
.ant-menu-item-selected > a {
  color: #444;
}
.ant-menu-item-selected > a:hover {
  color: #038fde;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6faff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.gx-drawer-sidebar .ant-menu-inline,
.gx-drawer-sidebar .ant-menu-vertical,
.gx-drawer-sidebar .ant-menu-vertical-left {
  border-right: 0 none;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-inside-header-horizontal .ant-menu-item,
.gx-inside-header-horizontal .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .ant-menu-item,
  .gx-inside-header-horizontal .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 20px;
}
.gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0;
  }
}
.gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item .ant-menu-submenu-title,
.gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item .ant-menu-submenu-title,
  .gx-inside-header-horizontal .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu > .ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-sub > li > a {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 0;
}
.ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 80px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 106px !important;
}
.ant-menu-submenu-popup {
  border-radius: 0;
  z-index: 1050 !important;
}
.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding-left: 20px !important;
}
.ant-menu-submenu-vertical .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-submenu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.gx-app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #444, #444);
}
.gx-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 60px;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #038fde;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #e8e8e8;
  line-height: 46px;
  padding: 0;
}
.ant-layout-header .ant-menu-horizontal {
  border-bottom: 0 none;
  line-height: 74px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #038fde;
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > a {
  color: #444;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover {
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item > a:before,
.ant-menu-horizontal > .ant-menu-submenu > a:before {
  bottom: -2px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #038fde;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 30px 0 20px !important;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: inherit;
}
.ant-layout-sider .ant-menu-vertical .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical .ant-menu-item,
.ant-drawer .ant-menu-vertical-left .ant-menu-item,
.ant-drawer .ant-menu-vertical-right .ant-menu-item,
.ant-drawer .ant-menu-inline .ant-menu-item,
.ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 40px !important;
}
.ant-menu-vertical .ant-menu-item .icon,
.ant-menu-vertical-left .ant-menu-item .icon,
.ant-menu-vertical-right .ant-menu-item .icon,
.ant-menu-inline .ant-menu-item .icon,
.ant-menu-vertical .ant-menu-submenu-title .icon,
.ant-menu-vertical-left .ant-menu-submenu-title .icon,
.ant-menu-vertical-right .ant-menu-submenu-title .icon,
.ant-menu-inline .ant-menu-submenu-title .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-vertical .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-vertical-left .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-vertical-right .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-inline .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-vertical .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-vertical-left .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-vertical-right .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-inline .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class*=" icon-"]::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class*=" icon-"]::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class*=" icon-"]::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class*=" icon-"]::before {
  position: relative;
  top: 2px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
  padding: 0 calc((80px - 20px) / 2) !important;
}
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 80px !important;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 30px !important;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 64px / 2 !important;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-left: 4px;
  padding-right: 4px;
  display: none;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 30px 0 80px;
  line-height: 42px;
  height: 42px;
  text-transform: uppercase;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #038fde;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #e2ecf1;
  background: #013;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #e2ecf1;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
  box-shadow: none;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #013;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #013;
}
.ant-menu-dark .ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-dark .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-dark .ant-menu-item .icon[class*=" icon-"]::before {
  position: relative;
  top: 2px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: #e2ecf1;
}
.ant-menu-dark .ant-menu-item {
  color: #e2ecf1;
}
.ant-menu-dark .ant-menu-item > a {
  color: #e2ecf1;
}
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected > a {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  background: #fa8c15;
}
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected span {
  color: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #e2ecf1;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #262626;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover > .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-item .icon {
  font-size: 20px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item .icon {
  font-size: 14px;
  margin-right: 12px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span,
.ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
}
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.gx-navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-navbar-nav li {
  padding: 0 5px;
}
.gx-navbar-nav li a {
  display: block;
  padding: 4px 10px;
  background-color: #038fde;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-profile-banner .gx-navbar-nav li a {
  background-color: transparent;
  color: #ffffff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 0;
}
.gx-navbar-nav li a * {
  color: #bfbfbf;
}
.gx-navbar-nav li a:hover,
.gx-navbar-nav li a:focus {
  background-color: #026fac;
  color: #ffffff;
}
.gx-profile-banner .gx-navbar-nav li a:hover,
.gx-profile-banner .gx-navbar-nav li a:focus {
  background-color: transparent;
  color: #fa8c15;
}
.gx-profile-banner .gx-navbar-nav li {
  padding: 0 15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav li {
    padding: 0 6px;
  }
}
.gx-profile-banner .gx-navbar-nav {
  margin: 0 -15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav {
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    margin: 0 -6px;
  }
}
.gx-menu-horizontal {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-menu-horizontal:before {
  display: none;
}
.gx-header-horizontal-nav .ant-menu-horizontal {
  border-bottom: 0 none;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
.gx-below-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title,
.gx-above-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #6a6a6a;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #fa8c15;
  color: #fa8c15;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected::after,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-color: transparent;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
  margin-top: 0;
  top: 0;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected {
  position: relative;
  border-bottom: 0 none;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  content: "\e049";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-family: "gaxon" !important;
  font-size: 31px;
  line-height: 5px;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #f5f5f5;
}
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #013;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected::after,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #fa8c15;
}
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #ffffff;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -7px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fa8c15;
  color: #013;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #013;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item > a:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #fa8c15;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed {
  padding-bottom: 10px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 30px !important;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item.ant-menu-item-selected > a {
  color: #013;
  background-color: #fa8c15;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span {
  color: #013;
  background-color: #fa8c15;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title .icon {
  margin-right: 0;
}
.gx-no-header-submenu-popup .ant-menu-sub {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
  padding: 34.5px 32px;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.gx-container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.gx-container-wrap .gx-layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1400px;
  }
}
/* Customizer Style */
.gx-customizer {
  height: calc(100vh - 30px) !important;
}
.gx-customizer-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.gx-customizer-item .ant-radio-group .ant-radio-button {
  display: none;
}
.gx-customizer-option {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 99;
}
.full-scroll .gx-customizer-option {
  position: fixed;
}
.full-scroll.boxed-layout .gx-customizer-option {
  position: absolute;
}
.full-scroll.framed-layout .gx-customizer-option {
  right: 20px;
}
@media screen and (max-width: 991px) {
  .full-scroll.framed-layout .gx-customizer-option {
    right: 0;
  }
}
.horizontal-layout.full-scroll .gx-customizer-option {
  top: 200px;
}
@media screen and (max-width: 575px) {
  .gx-customizer {
    width: 250px !important;
  }
  .gx-customizer .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
.gx-customizer .gx-list-inline {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framed-layout .gx-customizer {
  height: calc(100vh - 30px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-customizer {
    height: calc(100vh - 30px) !important;
  }
}
.gx-color-option {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.gx-color-option li {
  font-size: 36px;
  line-height: 1;
}
.gx-color-option li .gx-link {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}
.gx-color-option li .gx-link:before {
  font-family: "gaxon";
  font-size: 20px;
  content: "\30";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
}
.gx-color-option li .gx-link.active:before {
  display: block;
}
.gx-color-option li .gx-link:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 40px;
  background-color: #9283D4;
}
.gx-color-option li .gx-link.gx-color-red:after {
  background-color: #FF2B7A;
}
.gx-color-option li .gx-link.gx-color-blue:after {
  background-color: #3DA4E6;
}
.gx-color-option li .gx-link.gx-color-dark-blue:after {
  background-color: #0469B9;
}
.gx-color-option li .gx-link.gx-color-orange:after {
  background-color: #F18805;
}
.gx-color-option li .gx-link.gx-color-light-blue:after {
  background-color: #6A95FF;
}
.gx-color-option li .gx-link.gx-color-deep-orange:after {
  background-color: #F87060;
}
.gx-color-option li .gx-link.gx-color-light-purple1:after {
  background-color: #A172E7;
}
.gx-color-option li .gx-link.gx-color-light-purple2:after {
  background-color: #956FE7;
}
.gx-cus-customiz {
  padding-right: 20px;
}
.gx-layout-option li,
.gx-nav-option li {
  margin-bottom: 10px;
}
.gx-layout-option li span,
.gx-nav-option li span {
  position: relative;
  display: block;
}
.gx-layout-option li span:before,
.gx-nav-option li span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-family: "gaxon" !important;
  content: "\30";
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  font-size: 12px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #038fde;
  text-align: center;
  padding: 2px;
  color: #ffffff;
}
.gx-layout-option li span.active:before,
.gx-nav-option li span.active:before {
  display: block;
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: #013;
  color: #e2ecf1;
}
.ant-drawer-content-wrapper {
  width: 280px !important;
}
@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 260px !important;
  }
}
.framed-layout .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 11px 0 0 11px;
  -moz-border-radius: 11px 0 0 11px;
  border-radius: 11px 0 0 11px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 2 * 20px);
  -webkit-border-radius: 0 11px 11px 0;
  -moz-border-radius: 0 11px 11px 0;
  border-radius: 0 11px 11px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}
.framed-layout .ant-drawer-content-wrapper,
.boxed-layout .ant-drawer-content-wrapper {
  position: absolute;
}
.boxed-layout .ant-drawer {
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer {
    max-width: 1500px;
  }
}
.framed-layout .ant-drawer-open.ant-drawer-left,
.framed-layout .ant-drawer-open.ant-drawer-right,
.boxed-layout .ant-drawer-open.ant-drawer-left,
.boxed-layout .ant-drawer-open.ant-drawer-right {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.framed-layout .ant-drawer-open {
  width: calc(100% - 2 * 20px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-open {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer.ant-drawer-left,
  .boxed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (min-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    position: absolute;
  }
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer.ant-drawer-left,
  .framed-layout .ant-drawer.ant-drawer-right {
    right: 20px;
    left: 20px;
    margin: 0;
  }
}
.ant-drawer-close {
  top: -11px;
}
.ant-drawer-close-x {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.ant-drawer-mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}
.custom-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 18px 30px;
  border-bottom: 1px solid #e8e8e8;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .custom-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.custom-header div {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .custom-header div {
    gap: 2px;
    display: inline;
    text-align: center;
    width: 100%;
  }
}
.custom-header > div > i {
  margin-right: 3px;
}
.dark-theme .custom-header {
  background-color: #131722;
  border-bottom: 1px solid #1d2435;
}
.power-container {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}
@media screen and (max-width: 767px) {
  .power-container {
    display: none !important;
  }
}
@media screen and (min-width: 767px) {
  .power-mobile-indicator {
    display: none;
  }
}
.header-value-mobile {
  display: flex !important;
  flex-direction: column;
}
.header-value-mobile > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-header-content {
  display: flex !important;
  gap: 15px;
}
@media screen and (max-width: 767px) {
  .custom-header-content {
    display: none !important;
  }
}
.custom-header-content-mobile {
  display: none !important;
}
@media screen and (max-width: 767px) {
  .custom-header-content-mobile {
    display: flex !important;
  }
}
.header-desktop-value {
  gap: 1px;
}
.header-desktop-value > span {
  padding-left: 2px;
}
.power-timer {
  font-size: 12px;
  color: #777;
  display: flex;
  align-items: center;
  padding-left: 5px;
  gap: 5px;
  padding-top: 2px;
}
.dark-theme .power-timer {
  color: #e0e0e0;
}
.header-desktop-settings {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 15px;
}
.header-mobile-settings {
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
}
.header-settings-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-mobile-settings > .header-settings-icon {
  display: flex;
  align-items: center;
  width: 38px;
  height: 38px;
}
.language-button {
  border: none;
  width: 32px;
  height: 32px;
  margin: 0 !important;
  font-size: 16px;
  justify-content: center;
  display: flex;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  align-items: center !important;
}
.mobile-top-icon {
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  .header-desktop-settings {
    display: none !important;
  }
}
/*Alert Styles*/
.ant-alert {
  margin-bottom: 16px;
}
.gx-custom-sweet-alert h2,
.gx-custom-sweet-alert .text-muted {
  color: #ffffff !important;
}
.notification {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.notification .title {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.gx-sweet-alert-top-space {
  padding-top: 40px !important;
}
.sweet-alert h2 {
  font-size: 20px !important;
  font-weight: 400 !important;
}
/*Avatar Styles*/
.gx-avatar-img {
  height: 50px;
  width: 50px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #e8e8e8;
}
.gx-avatar-img-lg {
  height: 100px;
  width: 100px;
}
.ant-avatar {
  position: relative;
}
.ant-avatar.gx-size-150 > * {
  line-height: 150px;
}
.ant-avatar.gx-size-120 > * {
  line-height: 120px;
}
.ant-avatar.gx-size-100 > * {
  line-height: 100px;
}
.ant-avatar.gx-size-80 > * {
  line-height: 80px;
}
.ant-avatar.gx-size-60 > * {
  line-height: 60px;
}
.ant-avatar.gx-size-50 > * {
  line-height: 50px;
}
.ant-avatar.gx-size-40 > * {
  line-height: 40px;
}
.ant-avatar.gx-size-36 > * {
  line-height: 36px;
}
.ant-avatar.gx-size-30 > * {
  line-height: 30px;
}
.ant-avatar.gx-size-24 > * {
  line-height: 24px;
}
.ant-avatar.gx-size-20 > * {
  line-height: 20px;
}
.ant-avatar.gx-size-15 > * {
  line-height: 14px;
}
.gx-customers-list .ant-avatar {
  width: 70px;
  height: 70px;
}
@media (max-width: 1500px) {
  .gx-customers-list .ant-avatar {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 1366px) {
  .gx-customers-list .ant-avatar {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 1199px) {
  .gx-customers-list .ant-avatar {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 400px) {
  .gx-customers-list .ant-avatar {
    width: 52px;
    height: 52px;
  }
}
.gx-avatar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 50px;
  width: 50px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-user-nav .gx-avatar {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 575px) {
  .gx-user-nav .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}
.gx-header-horizontal-main .gx-avatar {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}
.gx-user-thumb {
  position: relative;
}
/*Badge Styles*/
.ant-badge {
  margin-bottom: 6px;
}
.ant-badge:not(:last-child) {
  margin-right: 12px;
}
.ant-tag {
  margin-bottom: 8px;
}
.gx-badge {
  display: inline-block;
  padding: 5px 8px 4px;
  font-size: 75%;
  margin-bottom: 6px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-badge:not(:last-child) {
  margin-right: 12px;
}
.gx-chat-date .gx-badge {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 16px;
  padding: 2px;
}
.gx-user-thumb .gx-badge {
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 1;
  margin: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 0;
  line-height: 16px;
}
.gx-badge-outline {
  background-color: transparent;
  border: solid 1px #e8e8e8;
}
.gx-badge-radius-sm .ant-badge-count {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
/*Button Styles*/
.ant-btn {
  line-height: 34px;
  display: inline-block;
  vertical-align: middle;
}
.ant-btn-group-sm .ant-btn {
  line-height: 24px;
}
.ant-btn-group-lg .ant-btn {
  line-height: 40px;
}
.ant-btn-sm {
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}
.ant-btn-group > .ant-btn-sm {
  line-height: 24px;
}
.ant-btn-sm.ant-btn-icon-only {
  line-height: 1;
}
.ant-btn-lg {
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}
.ant-btn-group > .ant-btn-lg {
  line-height: 40px;
}
.ant-btn-lg.ant-btn-icon-only {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
}
.ant-btn-group .ant-btn:not(:last-child),
.ant-transfer-operation .ant-btn:not(:last-child) {
  margin-right: 0;
}
@media (max-width: 1599px) {
  .gx-product-footer .ant-btn:not(:last-child) {
    margin-right: 6px;
  }
}
.ant-input-group-addon .ant-btn {
  margin-right: 0;
}
@media screen and (max-width: 575px) {
  .ant-btn {
    margin-bottom: 10px;
  }
}
.ant-input-search .ant-btn {
  margin-bottom: 0;
}
.gx-customizer-option .ant-btn {
  padding: 0 18px;
  font-size: 18px;
  height: 42px;
  line-height: 42px;
  margin: 0;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  border-radius: 30px 0 0 30px;
  background-color: #fa8c16;
  background: -webkit-gradient(linear, left top, right top, from(#fa8c16), to(#f5222d));
  background: -webkit-linear-gradient(left, #fa8c16, #f5222d);
  background: -moz-linear-gradient(left, #fa8c16, #f5222d);
  background: -ms-linear-gradient(left, #fa8c16, #f5222d);
  background: -o-linear-gradient(left, #fa8c16, #f5222d);
  border: 0 none;
  color: #ffffff;
}
.gx-customizer-option .ant-btn:hover,
.gx-customizer-option .ant-btn:focus {
  background-color: #f5222d;
  background: -webkit-gradient(linear, left top, right top, from(#f5222d), to(#fa8c16));
  background: -webkit-linear-gradient(left, #f5222d, #fa8c16);
  background: -moz-linear-gradient(left, #f5222d, #fa8c16);
  background: -ms-linear-gradient(left, #f5222d, #fa8c16);
  background: -o-linear-gradient(left, #f5222d, #fa8c16);
}
.gx-customizer-option .ant-btn .icon:before {
  display: block;
}
.horizontal-layout.full-scroll .gx-customizer-option .ant-btn {
  margin-right: 0;
}
.gx-module-add-task .ant-btn {
  height: 36px;
}
.gx-module-add-task .ant-btn .icon:before {
  position: relative;
  top: 2px;
}
.ant-modal-footer .ant-btn,
.ant-modal-confirm-btns .ant-btn {
  margin-bottom: 0;
}
.gx-login-content .ant-btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.boxed-layout .ant-btn-group .ant-btn {
  padding: 0 12px;
}
@media (max-width: 1366px) {
  .ant-btn-group .ant-btn {
    padding: 0 12px;
  }
}
@media (max-width: 1366px) {
  .gx-product-footer .ant-btn {
    padding: 0 10px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
  }
}
.ant-btn-background-ghost.ant-btn-primary {
  border-color: #038fde !important;
  color: #038fde !important;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  background-color: #038fde !important;
  border-color: #038fde !important;
  color: #ffffff !important;
}
.ant-btn-group:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn-group:not(:last-child) {
    margin-right: 10px;
  }
}
.ant-radio-group .ant-radio-button-wrapper {
  margin-bottom: 6px;
}
button {
  outline: none;
}
.ant-confirm .ant-confirm-btns button + button {
  margin-bottom: 8px;
}
.ant-modal.ant-confirm .ant-confirm-btns button + button,
.ant-modal.ant-confirm .ant-btn {
  margin-bottom: 0;
}
.gx-btn,
.btn {
  display: inline-block;
  font-weight: 400;
  color: #444;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: solid 1px #d9d9d9;
  padding: 0 15px;
  height: 36px;
  font-size: 14px;
  line-height: 34px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.gx-btn:not(:last-child),
.btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .gx-btn:not(:last-child),
  .btn:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .gx-btn,
  .btn {
    margin-bottom: 10px;
  }
}
.gx-btn:focus,
.btn:focus,
.gx-btn:hover,
.btn:hover {
  text-decoration: none;
}
.gx-btn:focus,
.btn:focus,
.gx-btn.focus,
.btn.focus {
  outline: 0;
}
.gx-btn.disabled,
.btn.disabled,
.gx-btn:disabled,
.btn:disabled {
  opacity: 0.65;
}
.gx-btn:not([disabled]):not(.disabled),
.btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.gx-btn-list .gx-btn,
.gx-btn-list .btn {
  margin-bottom: 6px;
}
.gx-sub-popover .gx-btn,
.gx-sub-popover .btn {
  border: 0 none;
  margin-bottom: 5px;
}
.gx-sub-popover .gx-btn:focus,
.gx-sub-popover .btn:focus,
.gx-sub-popover .gx-btn:hover,
.gx-sub-popover .btn:hover {
  background-color: #f5f5f5;
}
.gx-sub-popover .gx-btn:not(:last-child),
.gx-sub-popover .btn:not(:last-child) {
  margin-right: 5px;
}
.gx-login-content .gx-btn,
.gx-login-content .btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.gx-btn-block {
  display: block;
  width: 100%;
}
a.gx-btn.disabled,
fieldset[disabled] a.gx-btn {
  pointer-events: none;
}
.gx-btn-rounded {
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  border-radius: 50px !important;
}
.gx-btn-link {
  font-size: 14px;
  font-weight: 400;
  color: #038fde;
  background-color: transparent;
}
.gx-btn-link:hover {
  color: #038fde;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.gx-btn-link:focus,
.gx-btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
}
.gx-btn-link:disabled,
.gx-btn-link.disabled {
  color: #bfbfbf;
}
.gx-btn-lg,
.gx-btn-group-lg > .gx-btn {
  padding: 0 15px;
  font-size: 16px;
  line-height: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  height: 40px;
}
.gx-btn-sm,
.gx-btn-group-sm > .gx-btn {
  padding: 0 7px;
  font-size: 12px;
  line-height: 24px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 24px;
}
.gx-btn-xs {
  font-size: 10px;
  padding: 0 6px;
  height: 26px;
  line-height: 25px;
}
.gx-btn-block {
  display: block;
  width: 100%;
}
.gx-btn-block + .gx-btn-block {
  margin-top: 0.5rem;
}
.gx-icon-btn {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}
.gx-icon-btn:focus,
.gx-icon-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.gx-layout-sider-dark .gx-icon-btn:focus,
.gx-layout-sider-dark .gx-icon-btn:hover {
  background-color: #013;
}
.gx-popover-header .gx-icon-btn {
  width: 30px;
  height: 30px;
}
.gx-icon-btn .icon-star-o:before,
.gx-icon-btn .icon-star:before {
  position: relative;
  top: 3px;
}
@media screen and (max-width: 575px) {
  .gx-module-box-content .gx-icon-btn.icon {
    font-size: 16px;
  }
}
.gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper {
  height: 24px;
  line-height: 24px;
  border: 0 none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-left: 0;
}
.gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #038fde;
  color: #ffffff;
}
.gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
@media screen and (max-width: 575px) {
  .gx-radio-group-link-news {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 575px) {
  .gx-radio-group-link .ant-radio-button-wrapper {
    padding: 0 8px;
  }
}
.gx-radio-group-link-bg-light {
  margin-left: -5px;
  margin-right: -5px;
}
.gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper {
  padding: 0 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e8e8e8;
}
.gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #038fde;
  color: #ffffff;
}
.gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
/*Card Styles*/
.ant-card {
  margin-bottom: 32px;
}
.ant-card-head {
  background: none;
}
.ant-card-head-title {
  font-weight: 400;
}
.ant-card-grid {
  text-align: center;
  width: 25%;
}
@media screen and (max-width: 767px) {
  .ant-card-grid {
    width: 33.33%;
  }
}
@media screen and (max-width: 575px) {
  .ant-card-grid {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .ant-card {
    margin-bottom: 20px;
  }
}
.ant-card-body .ant-card-head {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ant-card-type-inner .ant-card-head {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.ant-card-wider-padding .ant-card-body > :last-child {
  margin-bottom: 0;
}
.gx-card-center-vertical {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-card-img {
  position: relative;
  overflow: hidden;
}
.gx-card-img .ant-card-body {
  position: relative;
}
.gx-card-img .gx-badge-up {
  position: absolute;
  right: 60px;
  top: -20px;
  z-index: 1;
  padding: 5px;
  margin: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 36px;
  text-align: center;
}
.gx-card-img .gx-badge-up-right {
  right: 10px;
  top: -15px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  line-height: 32px !important;
}
.gx-card-img-center .ant-card-cover {
  text-align: center;
}
.gx-card-img-center .ant-card-cover > * {
  width: auto;
  display: inline-block;
}
.gx-card-body-border-top .ant-card-cover {
  padding-top: 24px;
}
.gx-card-body-border-top .ant-card-body {
  border-top: solid 1px #e8e8e8;
  margin-top: 32px;
}
@media screen and (max-width: 575px) {
  .gx-card-body-border-top .ant-card-body {
    margin-bottom: 20px;
  }
}
.gx-ant-card-actions {
  list-style: none;
  margin: 0 -10px 10px;
  padding-left: 0;
}
.gx-ant-card-actions li {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
}
.gx-ant-card-actions li:not(:last-child) {
  border-right: solid 1px #e8e8e8;
}
.gx-card {
  margin-bottom: 32px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
@media screen and (max-width: 575px) {
  .gx-card {
    margin-bottom: 20px;
  }
}
.gx-card-head {
  padding: 24px;
}
.gx-card-head .gx-card-title {
  margin-bottom: 0;
}
.gx-card-head + .gx-card-body {
  padding-top: 0;
}
.gx-card .ant-card-head {
  border-bottom: 0 none;
  min-height: 10px;
  padding: 0 24px;
}
@media screen and (max-width: 991px) {
  .gx-card .ant-card-head {
    padding: 0 24px;
  }
}
.gx-card .ant-card-head-title {
  padding: 25px 0 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
@media screen and (max-width: 991px) {
  .gx-card .ant-card-head-title {
    padding-top: 20px;
  }
}
.gx-card-body,
.gx-card .ant-card-body {
  padding: 24px;
}
@media screen and (max-width: 991px) {
  .gx-card-body,
  .gx-card .ant-card-body {
    padding: 24px;
  }
}
.gx-card.ant-card-bordered {
  border: 0 none;
}
.gx-card.ant-card-hoverable:hover {
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
}
.gx-card .ant-card-cover {
  padding: 24px;
  padding-bottom: 0;
}
@media screen and (max-width: 991px) {
  .gx-card .ant-card-cover {
    padding: 24px;
    padding-bottom: 0;
  }
}
.gx-card-metrics {
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-card-metrics.ant-card-bordered {
  border: 0 none;
}
.gx-card-metrics .ant-card-head {
  border-bottom: 0 none;
  min-height: 10px;
}
.gx-card-metrics .ant-card-head-title {
  padding: 20px 0 0;
  text-transform: uppercase;
  font-size: 14px;
}
.gx-card-widget {
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-card-widget.ant-card-bordered {
  border: 0 none;
}
.gx-card-widget .ant-card-head {
  border-bottom: 0 none;
  min-height: 24px;
  padding: 24px 24px 0;
}
.gx-card-widget .ant-card-head-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-card-widget .ant-card-head-title {
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
}
.gx-card-widget.gx-ch-capitalize .ant-card-head-title {
  text-transform: capitalize;
  font-size: 14px;
}
.gx-card-widget .ant-card-extra {
  padding: 0;
}
.gx-card-widget .ant-card-extra .gx-btn {
  margin-bottom: 0;
}
.gx-card-widget .ant-card-body {
  padding: 24px;
}
.gx-card-equal-height {
  height: calc(100% - 32px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media screen and (max-width: 575px) {
  .gx-card-equal-height {
    height: calc(100% - 20px);
  }
}
.gx-card-equal-height .ant-card-body {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-card-equal-height .ant-card-body .gx-rechart {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-card-equal-height .ant-card-body .gx-rechart .recharts-responsive-container {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
}
.gx-card-sm-p .ant-card-head {
  min-height: 10px;
}
.gx-card-sm-p .ant-card-head-title {
  padding: 14px 14px 0;
}
.gx-card-sm-p .ant-card-body {
  padding: 14px;
}
.gx-card-sm-px .ant-card-head {
  min-height: 10px;
}
.gx-card-sm-px .ant-card-head-title {
  padding-left: 10px;
  padding-right: 10px;
}
.gx-card-sm-px .ant-card-body {
  padding-left: 10px;
  padding-right: 10px;
}
.gx-entry-header {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .gx-entry-header {
    margin-bottom: 20px;
  }
}
.gx-entry-header .gx-entry-heading {
  margin-bottom: 0;
}
.gx-entry-header .gx-entry-description {
  margin-bottom: 0;
  font-size: 13px;
}
.gx-card-full {
  overflow: hidden;
}
.gx-card-full .gx-card-body {
  padding: 0;
}
.gx-card-full .gx-card-body .recharts-legend-wrapper {
  padding: 0 24px;
}
.gx-card-full .ant-card-body {
  padding: 0;
}
.gx-card-full .ant-card-body .recharts-legend-wrapper {
  padding: 0 24px;
}
.gx-card-full .recharts-default-legend {
  margin: 0 -10px !important;
}
.gx-card-full .recharts-default-legend li {
  padding: 0 10px;
  margin: 0 !important;
  display: inline-block;
  vertical-align: top;
}
.gx-card-full .recharts-default-legend li .recharts-legend-item-text {
  margin-left: 10px;
}
.gx-card-overview {
  margin-bottom: 32px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 24px;
}
@media screen and (max-width: 575px) {
  .gx-card-overview {
    margin-bottom: 20px;
  }
}
.gx-card-overview .gx-card-title {
  margin-bottom: 20px;
}
.gx-card-profile .ant-card-head {
  padding-top: 15px;
  padding-bottom: 16px;
}
.gx-card-profile.gx-card-tabs .ant-card-head {
  border-bottom: 1px solid #e8e8e8;
}
@media screen and (max-width: 575px) {
  .gx-card-profile.gx-card-tabs .ant-card-head {
    border-bottom: 0 none;
  }
}
.gx-card-profile .ant-card-body,
.gx-card-profile.gx-card-tabs .ant-card-body {
  padding-top: 0;
}
.gx-card-profile.gx-card-tabs .ant-tabs {
  margin-top: -50px;
}
@media screen and (max-width: 575px) {
  .gx-card-profile.gx-card-tabs .ant-tabs {
    margin-top: 0;
  }
}
.gx-card-profile .ant-tabs-tab {
  padding-top: 15px;
  padding-bottom: 18px;
}
.gx-card-profile .ant-card-head-title,
.gx-card-profile.gx-card-tabs .ant-card-head-title {
  font-size: 20px;
  text-transform: capitalize;
}
.gx-card-profile.gx-card-tabs .ant-tabs-nav-container {
  font-size: 14px;
  color: #8c8c8c;
}
.gx-card-profile-sm.gx-card-widget .ant-card-body {
  padding-top: 10px;
}
@media (max-width: 1366px) {
  .gx-card-1367-p .ant-card-head {
    min-height: 10px;
  }
  .gx-card-1367-p .ant-card-head-title {
    padding-left: 14px;
    padding-right: 14px;
  }
  .gx-card-1367-p .ant-card-body {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.ant-divider-horizontal.ant-divider-with-text,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  font-size: 14px;
}
/*Cascader Styles*/
.ant-cascader-picker {
  width: 100%;
}
/*Chart Styles*/
.recharts-default-tooltip {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #fa8c15;
}
.recharts-tooltip-item-list li {
  color: #038fde !important;
}
.dropdown-delete-item {
  padding: 0 ;
}
/*Form Styles*/
.ant-form .ant-row {
  margin-left: 0;
  margin-right: 0;
}
.ant-form-inline {
  margin-left: -8px;
  margin-right: -8px;
}
.ant-form-inline .ant-row {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-form-inline .ant-form-item {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .ant-form-inline .ant-form-item {
    display: block;
  }
}
.ant-form-horizontal .ant-row {
  margin-left: -15px;
  margin-right: -15px;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}
.ant-form-vertical .ant-row {
  -ms-flex-direction: column;
  flex-direction: column;
}
.ant-form-extra {
  word-break: break-all;
}
@media (max-width: 575px) {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}
.ant-form-item-with-help {
  margin-bottom: 20px;
}
.ant-advanced-search-form .gx-btn-link:hover {
  text-decoration: none;
}
.ant-form-item-control > .ant-form-item:last-child,
.ant-form-item [class^="ant-col-"] > .ant-form-item:only-child {
  margin-bottom: 0;
}
.gx-form-row0 .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.gx-login-form {
  max-width: 350px;
}
.gx-login-form .ant-form-item-control-wrapper {
  width: 100%;
}
.ant-form-inline .gx-form-item-one-fourth {
  width: 25%;
}
@media (max-width: 575px) {
  .ant-form-inline .gx-form-item-one-fourth {
    width: 100%;
  }
}
.ant-form-inline .gx-form-item-two-fourth {
  width: 50%;
}
@media (max-width: 575px) {
  .ant-form-inline .gx-form-item-two-fourth {
    width: 100%;
  }
}
.gx-form-inline-currency {
  margin: 0 -8px;
}
.gx-form-inline-currency .ant-form-item {
  padding: 0 8px;
}
.gx-form-inline-currency .gx-form-item-one-fourth {
  width: 30%;
}
@media (max-width: 575px) {
  .gx-form-inline-currency .gx-form-item-one-fourth {
    width: 40%;
    display: inline-block;
  }
}
.gx-form-inline-currency .gx-form-item-two-fourth {
  width: 40%;
}
@media (max-width: 575px) {
  .gx-form-inline-currency .gx-form-item-two-fourth {
    width: 100%;
    display: block;
  }
}
.gx-radio-wrapper {
  white-space: normal;
  margin-bottom: 10px;
  margin-right: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 1599px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
  .ant-form-item,
  .ant-form-item-with-help {
    margin-bottom: 12px;
  }
  .ant-advanced-search-form .ant-form-item {
    margin-bottom: 12px;
  }
}
@media (max-width: 991px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
}
@media (max-width: 575px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
    margin-bottom: 8px;
  }
  .ant-advanced-search-form .ant-btn,
  .ant-advanced-search-form .gx-btn-link {
    display: block;
    margin: 0 0 10px !important;
  }
}
/*Input Styles*/
.ant-input-search {
  margin-bottom: 16px;
}
.ant-input-search > .ant-input-suffix > .ant-input-search-button {
  margin-right: 0;
}
.ant-input-group > [class*="col-"] {
  padding-left: 0;
  float: left !important;
}
.ant-transfer-list {
  margin-bottom: 10px;
}
.ant-input-number {
  margin-right: 10px;
}
.gx-form-group {
  margin-bottom: 10px;
}
.gx-search-bar {
  position: relative;
}
.gx-search-bar .gx-form-group {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}
.gx-search-bar input[type="search"] {
  padding-right: 35px;
  background: rgba(250, 250, 250, 0.1);
}
.ant-layout-header .gx-search-bar input[type="search"],
.ant-popover-content .gx-search-bar input[type="search"] {
  border: 0 none;
}
.gx-search-bar input[type="search"]:focus {
  box-shadow: none;
  background-color: rgba(250, 250, 250, 0.3);
}
.gx-search-bar .gx-search-icon {
  background-color: transparent;
  border: 0 none;
  color: #d9d9d9;
  height: 32px;
  width: 30px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.gx-algolia-header .gx-search-bar .gx-search-icon {
  margin-top: 2px;
  text-align: left;
}
.gx-search-bar .ant-input-lg + .gx-search-icon {
  height: 40px;
  line-height: 45px;
}
.gx-lt-icon-search-bar input[type="search"] {
  padding-right: 16px;
  padding-left: 35px;
  border: 0 none;
}
.gx-lt-icon-search-bar .gx-search-icon {
  left: 0;
  right: auto;
}
.gx-algolia-header .gx-lt-icon-search-bar {
  margin-right: 10px;
}
@media screen and (max-width: 1199px) {
  .gx-algolia-header .gx-lt-icon-search-bar {
    width: 140px;
  }
}
@media screen and (max-width: 400px) {
  .gx-algolia-header .gx-lt-icon-search-bar {
    margin-right: 0;
    margin-bottom: 10px;
    width: auto;
  }
}
.gx-lt-icon-search-bar-lg {
  width: 350px;
}
.gx-lt-icon-search-bar-lg input[type="search"] {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  height: 46px;
  padding: 10px 18px 10px 40px;
}
@media screen and (max-width: 991px) {
  .gx-lt-icon-search-bar-lg input[type="search"] {
    height: 38px;
    padding: 5px 18px 5px 40px;
  }
}
.gx-header-search .gx-lt-icon-search-bar-lg input[type="search"] {
  background-color: #f5f5f5;
  height: 36px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border: solid 1px #e8e8e8;
}
.gx-header-search .gx-lt-icon-search-bar-lg input[type="search"]:focus {
  background-color: #f2f2f2;
}
.gx-header-horizontal-dark .gx-header-search .gx-lt-icon-search-bar-lg input[type="search"] {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.gx-header-horizontal-dark .gx-header-search .gx-lt-icon-search-bar-lg input[type="search"]:focus {
  background-color: transparent;
}
.gx-lt-icon-search-bar-lg .gx-search-icon {
  width: 35px;
  height: 46px;
  line-height: 50px;
  font-size: 16px;
  left: 0;
  right: auto;
}
@media screen and (max-width: 991px) {
  .gx-lt-icon-search-bar-lg .gx-search-icon {
    height: 38px;
    line-height: 40px;
  }
}
.gx-header-search .gx-lt-icon-search-bar-lg .gx-search-icon {
  height: 36px;
  line-height: 40px;
}
.gx-inside-header-horizontal .gx-lt-icon-search-bar-lg {
  width: 200px;
  margin-right: 10px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .gx-lt-icon-search-bar-lg {
    width: 120px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-lt-icon-search-bar-lg {
    width: 150px;
  }
}
.gx-popover-search-bar {
  width: 150px;
  margin: -10px -14px;
}
.gx-chat-search-bar {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 100px;
  width: auto;
}
.gx-chat-search-bar input[type="search"] {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 40px;
  border: 0 none;
}
.gx-chat-search-bar input[type="search"]:focus {
  background-color: #ffffff;
}
.gx-chat-search-bar .gx-search-icon {
  height: 40px;
  line-height: 44px;
}
.ant-transfer-list {
  width: 150px;
}
@media screen and (max-width: 575px) {
  .ant-transfer-list {
    width: 100%;
  }
}
.gx-select-sm {
  font-size: 12px;
}
.gx-select-sm .ant-select-selection {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-select-sm .ant-select-selection--single,
.gx-select-sm.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
  padding: 0 7px;
}
.gx-select-sm.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 22px;
}
.gx-select-sm.ant-select-single .ant-select-selector .ant-select-selection-item,
.gx-select-sm.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.gx-select-sm .ant-select-selection__rendered {
  line-height: 22px;
  margin-left: 6px;
  margin-right: 6px;
}
.gx-select-sm .ant-select-selection-selected-value {
  padding-right: 20px;
}
.gx-select-sm .ant-select-arrow {
  right: 6px;
}
.gx-select-sm .ant-select-dropdown {
  font-size: 12px;
}
.gx-form-inline-label-up .ant-form-item,
.gx-form-inline-label-up .ant-form-item > .ant-form-item-control-wrapper,
.gx-form-inline-label-up .ant-form-item > .ant-form-item-label {
  display: block;
}
.gx-form-inline-label-up .ant-form-item-label {
  text-align: left;
  color: #bfbfbf;
  line-height: 1;
  margin-bottom: 4px;
}
.gx-form-inline-label-up .ant-select-selection--single,
.gx-form-inline-label-up .ant-input {
  height: 30px;
}
.gx-form-inline-label-up .ant-select-selection__rendered {
  line-height: 28px;
}
.gx-form-inline-label-up .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px;
}
.gx-form-inline-label-up .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 28px;
}
.gx-form-inline-label-up .ant-select-single .ant-select-selector .ant-select-selection-item,
.gx-form-inline-label-up .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 28px;
}
.gx-form-inline-label-up.ant-form-inline .ant-form-item > .ant-form-item-label,
.gx-form-inline-label-up.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: block;
}
.gx-form-inline-label-up .ant-form-item-label > label {
  height: auto;
}
.gx-input-lineheight {
  line-height: 36px;
}
.ant-mention-wrapper {
  border: solid 1px #e8e8e8;
  padding: 8.6px 11px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media screen and (max-width: 575px) {
  .gx-ant-transfer-width .ant-transfer-list {
    width: 100% !important;
  }
}
@media screen and (max-width: 475px) {
  .ant-input-group.ant-input-group-compact > * {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    width: 100% !important;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
    border-radius: 4px;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact .gx-border-lt-xs {
    border-left: 1px solid #d9d9d9 !important;
  }
}
/*Icon Styles*/
.gx-icon-views {
  position: relative;
  text-align: center;
  padding: 16px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-bottom: 15px;
  height: calc(100% - 15px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-icon-views .icon {
  margin-bottom: 12px;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  line-height: 1;
  display: block;
}
.gx-icon-views:hover {
  background-color: #038fde;
  cursor: pointer;
  color: #ffffff;
}
.gx-icon-views:hover .icon {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
}
.gx-icon-text {
  display: block;
  font-size: 12px;
}
.ant-btn-icon-only {
  padding: 0;
}
/*List Styles*/
.ant-list-vertical .ant-list-item-main {
  min-width: 200px;
}
@media screen and (max-width: 480px) {
  .ant-list-vertical .ant-list-item-extra {
    margin-bottom: 10px;
  }
}
.gx-list-item .ant-list-item-extra-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-list-item .ant-list-item-extra-wrap .ant-list-item-extra {
  margin-left: 20px;
}
@media screen and (max-width: 575px) {
  .gx-list-item .ant-list-item-extra-wrap .ant-list-item-extra {
    margin-left: 0;
    margin-top: 10px;
  }
  .gx-list-item .ant-list-item-extra-wrap .ant-list-item-extra img {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .gx-list-item .ant-list-item-extra-wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.gx-user-list {
  width: 100%;
  padding-bottom: 10px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-user-list:not(:first-child) {
  padding-top: 20px;
}
@media screen and (max-width: 575px) {
  .gx-user-list:not(:first-child) {
    padding-top: 0;
  }
}
.gx-user-list.gx-card-list {
  padding: 30px;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 32px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
.gx-user-list.gx-card-list .gx-avatar-img {
  margin-right: 25px;
}
@media screen and (max-width: 767px) {
  .gx-user-list.gx-card-list {
    padding: 20px;
  }
}
@media screen and (max-width: 575px) {
  .gx-user-list.gx-card-list {
    margin-bottom: 20px;
  }
}
.gx-user-list .gx-avatar-img {
  margin-right: 20px;
  margin-bottom: 10px;
}
.gx-user-list h3 {
  font-weight: 500;
  margin-bottom: 8px;
}
.gx-user-list .gx-link[class*="gx-meta-"] {
  display: inline-block;
  color: #444;
  font-size: 12px;
  font-weight: 500;
}
.gx-user-list .gx-link[class*="gx-meta-"] i {
  margin-right: 6px;
  vertical-align: middle;
}
.gx-user-list .gx-description {
  width: calc(100% - 120px);
}
@media screen and (max-width: 575px) {
  .gx-user-list .gx-description {
    width: 100%;
  }
}
.gx-user-list .gx-btn-list li:not(:last-child) {
  margin-right: 10px;
}
.gx-list-inline {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 0;
  list-style: none;
}
.gx-list-inline li {
  padding: 0 5px;
  display: inline-block;
}
.gx-list-inline-lg {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 0;
  list-style: none;
}
.gx-list-inline-lg li {
  padding: 0 10px;
  display: inline-block;
}
.gx-card-strip {
  margin-bottom: 10px;
}
.gx-card-strip:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-card-list .gx-description {
  width: calc(100% - 230px);
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .gx-card-list .gx-description {
    width: calc(100% - 80px);
  }
}
.gx-card-list .gx-card-list-footer {
  padding-left: 15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-width: 150px;
}
.gx-card-list .gx-card-list-footer .ant-btn {
  display: block;
  margin-right: 0;
  width: 120px;
}
.gx-card-list .gx-card-list-footer .ant-btn:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .gx-card-list .gx-card-list-footer {
    padding-left: 0;
    min-width: 10px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .gx-card-list .gx-card-list-footer .ant-btn {
    width: auto;
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.gx-list-group {
  list-style: none;
  padding-left: 0;
}
.gx-list-group li {
  margin-bottom: 6px;
}
.gx-list-group li i,
.gx-list-group li .anticon {
  margin-right: 12px;
}
.gx-dot-list {
  list-style: none;
  margin: 0 -3px 10px;
  padding-left: 0;
}
.gx-dot-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 3px;
}
@media screen and (max-width: 575px) {
  .gx-dot-list {
    margin-bottom: 5px;
  }
}
/*Loader Styles*/
.ant-spin {
  margin-right: 16px;
  margin-bottom: 10px;
}
.gx-loader-container {
  text-align: center;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 30px 50px;
  margin-right: 0;
  display: block;
}
/*Pagination Styles*/
.ant-pagination > li {
  margin-bottom: 10px;
}
.ant-pagination.mini:not(:last-child) {
  margin-bottom: 10px;
}
.ant-pagination.mini li {
  margin-bottom: 5px !important;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  line-height: 30px;
}
/*Pickers Styles*/
.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
  width: 100% !important;
}
.gx-alpha-pickers {
  position: relative;
  min-height: 250px;
}
.ant-calendar-footer .ant-calendar-ok-btn {
  margin-bottom: 0;
}
/*Progress Styles*/
.ant-progress-line {
  margin-right: 8px;
  margin-bottom: 8px;
}
.ant-progress-circle {
  margin-right: 8px;
  margin-bottom: 8px;
}
/*Steps Styles*/
.ant-steps-dot {
  flex-wrap: wrap;
  overflow: hidden;
  padding: 10px 0;
}
.ant-steps-dot .ant-steps-item {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .ant-steps-dot .ant-steps-item-description {
    display: none;
  }
}
/*Slider Styles*/
.ant-carousel h1,
.ant-carousel h2,
.ant-carousel h3,
.ant-carousel h4,
.ant-carousel h5,
.ant-carousel h6 {
  line-height: inherit;
  margin-bottom: 0;
}
.ant-carousel .slick-dots {
  margin-left: 0;
  margin-right: 0;
}
.gx-vertical-slider {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.gx-vertical-slider-item {
  width: 33.33%;
  padding: 0 15px;
  height: 300px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-vertical-slider-item {
    width: 50%;
    margin-bottom: 15px;
  }
}
.ant-carousel-vertical .gx-vertical-slide .slick-slide {
  height: 160px;
}
.slick-slider {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .slick-slider {
    margin-bottom: 20px;
  }
}
.slick-slider .slick-prev {
  left: -10px;
}
.slick-slider .slick-prev:before {
  color: #000000;
}
.slick-slider .slick-next {
  right: -10px;
}
.slick-slider .slick-next:before {
  color: #000000;
}
.slick-slider img {
  width: 100%;
  object-fit: cover;
}
.slick-dots li button:before {
  display: none;
}
.gx-slick-slider .slick-dots {
  bottom: 26px;
  padding: 0 24px;
  text-align: left;
}
.gx-slick-slider .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}
.gx-slick-slider .slick-dots li button {
  width: 10px !important;
  height: 10px !important;
}
.gx-slick-slider .slick-dots li button:before {
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  color: transparent;
  border: solid 2px #000000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
}
.gx-slick-slider .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
}
.gx-slick-slider .slick-dots li.slick-active button:before {
  color: transparent;
  border-color: #038fde;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}
.gx-slide-item {
  margin-left: 15px;
  margin-right: 15px;
}
@media screen and (max-width: 480px) {
  .gx-slide-item {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.gx-slider {
  position: relative;
}
.gx-slider-img {
  position: relative;
  padding-bottom: 24px;
}
.gx-slider-img .gx-img-up {
  position: absolute;
  left: 24px;
  bottom: -5px;
  z-index: 1;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: auto;
  max-width: 64px;
}
.gx-slider-content {
  padding: 24px;
}
.gx-slick-slider {
  margin-bottom: 0;
  padding-bottom: 24px;
}
.gx-slick-slider-dot-top {
  padding-top: 70px;
  padding-right: 24px;
  padding-bottom: 58px;
}
@media (max-width: 1366px) {
  .gx-slick-slider-dot-top {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
.gx-slick-slider-dot-top .slick-dots {
  bottom: auto;
  top: 30px;
  padding-left: 0;
}
@media (max-width: 1366px) {
  .gx-slick-slider-dot-top .slick-dots {
    top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .gx-slick-slider-dot-top {
    padding-left: 24px;
  }
}
.gx-slick-slider-lt-thumb {
  height: 100%;
  position: relative;
}
.gx-slick-slider-lt-thumb img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 575px) {
  .gx-slick-slider-lt-thumb img {
    position: relative;
    left: auto;
    top: auto;
  }
}
.ant-modal .slides {
  position: relative;
}
.ant-modal .slides .slick-prev,
.ant-modal .slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -10px;
}
.ant-modal .slides .slick-prev {
  left: 20px;
}
.ant-modal .slides .slick-next {
  right: 20px;
}
.ant-modal .slick-slider {
  margin-bottom: 0;
}
/*Tables Styles*/
.ant-table-small .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: normal;
}
.gx-table-responsive .ant-table {
  min-height: 0.01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .gx-table-responsive .ant-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: solid 1px #e8e8e8;
  }
  .ant-card-bordered.gx-card-full .gx-table-responsive .ant-table {
    border: 0 none;
    border-bottom: solid 1px #e8e8e8;
  }
  .gx-table-responsive .ant-table .ant-table-tbody > tr:last-child > td {
    border-bottom: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-header > table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-body > table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-fixed-left table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-fixed-right table {
    border-top: 0 none;
    border-left: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-title {
    border: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-footer {
    border: 0 none;
    border-top: solid 1px #e8e8e8;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-thead > tr > th:last-child,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-tbody > tr > td:last-child {
    border-right: 0 none;
  }
  .gx-table-responsive .ant-table-body > table > thead > tr > th,
  .gx-table-responsive .ant-table-body > table > tbody > tr > th,
  .gx-table-responsive .ant-table-body > table > tfoot > tr > th,
  .gx-table-responsive .ant-table-body > table > thead > tr > td,
  .gx-table-responsive .ant-table-body > table > tbody > tr > td,
  .gx-table-responsive .ant-table-body > table > tfoot > tr > td {
    white-space: nowrap;
  }
}
.gx-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.gx-table th,
.gx-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: solid 1px #e8e8e8;
}
.gx-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8e8e8;
}
.gx-table tbody + tbody {
  border-top: 2px solid #e8e8e8;
}
.gx-table-bordered {
  border: 1px solid #e8e8e8;
}
.gx-table-bordered th,
.gx-table-bordered td {
  border: 1px solid #e8e8e8;
}
.gx-table-bordered thead th,
.gx-table-bordered thead td {
  border-bottom-width: 2px;
}
.gx-table-no-bordered {
  border: 0 none;
}
.gx-table-no-bordered th,
.gx-table-no-bordered td {
  border: 0 none;
}
.gx-table-no-bordered thead th,
.gx-table-no-bordered thead td {
  border-bottom-width: 2px;
}
.gx-table-no-bordered .ant-table {
  border: 0 none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.gx-table-no-bordered .ant-table-thead > tr > th {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 11px;
}
.gx-table-no-bordered .ant-table-tbody > tr > td {
  border-bottom: 0 none;
}
.gx-table-thead-uppercase thead th {
  text-transform: uppercase;
}
.gx-table-padding-lg thead th,
.gx-table-padding-lg tbody td {
  padding: 20px 30px !important;
}
@media screen and (max-width: 991px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 15px 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 10px !important;
  }
}
.gx-table-padding-lg .ant-table-pagination {
  float: none;
  text-align: center;
  margin: 22px 0;
}
@media screen and (max-width: 991px) {
  .gx-table-padding-lg .ant-table-pagination {
    margin: 16px 0;
  }
}
/*Time Lines Style*/
/*Antd Time Lines Style*/
.ant-timeline-item {
  padding: 0;
}
.ant-timeline-item:not(:last-child) {
  padding-bottom: 20px;
}
@media screen and (max-width: 575px) {
  .ant-timeline-item:not(:last-child) {
    padding-bottom: 15px;
  }
}
.gx-timeline-info {
  position: relative;
}
.gx-timeline-info:not(:last-child) {
  padding-bottom: 20px;
}
.gx-timeline-info-day {
  margin-bottom: 15px;
  font-size: 12px;
  color: #595959;
}
.gx-timeline-info .ant-timeline {
  padding-top: 0;
  padding-left: 0;
}
.gx-timeline-info .ant-timeline-item-head-custom {
  padding: 0;
  margin-right: 15px;
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.gx-timeline-info .ant-timeline-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.gx-timeline-info .ant-timeline-item-content {
  padding-left: 0;
  top: 0;
  margin-left: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #595959;
}
.gx-timeline-info .ant-timeline-item-content .gx-link {
  color: #262626;
}
.gx-timeline-info .ant-timeline-item-content .gx-link:hover,
.gx-timeline-info .ant-timeline-item-content .gx-link:focus {
  color: #038fde;
}
.gx-timeline-info .ant-timeline-item-tail {
  display: none;
}
.gx-img-more {
  width: 60px;
  height: 40px;
  background-color: #ffffff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  border: solid 1px #e8e8e8;
}
/*Custom Time Lines Style*/
.gx-timeline-left {
  float: left;
  margin-right: 20px;
}
.gx-timeline-right {
  float: right;
  margin-left: 20px;
}
.gx-timeline-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.gx-timeline-section {
  margin-bottom: 25px;
  overflow: hidden;
}
.gx-timeline-item {
  padding-bottom: 25px;
  padding-left: 80px;
  position: relative;
}
.gx-timeline-item:before {
  border-left: solid 2px #e8e8e8;
  content: "";
  left: 25px;
  position: absolute;
  top: 25px;
  bottom: -20px;
  width: 2px;
  z-index: 1;
}
.gx-timeline-item:first-child:before {
  border-left-style: dashed;
}
.gx-timeline-item:last-child:before {
  border-left-style: dashed;
  bottom: 25px;
}
.gx-timeline-badge {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.gx-timeline-badge.gx-timeline-img {
  background-color: transparent;
}
.gx-timeline-badge.gx-timeline-img:after {
  display: none;
}
.gx-timeline-badge:after {
  position: absolute;
  content: "";
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 4px solid #e8e8e8;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 4px;
  top: 4px;
  z-index: 1;
}
.gx-timeline-badge [class^="gx-"]:before,
.gx-timeline-badge [class*="gx-"]:before {
  line-height: 50px;
}
.gx-timeline-img > img,
.gx-timeline-header-img > img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.gx-timeline-panel {
  padding: 20px 30px;
  position: relative;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: solid 1px #e8e8e8;
}
.gx-timeline-panel:after,
.gx-timeline-panel:before {
  content: "";
  position: absolute;
  border-style: solid;
  display: inline-block;
  top: 30px;
}
.gx-timeline-panel:before {
  border-color: transparent #e8e8e8;
  border-width: 10px 17px 10px 0;
  left: -18px;
}
.gx-timeline-panel:after {
  border-color: transparent #ffffff;
  border-width: 10px 16px 10px 0;
  left: -16px;
}
.gx-timeline-no-padding {
  padding: 0;
}
.gx-timeline-panel-header {
  margin-bottom: 10px;
}
.gx-timeline-header-img {
  margin-bottom: 10px;
}
.gx-timeline-inverted .gx-timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px;
}
.gx-timeline-heading {
  overflow: hidden;
  margin-bottom: 10px;
}
.gx-timeline-body {
  clear: both;
}
/*Gxtl Center Style*/
.gx-timeline-center .gx-timeline-item {
  clear: both;
  float: right;
  width: 50%;
  padding-left: 55px;
}
.gx-timeline-center .gx-timeline-item:before {
  left: 0;
}
.gx-timeline-center .gx-timeline-badge {
  left: -25px;
  right: auto;
}
.gx-timeline-center .gx-timeline-time {
  position: absolute;
  top: 32px;
  left: -150px;
  right: auto;
  z-index: 2;
}
.gx-timeline-center .gx-timeline-inverted {
  float: left;
  text-align: right;
  padding-left: 0;
  padding-right: 55px;
}
.gx-timeline-center .gx-timeline-inverted:before {
  border-left: 0 none;
  border-right: solid 2px #e8e8e8;
  left: auto;
  right: -2px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-badge {
  left: auto;
  right: -25px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:before {
  border-width: 10px 0 10px 17px;
  left: auto;
  right: -18px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:after {
  border-width: 10px 0 10px 16px;
  left: auto;
  right: -16px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-time {
  position: absolute;
  top: 32px;
  right: -150px;
  left: auto;
  z-index: 2;
}
/*Gxtl Zigzag Style*/
.gx-timeline-zigzag .gx-timeline-item {
  padding-left: 150px;
}
.gx-timeline-zigzag .gx-timeline-item + .gx-timeline-item {
  margin-top: -80px;
}
.gx-timeline-zigzag .gx-timeline-img {
  width: 90px;
  height: 90px;
  left: 5px;
}
.gx-timeline-zigzag .gx-timeline-img > img {
  width: 100%;
}
.gx-timeline-zigzag .gx-timeline-inverted .gx-timeline-img {
  right: 5px !important;
}
.gx-timeline-zigzag .gx-timeline-item:before {
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  top: 20px;
  bottom: 20px;
}
.gx-timeline-zigzag .gx-timeline-inverted {
  padding-left: 0;
  padding-right: 150px;
}
.gx-timeline-zigzag .gx-timeline-inverted:before {
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.gx-timeline-zigzag .gx-timeline-item:first-child:before {
  border-left-style: solid;
}
.gx-timeline-zigzag .gx-timeline-item:last-child:before {
  display: none;
}
.gx-timeline-item:last-child:before {
  bottom: 25px;
}
@media screen and (max-width: 991px) {
  .gx-timeline-zigzag .gx-timeline-item + .gx-timeline-item {
    margin-top: -60px;
  }
  .gx-timeline-zigzag .gx-timeline-item:before {
    bottom: 0;
  }
  .gx-timeline-zigzag .gx-timeline-item {
    padding-left: 120px;
  }
  .gx-timeline-zigzag .gx-timeline-inverted {
    padding-right: 120px;
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .gx-timeline-center .gx-timeline-item {
    float: none;
    padding-left: 80px;
    width: auto;
  }
  .gx-timeline-center .gx-timeline-item:before {
    left: 25px;
  }
  .gx-timeline-center .gx-timeline-badge {
    left: 0;
  }
  .gx-timeline-center .gx-timeline-inverted {
    float: none;
    text-align: left;
    padding-right: 0;
  }
  .gx-timeline-center .gx-timeline-inverted:before {
    border-left: solid 2px #e8e8e8;
    border-right: 0 none;
    left: 24px;
    right: auto;
  }
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-badge {
    right: auto;
    left: 0;
  }
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:before {
    border-width: 10px 17px 10px 0 !important;
    left: -18px;
    right: auto;
  }
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:after {
    border-width: 10px 16px 10px 0 !important;
    left: -16px;
    right: auto;
  }
  .gx-timeline-inverted .gx-timeline-panel-header {
    float: none;
  }
  .gx-timeline-inverted .gx-timeline-left {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
  .gx-timeline-zigzag .gx-timeline-panel {
    max-height: none;
    min-height: 10px;
    overflow-y: visible;
  }
  .gx-timeline-zigzag .gx-timeline-item {
    padding-left: 100px;
  }
  .gx-timeline-zigzag .gx-timeline-item + .gx-timeline-item {
    margin-top: 0;
  }
  .gx-timeline-zigzag .gx-timeline-item:before {
    transform: none;
    top: 25px;
    bottom: -25px;
    left: 45px;
  }
  .gx-timeline-zigzag .gx-timeline-item:last-child:before {
    bottom: 0;
  }
  .gx-timeline-zigzag .gx-timeline-inverted:before {
    transform: none;
  }
  .gx-timeline-center .gx-timeline-time,
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-time {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 6px;
  }
  .gx-timeline-center .gx-timeline-time-item .gx-timeline-panel::before,
  .gx-timeline-center .gx-timeline-time-item .gx-timeline-panel::after {
    top: 10px;
  }
}
@media screen and (max-width: 399px) {
  .gx-timeline-left {
    float: none;
    margin-right: 0;
  }
  .gx-timeline-right {
    float: none;
    margin-left: 0;
  }
  .gx-timeline-inverted .gx-timeline-left {
    float: none;
    margin-right: 0;
  }
}
/*Tabs Styles*/
.gx-tabs-half .ant-tabs-bar {
  margin-bottom: 0;
}
.gx-tabs-half .ant-tabs-nav {
  display: block;
}
.gx-tabs-half .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  width: 50%;
  text-align: center;
  display: block;
}
.gx-tabs-half .ant-tabs-nav .ant-tabs-ink-bar {
  width: 50%;
}
.gx-card-tabs .ant-card-head-title {
  text-transform: capitalize;
  font-size: 16px;
}
.gx-card-tabs .ant-card-extra {
  position: relative;
  z-index: 2;
}
.gx-card-tabs .ant-tabs {
  margin-top: -40px;
}
@media screen and (max-width: 575px) {
  .gx-card-tabs .ant-tabs {
    margin-top: 0;
  }
}
.gx-card-tabs .ant-tabs-tab {
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 15px;
}
.gx-card-tabs .ant-tabs-tab-active {
  font-weight: 400;
}
.gx-card-tabs .ant-tabs-nav-wrap {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  font-size: 16px;
}
@media screen and (max-width: 575px) {
  .gx-card-tabs .ant-tabs-nav-wrap {
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-card-tabs .ant-card-body {
  padding-top: 4px;
}
.gx-tabs-right .ant-tabs-nav-wrap {
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
}
@media screen and (max-width: 575px) {
  .gx-tabs-right .ant-tabs-nav-wrap {
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
/* Apps Style */
.gx-app-module {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -18px -18px 0;
  flex: 1;
}
@media screen and (max-width: 575px) {
  .gx-app-module {
    margin: -20px -15px 0;
  }
}
.gx-container-wrap .gx-app-module {
  margin: 0;
  margin-bottom: 32px;
}
.gx-module-side {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 0 1 230px;
  -ms-flex: 0 1 230px;
  flex: 0 1 230px;
  min-width: 230px;
}
@media screen and (max-width: 991px) {
  .gx-module-side {
    min-width: 260px;
    background-color: #ffffff;
  }
}
.gx-module-side-header {
  padding: 10px;
  min-height: 83px;
  border-bottom: solid 1px #e8e8e8;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #fbfbfb;
  color: #545454;
}
@media screen and (max-width: 991px) {
  .gx-module-side-header {
    min-height: 76px;
  }
}
.gx-module-logo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 20px;
}
.gx-module-logo i {
  font-size: 28px;
  line-height: 18px;
}
.gx-module-user-info,
.gx-module-side-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-module-user-detail {
  font-size: 12px;
}
.gx-module-add-task {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 24px;
}
.gx-module-add-task .ant-btn {
  margin-bottom: 0;
}
.gx-module-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-module-nav li {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-module-nav li .gx-link {
  padding: 5px 29px;
  color: #999999;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-decoration: none;
}
.gx-module-nav li .gx-link:hover,
.gx-module-nav li .gx-link:focus {
  color: #000000;
}
.gx-module-nav li .gx-link.active {
  color: #038fde;
}
.gx-module-nav li i {
  margin-right: 16px;
  font-size: 16px;
}
.gx-module-nav li i[class^="icon-"]::before,
.gx-module-nav li i[class*=" icon-"]::before {
  display: block;
}
.gx-module-nav li span {
  display: inline-block;
  vertical-align: middle;
}
.gx-module-nav li.gx-module-nav-label {
  padding: 12px 29px;
}
.gx-module-box {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: calc(100% - 230px - 1px);
}
@media screen and (max-width: 991px) {
  .gx-module-box {
    max-width: 100%;
  }
}
.gx-module-box-header {
  padding: 10px 26px;
  min-height: 79px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: solid 1px #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-module-box-header {
    position: relative;
    min-height: 58px;
    padding: 5px 26px 5px 110px;
  }
}
@media screen and (max-width: 991px) {
  .gx-module-box-header .gx-drawer-btn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-right: solid 1px #e8e8e8;
    height: 58px;
    width: 98px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 22px;
    text-align: center;
  }
}
.gx-module-box-header-inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-module-box-header-right {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-left: auto;
}
.gx-module-box-content {
  background-color: #ffffff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.gx-module-box-topbar {
  padding: 8px 28px;
  min-height: 65px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px #e8e8e8;
}
.gx-module-box-topbar label.ant-checkbox-wrapper {
  margin-right: 10px;
}
.gx-module-box-topbar .ant-dropdown-trigger {
  cursor: pointer;
  margin-right: 10px;
}
.gx-module-box-topbar .ant-dropdown-trigger .icon:before {
  position: relative;
  top: 3px;
}
.gx-module-box-topbar-todo {
  padding-left: 28px;
}
@media screen and (max-width: 991px) {
  .gx-module-box-topbar {
    min-height: 58px;
    padding-left: 28px;
    padding-right: 28px;
  }
}
.gx-module-list-icon {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-right: 10px;
}
.gx-module-list-icon .gx-bar-icon .gx-icon-menu:before {
  top: 3px;
  position: relative;
}
.gx-toolbar-separator {
  border-left: solid 1px #e8e8e8;
  height: 100%;
  width: 1px;
  margin: 0 12px;
}
.gx-module-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-module-list-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 15px 28px;
  z-index: 5;
  position: relative;
  cursor: pointer;
}
.gx-module-list-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-module-list-item.gx-mail-cell {
  -webkit-align-items: start;
  -ms-align-items: start;
  align-items: start;
}
@media screen and (max-width: 575px) {
  .gx-module-list-item {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.gx-module-detail-header {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .gx-module-detail-header {
    margin-bottom: 0;
  }
}
.gx-module-list-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: calc(100% - 140px);
}
@media screen and (max-width: 575px) {
  .gx-module-list-info {
    max-width: calc(100% - 100px);
  }
}
.gx-module-todo-content {
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.gx-module-todo-content .gx-subject {
  margin-bottom: 5px;
}
.gx-module-todo-right {
  margin-left: 8px;
}
.gx-module-sidenav {
  background-color: #ffffff;
  border-right: solid 1px #e8e8e8;
  position: relative;
  z-index: 9;
}
.gx-module-sidenav .gx-chat-sidenav-main {
  max-width: 300px;
  min-width: 300px;
  width: 90%;
}
.gx-module-date {
  max-width: 160px;
}
.gx-module-detail-item {
  padding: 10px 28px;
}
.gx-task-title {
  font-size: 18px;
}
@media screen and (max-width: 991px) {
  .gx-task-title {
    font-size: 16px;
  }
}
.gx-chat-todo-avatar {
  margin-right: 15px;
}
.gx-module-side-scroll {
  height: calc(100vh - 201px) !important;
}
.framed-layout .gx-module-side-scroll {
  height: calc(100vh - 201px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-module-side-scroll {
    height: calc(100vh - 76px) !important;
  }
  .framed-layout .gx-module-side-scroll {
    height: calc(100vh - 76px) !important;
  }
}
.gx-module-side-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-module-content-scroll {
  height: calc(100vh - 266px) !important;
}
.framed-layout .gx-module-content-scroll {
  height: calc(100vh - 266px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-module-content-scroll {
    height: calc(100vh - 238px) !important;
  }
  .framed-layout .gx-module-content-scroll {
    height: calc(100vh - 238px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-module-content-scroll {
    height: calc(100vh - 216px) !important;
  }
}
.gx-module-content-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-todo-detail-content-scroll {
  height: calc(100vh - 319px) !important;
}
.framed-layout .gx-todo-detail-content-scroll {
  height: calc(100vh - 319px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-todo-detail-content-scroll {
    height: calc(100vh - 291px) !important;
  }
  .framed-layout .gx-todo-detail-content-scroll {
    height: calc(100vh - 291px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-todo-detail-content-scroll {
    height: calc(100vh - 270px) !important;
  }
}
.gx-todo-detail-content-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-loader-view {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.gx-loader-view .ant-spin {
  margin: 0;
}
.gx-app-module .gx-loader-view {
  height: calc(100vh - 266px) !important;
}
.framed-layout .gx-app-module .gx-loader-view {
  height: calc(100vh - 266px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-app-module .gx-loader-view {
    height: calc(100vh - 238px) !important;
  }
  .framed-layout .gx-app-module .gx-loader-view {
    height: calc(100vh - 238px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-app-module .gx-loader-view {
    height: calc(100vh - 244px) !important;
  }
}
.gx-chat-module .gx-loader-view {
  height: calc(100vh - 122px) !important;
}
.framed-layout .gx-chat-module .gx-loader-view {
  height: calc(100vh - 122px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-chat-module .gx-loader-view {
    height: calc(100vh - 122px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-chat-module .gx-loader-view {
    height: calc(100vh - 100px) !important;
  }
}
.gx-loader-position {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  bottom: 0;
}
.gx-module-default {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 100%;
  padding: 15px;
}
.gx-no-content-found {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 16px;
}
.gx-task-des,
.gx-task-input {
  padding-left: 0;
}
/*Mails Apps Styles*/
.gx-module-box-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.gx-module-box-column,
.gx-module-list-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-mail-list-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-left: 10px;
  max-width: calc(100% - 150px);
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
@media screen and (max-width: 575px) {
  .gx-mail-list-info {
    max-width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }
}
.gx-mail-user-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-left: 10px;
}
.gx-mail-user-info .gx-avatar {
  margin-right: 10px;
}
.gx-mail-user-info .gx-badge {
  margin-bottom: 0;
}
.gx-mail-user-des {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 3px;
}
.gx-mail-user-des .gx-time,
.gx-mail-user-des .gx-icon-btn {
  margin-left: auto;
}
.gx-mail-detail-inner {
  padding: 24px;
}
@media screen and (max-width: 991px) {
  .gx-mail-detail-inner {
    padding-right: 28px;
    padding-left: 28px;
  }
}
.gx-mail-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 498px) {
  .gx-mail-header {
    display: block;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.gx-mail-header-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-mail-header-content .gx-subject {
  margin-bottom: 5px;
}
.gx-show-link {
  cursor: pointer;
  margin-bottom: 8px;
  color: #038fde;
}
.gx-show-detail {
  margin-bottom: 12px;
}
.gx-module-list-content .gx-subject {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gx-module-list-content .gx-message {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  color: #8d8d8d;
  font-size: 13px;
}
.gx-module-list-content .gx-message p {
  margin-bottom: 8px;
}
.gx-size {
  margin: 5px 0;
}
.gx-sender-name {
  margin-right: 0;
  font-weight: 500;
  font-size: 15px;
}
.gx-send-subject {
  width: calc(100% - 220px);
}
@media screen and (max-width: 575px) {
  .gx-send-subject {
    width: calc(100% - 180px);
    margin-right: 6px;
  }
}
.gx-mail-header-actions {
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  min-width: 100px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-mail-header-actions .icon-btn:not(:last-child) {
  margin-right: 3px;
}
@media screen and (max-width: 498px) {
  .gx-mail-header-actions {
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    min-width: 10px;
  }
}
.gx-attachment-block {
  position: relative;
}
.gx-attachment-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media screen and (max-width: 575px) {
  .gx-attachment-row {
    margin: 0 -5px;
  }
}
.gx-attachment-col {
  padding: 0 10px;
}
.gx-attachment-col img {
  width: 80px;
}
.gx-attachment-col .size {
  padding-top: 5px;
}
@media screen and (max-width: 575px) {
  .gx-attachment-col {
    padding: 0 5px;
  }
}
@media screen and (max-width: 575px) {
  .gx-mail-list {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    border-right: 0 none;
  }
  .gx-mail-detail {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
  }
}
/*Chat Apps Styles*/
.gx-chat-module {
  position: relative;
}
.gx-chat-module-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  background-color: #fafafa;
  position: relative;
  width: 100%;
}
.gx-chat-sidenav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 0 1 315px;
  -ms-flex: 0 1 315px;
  flex: 0 1 315px;
  min-width: 315px;
  border-right: solid 1px #e8e8e8;
}
.gx-chat-sidenav-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 25px 20px 12px;
  background-color: #fbfbfb;
  border-bottom: solid 1px #e8e8e8;
}
.gx-chat-user-hd {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 25px;
}
.gx-chat-search-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
.gx-chat-avatar {
  min-width: 50px;
}
.gx-chat-user-row .gx-chat-avatar {
  padding: 0 5px;
  min-width: 10px;
}
.gx-chat-user-hd .gx-chat-avatar {
  cursor: pointer;
}
.gx-chat-sidenav-scroll {
  height: calc(100vh - 295px) !important;
}
.framed-layout .gx-chat-sidenav-scroll {
  height: calc(100vh - 295px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-chat-sidenav-scroll {
    height: calc(100vh - 175px) !important;
  }
  .framed-layout .gx-chat-sidenav-scroll {
    height: calc(100vh - 175px) !important;
  }
}
.gx-chat-sidenav-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-chat-sidenav-scroll-tab-1,
.gx-chat-sidenav-scroll-tab-2 {
  height: calc(100vh - 320px) !important;
}
.framed-layout .gx-chat-sidenav-scroll-tab-1,
.framed-layout .gx-chat-sidenav-scroll-tab-2 {
  height: calc(100vh - 320px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-chat-sidenav-scroll-tab-1,
  .gx-chat-sidenav-scroll-tab-2 {
    height: calc(100vh - 198px) !important;
  }
  .framed-layout .gx-chat-sidenav-scroll-tab-1,
  .framed-layout .gx-chat-sidenav-scroll-tab-2 {
    height: calc(100vh - 198px) !important;
  }
}
.gx-chat-sidenav-scroll-tab-1 > div:first-child,
.gx-chat-sidenav-scroll-tab-2 > div:first-child {
  overflow-y: scroll !important;
}
.gx-chat-list-scroll {
  height: calc(100vh - 268px) !important;
}
.framed-layout .gx-chat-list-scroll {
  height: calc(100vh - 268px - 2 * 20px) !important;
}
@media screen and (max-width: 1199px) {
  .gx-chat-list-scroll {
    height: calc(100vh - 242px) !important;
  }
  .framed-layout .gx-chat-list-scroll {
    height: calc(100vh - 242px - 2 * 20px) !important;
  }
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-chat-list-scroll {
    height: calc(100vh - 242px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-chat-list-scroll {
    height: calc(100vh - 220px) !important;
  }
}
.gx-chat-list-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-chat-sidenav-content {
  background-color: #ffffff;
}
.gx-chat-sidenav-title {
  padding: 10px 16px;
  font-size: 14px;
  color: #1890ff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
@media screen and (max-width: 1199px) {
  .gx-chat-sidenav-title {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .gx-chat-sidenav-title {
    font-size: 15px;
  }
}
.gx-chat-tabs-header {
  background-color: #fbfbfb !important;
}
.gx-chat-user {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-chat-user.gx-chat-user-center {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-chat-user.gx-chat-user-center .gx-chat-avatar {
  margin-left: auto;
}
.gx-chat-user-item {
  padding: 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  cursor: pointer;
  max-height: 96px;
}
.gx-chat-user-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-chat-user-item.active,
.gx-chat-user-item:hover {
  background-color: #e6faff;
}
.gx-chat-user-row {
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-tabs-half .ant-tabs-nav-list,
.gx-tabs-half .ant-tabs-nav:before {
  width: 100%;
}
.gx-chat-info {
  max-width: calc(100% - 80px);
  padding: 0 5px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-chat-info p {
  margin-bottom: 0;
}
.gx-chat-info .h4 {
  display: block;
  margin-bottom: 3px;
}
.gx-chat-info-des {
  color: #595959;
  font-size: 13px;
}
.gx-chat-contact-col {
  max-width: calc(100% - 50px);
  padding: 0 5px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-chat-contact-col p {
  margin-bottom: 0;
}
.gx-chat-contact-col .h4 {
  display: block;
  margin-bottom: 3px;
}
.gx-chat-date {
  padding: 0 5px;
  text-align: right;
}
.gx-fs-80 {
  font-size: 80px !important;
  line-height: 80px !important;
}
.gx-comment-box .gx-fs-80 {
  text-align: center;
  margin-bottom: 12px;
}
@media screen and (max-width: 575px) {
  .gx-fs-80 {
    font-size: 60px !important;
    line-height: 60px !important;
  }
}
.gx-comment-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: calc(100vh - 122px) !important;
}
.framed-layout .gx-comment-box {
  height: calc(100vh - 122px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-comment-box {
    height: calc(100vh - 122px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-comment-box {
    height: calc(100vh - 100px) !important;
  }
}
.gx-chat-box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  z-index: 2;
}
.gx-chat-main {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-chat-main-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px #e8e8e8;
  padding: 16px;
  background-color: #ffffff;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .gx-chat-main-header {
    padding: 8px 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-chat-main-header {
    padding: 8px 30px;
  }
}
.gx-chat-main-header-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-right: auto;
}
.gx-chat-contact-name {
  font-size: 20px;
  font-weight: 500;
}
@media screen and (max-width: 1199px) {
  .gx-chat-contact-name {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .gx-chat-contact-name {
    font-size: 15px;
  }
}
.gx-chat-main-footer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-top: solid 1px #e8e8e8;
  padding: 6px 16px;
  background-color: #ffffff;
  width: 100%;
}
.gx-chat-main-footer .gx-form-group {
  margin-bottom: 0;
}
.gx-chat-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 16px;
}
@media screen and (max-width: 991px) {
  .gx-chat-item {
    padding: 16px 30px;
  }
}
.gx-chat-item .gx-bubble-block {
  width: calc(100% - 40px);
}
.gx-chat-item .gx-bubble {
  padding: 12px 15px;
  background-color: #ffffff;
  -webkit-border-radius: 20px 20px 20px 0px;
  -moz-border-radius: 20px 20px 20px 0px;
  border-radius: 20px 20px 20px 0px;
  position: relative;
  margin-left: 16px;
  max-width: 600px;
  border: solid 1px #d9d9d9;
  display: inline-block;
}
.gx-chat-item.gx-flex-row-reverse .gx-bubble-block {
  text-align: right;
}
.gx-chat-item.gx-flex-row-reverse .gx-bubble {
  margin-right: 16px;
  margin-left: 0;
  background-color: #e6faff;
  -webkit-border-radius: 20px 20px 0 20px;
  -moz-border-radius: 20px 20px 0 20px;
  border-radius: 20px 20px 0 20px;
  text-align: left;
}
.gx-chat-item .ant-avatar {
  display: block;
}
.gx-chat-btn {
  font-size: 38px !important;
  margin-right: 20px;
}
textarea.gx-chat-textarea {
  height: 40px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.gx-chat-sent {
  min-width: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}
.gx-last-message-time {
  font-size: 11px;
  color: #8c8c8c;
}
@media screen and (max-width: 1199px) {
  .gx-chat-main-header .gx-size-60 {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px;
  }
  .gx-chat-main-header button {
    margin-bottom: 0;
  }
  .gx-chat-main-header-info .gx-status-pos {
    max-width: 50px;
  }
}
@media screen and (max-width: 575px) {
  .gx-module-default h1 {
    font-size: 17px;
  }
}
/*Contact Apps Styles*/
.gx-contact-item {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 8px 28px;
}
.gx-contact-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-dragndrop-item {
  padding: 24px 16px;
}
.gx-dragndrop-item .gx-draggable-icon {
  margin-left: -16px;
}
@media screen and (max-width: 575px) {
  .gx-dragndrop-item {
    padding-right: 5px;
  }
}
.gx-contact-list-info {
  padding-left: 10px;
}
.gx-contact-list-info .gx-email {
  word-break: break-all;
}
.gx-contact-list-info .gx-toolbar-separator {
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-contact-list-info .gx-toolbar-separator {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .gx-contact-list-info {
    padding-left: 0;
    max-width: calc(100% - 50px);
  }
  .gx-contact-list-info .gx-job-title {
    display: none;
  }
}
.gx-module-contact-content {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-module-contact-right {
  margin-left: 8px;
}
.gx-contact-name {
  font-size: 15px;
}
.gx-draggable-icon {
  cursor: all-scroll;
  color: #8c8c8c;
}
.gx-draggable-icon:hover,
.gx-draggable-icon:focus,
.gx-draggable-icon:active {
  cursor: all-scroll;
  color: #262626;
}
.gx-module-side-nav {
  padding: 20px 0;
}
.gx-modal-box-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-modal-box-row .gx-modal-box-avatar {
  -webkit-order: 2;
  -ms-order: 2;
  order: 2;
  margin-left: 16px;
}
.gx-modal-box-row .gx-modal-box-avatar .ant-avatar {
  width: 120px;
  height: 120px;
}
@media screen and (max-width: 575px) {
  .gx-modal-box-row .gx-modal-box-avatar {
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    margin-left: 0;
    margin-bottom: 16px;
    text-align: center;
  }
}
.gx-modal-box-row .gx-modal-box-form-item {
  -webkit-order: 1;
  -ms-order: 1;
  order: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
@media screen and (max-width: 575px) {
  .gx-modal-box-row .gx-modal-box-form-item {
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
  }
}
@media screen and (max-width: 575px) {
  .gx-modal-box-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.gx-dragndrop-actions {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
/* Calendar Apps Style */
.gx-rbc-calendar {
  overflow: hidden;
  padding-bottom: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media screen and (max-width: 575px) {
  .gx-rbc-calendar {
    margin-bottom: 20px;
  }
}
.gx-rbc-calendar .rbc-calendar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 100%;
}
.rbc-event {
  background-color: #038fde;
}
.rbc-event.rbc-selected {
  background-color: #006eb8;
}
.rbc-slot-selection {
  background-color: #7ddcff;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #ffffff;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #52c6f7;
  border-color: #038fde;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
  color: #ffffff;
  background-color: #52c6f7;
  border-color: #038fde;
}
.rbc-month-row {
  min-height: 100px;
}
.rbc-event {
  min-height: 50px;
}
.rbc-btn-group button {
  cursor: pointer;
}
@media screen and (max-width: 867px) {
  .rbc-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }
}
@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }
  .rbc-toolbar button {
    padding: 5px 10px;
  }
}
/* Calendar Components Style */
.gx-com-calendar .ant-fullcalendar-header .ant-select {
  margin-left: 8px;
  margin-bottom: 8px;
}
.gx-com-calendar .ant-picker-calendar-header {
  flex-wrap: wrap;
}
.gx-com-calendar .ant-picker-calendar-header .ant-select {
  margin-bottom: 8px;
}
@media screen and (max-width: 349px) {
  .gx-com-calendar {
    margin: 0 -20px;
  }
}
.gx-com-calendar .ant-select .ant-select-arrow {
  top: 50%;
}
.gx-com-calendar-card {
  border: solid 1px #e8e8e8;
  max-width: 300px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media screen and (max-width: 349px) {
  .gx-com-calendar-card .gx-com-calendar {
    margin: 0;
  }
}
.gx-profileon {
  margin-bottom: 20px;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.gx-profileon-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.9)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}
.gx-profileon-content * {
  color: #ffffff;
}
.gx-agents-list .gx-profileon-content {
  padding: 10px;
}
.gx-profileon-thumb {
  position: relative;
}
.gx-profileon-thumb > img {
  width: 100%;
}
.gx-profileon-thumb-htctrcrop {
  overflow: hidden;
  padding-bottom: 80%;
  position: relative;
}
.gx-profileon-thumb-htctrcrop > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
}
.gx-profileon-title {
  margin-bottom: 5px;
}
.gx-agents-list .gx-profileon {
  margin-bottom: 0;
}
.gx-follower {
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.gx-follower-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
}
.gx-profile-banner .gx-follower-list {
  margin: 0 -20px;
}
.gx-follower-list li {
  color: #8c8c8c;
  font-size: 14px;
  padding: 0 5px;
  width: 33.33%;
}
.gx-profile-banner .gx-follower-list li {
  color: #ffffff;
  padding: 0 20px;
  width: auto;
}
.gx-follower-list li:not(:first-child) {
  border-left: solid 1px #d9d9d9;
}
.gx-profile-banner .gx-follower-list li:not(:first-child) {
  border-left-color: #ffffff;
}
.gx-follower-title {
  display: block;
  color: #262626;
  font-size: 14px;
}
.gx-profile-banner .gx-follower-title {
  color: #ffffff;
}
.gx-wall-scroll {
  height: calc(100vh - 154px) !important;
}
.framed-layout .gx-wall-scroll {
  height: calc(100vh - 152px - 2 * 20px) !important;
}
@media screen and (max-width: 991px) {
  .gx-wall-scroll {
    height: calc(100vh - 124px) !important;
  }
  .framed-layout .gx-wall-scroll {
    height: calc(100vh - 124px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-wall-scroll {
    height: calc(100vh - 132px) !important;
  }
}
.gx-wall-postlist {
  padding: 5px;
}
.gx-entry-sec {
  position: relative;
  margin-bottom: 20px;
}
.ant-card .gx-entry-sec,
.gx-card .gx-entry-sec {
  margin-bottom: 0;
}
.gx-entry-title {
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  position: relative;
}
.gx-entry-title:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 1;
  height: 3px;
  width: 42px;
  background-color: #038fde;
}
.ant-card .gx-entry-title:before,
.gx-card .gx-entry-title:before {
  display: none;
}
.ant-card .gx-entry-title,
.gx-card .gx-entry-title {
  border-bottom: 0 none;
  margin-bottom: 15px;
  padding-bottom: 0;
}
.gx-fnd-list {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px 20px;
}
.gx-fnd-list li {
  width: 33.33%;
  padding: 0 5px;
}
@media (max-width: 399px) {
  .gx-fnd-list li {
    width: 50%;
  }
}
.gx-user-fnd {
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.gx-user-fnd > img {
  width: 100%;
}
.gx-user-fnd-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.95)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
  color: #ffffff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-user-fnd-content h6 {
  color: #ffffff;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gx-user-fnd-content .ant-badge {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  margin: 0;
  width: 10px;
  height: 10px;
  display: block;
}
.gx-user-fnd-content .ant-badge-status-dot {
  width: 10px;
  height: 10px;
  display: block;
  top: 0;
  border: solid 1px #ffffff;
}
.gx-gallery-list {
  list-style: none;
  padding-left: 0;
  margin: 0 -0.5px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-gallery-list li {
  padding: 0 0.5px;
  margin-bottom: 1px;
  width: 33.33%;
}
@media (max-width: 399px) {
  .gx-gallery-list li {
    width: 50%;
  }
}
.gx-gallery-list li > img {
  width: 100%;
}
.gx-gallery-thumb {
  position: relative;
  cursor: pointer;
}
.gx-gallery-thumb:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.01);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.01)), to(rgba(0, 0, 0, 0.95)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.95));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.95));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.95));
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.95));
}
.gx-gallery-thumb-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 10px;
  color: #ffffff;
}
.gx-gallery-thumb-content h6 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 10px;
}
.gx-wall-user-info {
  margin-bottom: 6px;
}
.gx-wall-user-title {
  margin-bottom: 4px;
}
.gx-gallery-item {
  position: relative;
  margin-bottom: 15px;
}
.gx-gallery-item img {
  width: 100%;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-gallery-item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #ffffff;
}
.gx-gallery-grid {
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-gallery-grid .gx-gallery-item {
  padding: 0 5px;
}
.gx-gallery-grid .gx-gallery-item-content {
  left: 5px;
  right: 5px;
}
.gx-gallery-2 .gx-gallery-item,
.gx-gallery-4 .gx-gallery-item,
.gx-gallery-4-more .gx-gallery-item {
  width: 50%;
}
.gx-gallery-3 .gx-gallery-item {
  width: 33.33%;
}
.gx-wall-comment-box {
  padding: 0 30px;
}
@media screen and (max-width: 1199px) {
  .gx-wall-comment-box {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1100px) {
  .gx-wall-comment-box {
    padding: 0;
  }
}
@media screen and (max-width: 991px) {
  .gx-wall-comment-box {
    padding: 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .gx-wall-comment-box {
    padding-right: 0;
    padding-left: 15px;
  }
}
.gx-profile-banner {
  padding: 18px;
  padding-bottom: 46px;
  margin: -18px;
  margin-bottom: -30px;
  background-color: #013;
  color: #ffffff;
  position: relative;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner {
    padding: 20px 15px;
    padding-bottom: 41px;
    margin: -20px -15px;
    margin-bottom: -30px;
  }
}
.gx-profile-banner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-profile-banner > div {
  position: relative;
  z-index: 2;
}
.gx-profile-banner-top {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .gx-profile-banner-top {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 25px;
  }
}
.gx-profile-banner-top-left {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media (max-width: 575px) {
  .gx-profile-banner-top-left {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 25px;
  }
}
.gx-profile-banner-avatar {
  margin-right: 1.5rem;
}
@media (max-width: 575px) {
  .gx-profile-banner-avatar {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.gx-profile-banner-top-right {
  text-align: center;
  margin-left: auto;
}
@media (max-width: 575px) {
  .gx-profile-banner-top-right {
    margin-left: 0;
  }
}
.gx-profile-banner-bottom {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media (max-width: 575px) {
  .gx-profile-banner-bottom {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .gx-profile-banner-bottom .gx-tab-list {
    margin-bottom: 10px;
  }
}
.gx-profile-banner h1,
.gx-profile-banner h2,
.gx-profile-banner h3,
.gx-profile-banner h4,
.gx-profile-banner h5,
.gx-profile-banner h6,
.gx-profile-banner .h1,
.gx-profile-banner .h2,
.gx-profile-banner .h3,
.gx-profile-banner .h4,
.gx-profile-banner .h5,
.gx-profile-banner .h6 {
  color: #ffffff;
}
.gx-profile-banner .gx-link {
  color: #ffffff;
}
.gx-profile-banner .gx-link:hover,
.gx-profile-banner .gx-link:focus {
  color: #fa8c15;
}
.gx-profile-content {
  position: relative;
  z-index: 2;
}
.gx-profile-setting {
  margin-left: auto;
}
@media (max-width: 575px) {
  .gx-profile-setting {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .gx-profile-content-right {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -ms-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -webkit-align-items: center !important;
    -ms-align-items: center !important;
    align-items: center !important;
  }
  .gx-profile-content-right .gx-text-truncate {
    width: auto;
    margin-left: auto;
  }
}
.gx-pro-contact-list:not(:last-child) {
  margin-bottom: 1rem;
}
/*Callout Styles*/
/*Different size images set in Equal block height and width*/
.gx-thumb-equal,
.gx-grid-thumb-equal {
  position: relative;
  padding-bottom: 68%;
  height: 0;
  width: 100%;
  overflow: hidden;
}
.gx-thumb-cover,
.gx-grid-thumb-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
.gx-thumb-cover-img,
.gx-grid-thumb-cover img {
  height: auto;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
}
.gx-product-image .gx-grid-thumb-equal {
  padding-bottom: 82%;
}
/*Product listing page styles*/
.gx-product-row {
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  text-align: right;
}
.gx-product-row.even {
  text-align: left;
}
.gx-product-col {
  order: 1;
  width: 50%;
}
.odd .gx-product-thumb {
  order: 2;
}
.gx-product-content {
  padding: 20px 55px;
}
.gx-product-content p {
  margin-bottom: 10px;
}
.gx-product-row .gx-grid-thumb-equal {
  padding-bottom: 69%;
}
.gx-product-thumb img {
  width: 100%;
}
.gx-product-content h4 {
  margin-bottom: 25px;
}
@media screen and (max-width: 1230px) {
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 71%;
  }
}
@media screen and (max-width: 1199px) {
  .gx-product-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    text-align: center;
  }
  .gx-product-row.even {
    text-align: center;
  }
  .gx-product-content h4 {
    margin-bottom: 10px;
  }
  .gx-product-content {
    padding: 20px 35px;
  }
  .gx-product-col,
  .odd .gx-product-thumb {
    order: 1;
    width: 100%;
  }
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 40%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
  }
  .gx-product-row.even {
    text-align: left;
  }
  .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 120%;
  }
  .gx-product-col,
  .odd .gx-product-thumb {
    order: 1;
    width: 50%;
  }
  .odd .gx-product-thumb {
    order: 2;
  }
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 70%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    text-align: center;
  }
  .gx-product-row.even {
    text-align: center;
  }
  .gx-product-row:last-child .gx-product-col {
    padding-bottom: 0;
  }
  .gx-product-row:last-child .ant-btn {
    margin-bottom: 0;
  }
  .gx-product-content {
    padding: 20px 0;
  }
  .gx-product-col,
  .odd .gx-product-thumb {
    order: 1;
    width: 100%;
  }
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
  .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
}
/*E-commerce Styles*/
.gx-product-item {
  overflow: hidden;
  background: #fff;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  margin-bottom: 32px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 575px) {
  .gx-product-item {
    margin-bottom: 20px;
  }
}
.gx-product-body {
  padding: 24px;
}
.gx-product-body + .gx-product-footer {
  padding-top: 0;
}
.gx-product-body :last-child {
  margin-bottom: 0;
}
.gx-product-image > img {
  width: 100%;
}
.gx-product-footer {
  padding: 24px;
  padding-bottom: 15px;
}
.gx-product-horizontal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-product-horizontal .gx-product-image {
  width: 25%;
}
@media screen and (max-width: 1199px) {
  .gx-product-horizontal .gx-product-image {
    width: 33.33333%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-horizontal .gx-product-image {
    width: 25%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-image {
    width: 100%;
  }
}
.gx-product-horizontal .gx-product-body {
  width: 53%;
}
@media screen and (min-width: 1400px) {
  .gx-product-horizontal .gx-product-body {
    width: 55%;
  }
}
@media screen and (max-width: 1199px) {
  .gx-product-horizontal .gx-product-body {
    width: 41.66667%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-horizontal .gx-product-body {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-body {
    width: 100%;
  }
}
.gx-product-horizontal .gx-product-body + .gx-product-footer {
  padding-top: 24px;
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-body + .gx-product-footer {
    padding-top: 0;
  }
}
.gx-product-horizontal .gx-product-footer {
  width: 22%;
  text-align: center;
}
@media screen and (min-width: 1400px) {
  .gx-product-horizontal .gx-product-footer {
    width: 20%;
  }
}
.gx-product-horizontal .gx-product-footer .ant-btn:not(:last-child) {
  margin-left: 8px;
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-footer .ant-btn:not(:last-child) {
    margin-left: 0;
  }
}
.gx-product-horizontal .gx-product-footer .ant-btn:last-child {
  margin-left: 8px;
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-footer {
    width: 100%;
    text-align: left;
  }
}
.gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
  padding-bottom: 82%;
}
@media screen and (min-width: 1400px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 55%;
  }
}
@media screen and (max-width: 1199px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 80%;
  }
}
@media screen and (max-width: 1080px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 90%;
  }
}
@media screen and (max-width: 1000px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 110%;
  }
  .horizontal-layout .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 80%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 88%;
  }
  .horizontal-layout .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 128%;
  }
}
@media screen and (max-width: 849px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 110%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
  .horizontal-layout .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
}
.gx-product-vertical {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: calc(100% - 32px);
}
@media screen and (max-width: 575px) {
  .gx-product-vertical {
    height: calc(100% - 20px);
  }
}
.gx-product-vertical .gx-product-body {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-product-title {
  font-size: 18px;
}
/*Pricing Tables Styles*/
.gx-price-tables {
  position: relative;
}
.gx-package {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  height: calc(100% - 15px);
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.gx-package:hover,
.gx-package:focus,
.gx-package:active {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.55);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.55);
}
.gx-pt-dark .gx-package:hover,
.gx-pt-dark .gx-package:focus,
.gx-pt-dark .gx-package:active {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.gx-pt-classic .gx-package {
  border: 0 none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.gx-package.gx-highlight {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  z-index: 2;
}
.gx-package-header {
  padding: 30px;
  text-align: center;
}
.gx-package-header > .gx-price {
  display: inline-block;
  font-size: 36px;
  font-weight: 500;
  margin-left: 0;
  margin-bottom: 12px;
  color: #ffffff;
}
.gx-package-header > .gx-price > i {
  display: inline-block;
  margin-right: 6px;
  font-weight: bold;
}
.gx-package-header h5 {
  color: inherit;
}
.gx-package-header.gx-text-black > .gx-price {
  color: #595959 !important;
}
@media only screen and (max-width: 991px) {
  .gx-package-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.gx-package-body {
  padding: 50px 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .gx-package-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.gx-package-items {
  list-style: none;
  font-size: 14px;
  margin: 0 0 20px;
  padding-left: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-package-items li {
  margin-bottom: 16px;
  line-height: inherit;
}
.gx-package-items li::before {
  display: table;
  content: '';
}
.gx-package-items li::after {
  display: table;
  clear: both;
  content: '';
}
.gx-package-items li::before {
  display: table;
  content: '';
}
.gx-package-items li::after {
  display: table;
  clear: both;
  content: '';
}
.gx-package-items li i {
  float: left;
  min-width: 20px;
  margin-right: 16px;
  line-height: inherit;
}
.gx-package-items li span {
  display: block;
  overflow: hidden;
}
.gx-package-footer {
  padding-top: 30px;
}
.gx-package-footer .ant-btn {
  text-transform: capitalize;
  border: 0 none;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .gx-package:hover,
  .gx-package:focus,
  .gx-package:active,
  .gx-package.gx-highlight {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.gx-text-black .gx-text-white {
  color: #595959 !important;
}
.gx-pt-circle .gx-package-header {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 224px;
  width: 224px;
  margin: 40px auto 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-pt-circle .gx-package-header .gx-price {
  margin-top: -5px;
}
@media only screen and (max-width: 991px) {
  .gx-pt-circle .gx-package-header .gx-price {
    font-size: 26px;
    margin-bottom: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .gx-pt-circle .gx-package-header .gx-letter-spacing-base {
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 991px) {
  .gx-pt-circle .gx-package-header {
    height: 150px;
    width: 150px;
  }
}
/*Login Styles*/
.gx-login-container {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-login-content {
  max-width: 420px;
  width: 94%;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
}
.gx-login-content .ant-input {
  background-color: #f5f5f5;
  height: auto;
  padding: 6px 12px;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #038fde;
}
.gx-login-content .ant-input-affix-wrapper {
  background-color: #f5f5f5;
  max-height: none;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-login-wrap {
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 10px;
    -webkit-justify-content: flex-center;
    -ms-justify-content: flex-center;
    justify-content: flex-center;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #038fde;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content .gx-app-logo {
  height: 100%;
  display: flex;
  align-items: center;
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: #294fc7 !important;
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #038fde;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #038fde;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #038fde;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
.gx-signin-form .ant-form-item-control-input {
  min-height: 40px;
}
.forget-password-link {
  color: #999;
  display: block;
  text-align: center;
  padding-top: 5px;
  font-weight: 300;
}
.ant-row-rtl .forget-password-link {
  float: left !important;
}
.or-line {
  display: inline-block;
  width: 40%;
  height: 1px;
  background-color: #ccc;
  vertical-align: middle;
}
@media screen and (max-width: 575px) {
  .or-line {
    width: 30%;
  }
}
/*Dashboard Styles*/
.ant-list-item-meta {
  flex-wrap: wrap;
}
.gx-task-list-item {
  position: relative;
}
.gx-task-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.gx-card-ticketlist .gx-task-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.gx-card-tabs .gx-task-list-item:last-child {
  margin-bottom: 15px;
}
.gx-card-ticketlist .gx-task-list-item .gx-hover {
  display: none;
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-list-item .gx-hover {
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-list-item .gx-nonhover {
    display: none;
  }
}
.gx-card-ticketlist .gx-task-list-item:hover .gx-hover {
  display: inline-block;
}
.gx-card-ticketlist .gx-task-list-item:hover .gx-nonhover {
  display: none;
}
.gx-card-ticketlist .gx-task-list-item:hover .gx-task-item-title {
  color: #038fde;
}
.gx-task-list-item:hover {
  cursor: pointer;
}
.gx-task-list-item:hover .gx-text-hover {
  color: #038fde;
}
.gx-task-item-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  max-width: calc(100% - 40px);
}
.gx-task-item-content-left {
  width: calc(100% - 250px);
}
@media screen and (max-width: 575px) {
  .gx-task-item-content-left {
    width: 100%;
    margin-bottom: 10px;
    padding-right: 60px;
  }
}
.gx-card-ticketlist .gx-task-item-content-left {
  width: calc(100% - 100px);
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-item-content-left {
    width: 100%;
    padding-right: 0;
  }
}
.gx-task-item-content-right {
  width: 250px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding-left: 10px;
}
@media screen and (max-width: 575px) {
  .gx-task-item-content-right {
    width: 100%;
    padding-left: 0;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-card-ticketlist .gx-task-item-content-right {
  padding-top: 8px;
  width: 100px;
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-item-content-right {
    width: 100%;
    padding-top: 0;
  }
}
.gx-card-ticketlist .gx-task-item-content {
  max-width: calc(100% - 52px);
}
.gx-task-item-content .gx-task-date {
  min-width: 55px;
  text-align: right;
}
.gx-task-item-content .ant-tag {
  min-width: 60px;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .gx-task-item-content {
    flex-direction: column;
    position: relative;
  }
  .gx-task-item-content .gx-task-date {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .gx-card-ticketlist .gx-task-item-content {
    padding-right: 0;
  }
}
.gx-card-ticketlist .gx-link {
  color: #038fde;
}
.gx-card-testimonial {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 12px;
}
.gx-card-testimonial-img {
  margin-right: 10px;
  margin-bottom: 10px;
}
.gx-card-testimonial-content {
  background-color: #f5f5f5;
  padding: 15px;
  position: relative;
  margin-left: 10px;
  width: calc(100% - 70px);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.gx-card-testimonial-content:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 10px solid #f5f5f5;
}
.gx-card-testimonial-content > :last-child {
  margin-bottom: 0;
}
.gx-progress-task {
  position: relative;
}
.gx-progress-task :last-child {
  margin-bottom: 0;
}
.gx-progress-task-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 15px;
}
.gx-progress-task-list + .gx-progress-task-list {
  border-top: solid 1px #e8e8e8;
  padding-top: 15px;
}
.gx-progress-task-list .ant-progress {
  margin-bottom: 15px;
}
.gx-progress-task-list-content {
  margin-left: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 575px) {
  .gx-progress-task-list {
    margin-bottom: 0;
  }
}
.gx-act-task {
  position: relative;
}
.gx-act-task-cell {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 575px) {
  .gx-act-task-cell {
    margin-bottom: 10px;
  }
}
.gx-act-task-content {
  width: calc(100% - 72px);
}
.gx-act-task-content label.ant-checkbox-wrapper span {
  display: block;
  overflow: hidden;
}
.gx-act-task-content label.ant-checkbox-wrapper span.ant-checkbox {
  float: left;
  padding: 6px 5px 0 0;
}
.gx-act-task-btn-view {
  margin-left: 8px;
  width: 65px;
  text-align: right;
}
@media screen and (max-width: 575px) {
  .gx-act-task-btn-view {
    margin-left: 0;
    margin-top: 10px;
  }
}
.gx-line-indicator {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.gx-line-indicator li:not(:last-child) {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .gx-line-indicator li:not(:last-child) {
    margin-bottom: 5px;
  }
}
.gx-line-indicator li p {
  margin-bottom: 0;
}
.gx-line-indicator-half {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.gx-line-indicator-half li {
  width: 50%;
  padding: 0 12px;
}
.gx-line-indicator-col {
  width: 25%;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .gx-line-indicator-col {
    width: 100%;
    padding-right: 0;
  }
}
.gx-line-indi {
  height: 3px;
}
.gx-line-indi-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-wel-ema {
  position: relative;
}
.gx-overview-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .gx-overview-row {
    flex-direction: column;
  }
}
.gx-overview-description {
  width: 32%;
  color: #8c8c8c;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: -24px;
  margin-left: 0;
  border-left: solid 1px #e8e8e8;
  order: 2;
}
@media screen and (max-width: 767px) {
  .gx-overview-description {
    order: 1;
    width: 100%;
    border-left: 0 none !important;
    border-top: solid 1px #e8e8e8;
    border-bottom: solid 1px #e8e8e8;
    margin: 15px 0 0;
  }
}
.gx-map-col {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 50px 10px 0;
  order: 1;
}
@media screen and (max-width: 767px) {
  .gx-map-col {
    order: 2;
    padding: 15px 0 0;
  }
}
.gx-revenu {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-revenu-total {
  border-bottom: solid 1px #e8e8e8;
}
.gx-revenu-total h1 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media screen and (max-width: 767px) {
  .gx-revenu-total h1 {
    font-size: 22px;
  }
}
.gx-revenu-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.gx-revenu-col {
  width: 50%;
  padding: 0 15px;
  text-align: center;
}
.gx-revenu-col:not(:last-child) {
  border-right: solid 1px #e8e8e8;
}
.gx-revenu h3 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.gx-visit-col {
  order: 1;
}
@media screen and (max-width: 1199px) {
  .gx-visit-col {
    order: 2;
    margin-top: 20px;
  }
}
.gx-audi-col {
  order: 2;
}
@media screen and (max-width: 1199px) {
  .gx-audi-col {
    order: 1;
  }
}
@media screen and (max-width: 575px) {
  .gx-audi-col {
    margin-top: 20px;
  }
}
.gx-rechart {
  position: relative;
}
.gx-rechart-prod {
  position: absolute;
  left: 30px;
  top: 0;
  z-index: 1;
}
@media (max-width: 1366px) {
  .gx-rechart-prod {
    left: 20px;
  }
}
.gx-pink-purple-gradient {
  background-color: #564093;
  background: -webkit-gradient(linear, left top, right top, from(#564093), to(#d2449d));
  background: -webkit-linear-gradient(left, #564093, #d2449d);
  background: -moz-linear-gradient(left, #564093, #d2449d);
  background: -ms-linear-gradient(left, #564093, #d2449d);
  background: -o-linear-gradient(left, #564093, #d2449d);
}
.gx-pink-purple-gradient-reverse {
  background-color: #872776;
  background: -webkit-gradient(linear, left top, right top, from(#872776), to(#352883));
  background: -webkit-linear-gradient(left, #872776, #352883);
  background: -moz-linear-gradient(left, #872776, #352883);
  background: -ms-linear-gradient(left, #872776, #352883);
  background: -o-linear-gradient(left, #872776, #352883);
}
.gx-blue-cyan-gradient {
  background: -moz-linear-gradient(125deg, #45d1e6 0%, #4363c6 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #45d1e6), color-stop(100%, #4363c6));
  background: -webkit-linear-gradient(125deg, #45d1e6 0%, #4363c6 100%);
  background: -o-linear-gradient(125deg, #45d1e6 0%, #4363c6 100%);
  background: -ms-linear-gradient(125deg, #45d1e6 0%, #4363c6 100%);
  background: linear-gradient(325deg, #45d1e6 0%, #4363c6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@hstartColor', endColorstr='@hendColor', GradientType=0);
}
.gx-app-thumb {
  display: inline-block;
}
.gx-card-cover-black .ant-card-head {
  background-color: #000000;
  color: #ffffff;
  padding-top: 20px;
}
.gx-card-cover-black .ant-card-head h1 {
  color: #ffffff;
}
.gx-card-cover-black .ant-card-head-title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-card-cover-black .ant-card-body {
  padding: 1rem;
}
.gx-dot-arrow-hover {
  position: relative;
  overflow: hidden;
}
.gx-dot-arrow-hover:hover .gx-hover-arrow {
  left: -15px;
}
.gx-dot-arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  padding-right: 10px;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-dot-arrow .gx-dot {
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-hover-arrow {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
  -webkit-border-radius: 50% 0 0 50%;
  -moz-border-radius: 50% 0 0 50%;
  border-radius: 50% 0 0 50%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 107px;
  height: 107px;
  position: absolute;
  left: 15px;
  padding: 10px;
  font-size: 20px;
}
.gx-hover-arrow .icon {
  display: flex;
}
.gx-actchart {
  margin-bottom: -4px;
}
.gx-user-wid {
  width: 80px;
  position: relative;
}
.gx-user-wid img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.gx-user-wid-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-user-wid-body {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.boxed-layout .gx-build-box-lay,
.framed-layout .gx-build-box-lay {
  padding: 0 10px !important;
}
.boxed-layout .gx-build-box-lay img,
.framed-layout .gx-build-box-lay img {
  width: 50px;
}
@media (max-width: 1366px) {
  .gx-build-box-lay {
    padding: 0 10px !important;
  }
  .gx-build-box-lay img {
    width: 50px;
  }
}
.gx-build-box-lay-content {
  max-width: calc(100% - 70px);
}
.gx-chart-title {
  font-size: 14px;
}
@media (max-width: 1366px) {
  .gx-chart-title {
    font-size: 12px;
  }
}
.gx-widget-bg {
  background-color: #4c429a;
  background: -webkit-gradient(linear, left top, right top, from(#4c429a), to(#9956ce));
  background: -webkit-linear-gradient(left, #4c429a, #9956ce);
  background: -moz-linear-gradient(left, #4c429a, #9956ce);
  background: -ms-linear-gradient(left, #4c429a, #9956ce);
  background: -o-linear-gradient(left, #4c429a, #9956ce);
  color: #ffffff;
  position: relative;
}
.gx-widget-bg h1 {
  color: #ffffff;
}
.gx-widget-badge {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: #ff6bb3;
  color: #ffffff;
  padding: 5px 12px;
  -webkit-border-radius: 0 10px 0 10px;
  -moz-border-radius: 0 10px 0 10px;
  border-radius: 0 10px 0 10px;
}
.gx-fnd-content {
  padding-right: 40px;
}
@media (max-width: 1366px) {
  .gx-fnd-content {
    padding-right: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-fnd-content {
    margin-top: 20px;
  }
}
.gx-fnd-gallery-list {
  list-style: none;
  padding-left: 0;
  margin: 0 -6px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-fnd-gallery-list li {
  padding: 0 6px;
}
.gx-fnd-gallery-list li > img {
  width: 65px;
}
@media (max-width: 1366px) {
  .gx-fnd-gallery-list li > img {
    width: 50px;
  }
}
.gx-fnd-title {
  font-size: 24px;
  margin-bottom: 15px;
}
@media (max-width: 1366px) {
  .gx-fnd-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.gx-chart-up {
  color: #52c41a;
}
.gx-bg-geekblue .gx-chart-up {
  color: #ffffff;
}
.gx-chart-down {
  color: #f5222d;
}
.gx-chart-down .icon {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}
.gx-barchart {
  margin-top: -15px;
}
@media screen and (max-width: 1199px) {
  .gx-barchart {
    margin-top: 0;
  }
}
.gx-customers {
  margin-top: -7px;
  margin-bottom: -7px;
}
@media (max-width: 1366px) {
  .gx-customers {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .gx-customers .gx-size-60 {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px !important;
  }
}
@media (max-width: 575px) {
  .gx-customers {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.gx-fillchart {
  position: relative;
  color: #ffffff;
  min-height: 122px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}
.gx-fillchart-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 15px 20px;
}
.gx-overlay-fillchart .gx-fillchart-content {
  background-color: rgba(255, 255, 255, 0.35);
}
.gx-fillchart .ant-card-head-title,
.gx-fillchart h2,
.gx-fillchart .gx-chart-up,
.gx-fillchart .gx-chart-down {
  color: #ffffff;
}
.gx-fillchart .gx-chart-up,
.gx-fillchart .gx-chart-down {
  margin-right: 6px;
}
.gx-fillchart-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.gx-fillchart-btn .icon:before {
  line-height: 30px;
}
.gx-fillchart-btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #038fde;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  text-align: center;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 16px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.gx-fillchart-btn-close .icon {
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 38px;
}
.gx-fillchart:hover .gx-fillchart-btn {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.gx-fillchart-nocontent .gx-fillchart-content,
.gx-fillchart-nocontent .gx-fillchart-btn,
.gx-fillchart-nocontent:hover .gx-fillchart-btn {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
}
.gx-fillchart-nocontent .gx-fillchart-btn-close {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.gx-fillchart-nocontent .ant-card-head-title {
  position: absolute;
  left: 20px;
  top: 15px;
  z-index: 1;
}
.gx-agents-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px;
  list-style: none;
  padding-left: 0;
}
.gx-agents-list li {
  width: 20%;
  padding: 0 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1199px) {
  .gx-agents-list li {
    width: 25%;
  }
}
@media screen and (max-width: 991px) {
  .gx-agents-list li {
    width: 20%;
  }
}
@media screen and (max-width: 767px) {
  .gx-agents-list li {
    width: 33.33%;
  }
}
@media screen and (max-width: 575px) {
  .gx-agents-list li {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px) {
  .gx-agents-list {
    margin: 0 -10px;
  }
}
.gx-featured-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.gx-card-profile .gx-featured-item:not(:last-child) {
  padding-bottom: 8px;
  margin-bottom: 25px;
}
@media (max-width: 575px) {
  .gx-featured-item:not(:last-child) {
    margin-bottom: 27px;
  }
}
.gx-featured-item:last-child .gx-featured-thumb {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .gx-featured-item:last-child .gx-featured-thumb {
    margin-bottom: 15px;
  }
}
@media (max-width: 575px) {
  .gx-featured-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: normal;
    -ms-align-items: normal;
    align-items: normal;
  }
}
.gx-featured-thumb {
  position: relative;
  margin-right: 25px;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .gx-featured-thumb {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .gx-featured-thumb > img {
    width: 100% !important;
    height: auto !important;
  }
}
.gx-featured-thumb .ant-tag {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  margin: 0;
}
.gx-card-profile .gx-featured-thumb {
  max-width: 130px;
}
@media (max-width: 575px) {
  .gx-card-profile .gx-featured-thumb {
    max-width: none;
  }
}
.gx-featured-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1366px) {
  .gx-featured-content {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .gx-featured-content {
    font-size: 14px;
  }
  .gx-card-profile .gx-featured-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .gx-featured-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.gx-featured-content-right {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-left: auto;
  text-align: right;
}
.gx-card-profile .gx-featured-content-right {
  font-size: 14px;
}
@media (max-width: 1199px) {
  .gx-card-profile .gx-featured-content-right {
    text-align: left;
    margin-left: 0;
  }
  .gx-card-profile .gx-featured-content-right h2 {
    font-size: 16px;
  }
  .gx-card-profile .gx-featured-content-right h2 .gx-fs-lg {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .gx-featured-content-right {
    margin-left: 0;
    text-align: left;
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .gx-card-profile .gx-featured-content-right h2 {
    font-size: 14px;
  }
}
.gx-news-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.gx-news-item:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .gx-news-item:not(:last-child) {
    margin-bottom: 26px;
  }
}
@media (max-width: 575px) {
  .gx-news-item:not(:last-child) {
    margin-bottom: 22px;
  }
}
@media (max-width: 575px) {
  .gx-news-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
.gx-news-thumb {
  position: relative;
  margin-right: 25px;
}
@media (max-width: 1366px) {
  .gx-news-thumb {
    margin-right: 15px;
  }
}
@media (max-width: 575px) {
  .gx-news-thumb {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .gx-news-thumb > img {
    width: 100% !important;
    height: auto !important;
  }
}
.gx-news-content {
  color: #585858;
  width: calc(100% - 200px);
}
@media (max-width: 1366px) {
  .gx-news-content {
    width: calc(100% - 190px);
  }
}
@media (max-width: 575px) {
  .gx-news-content {
    width: 100%;
  }
}
.gx-news-tags-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
@media (max-width: 1366px) {
  .gx-news-tags-row {
    font-size: 12px;
  }
}
.gx-news-tags-left {
  width: calc(100% - 180px);
}
@media (max-width: 1366px) {
  .gx-news-tags-left {
    width: calc(100% - 130px);
  }
}
.gx-news-tags-right {
  width: 180px;
  padding-left: 8px;
  text-align: right;
}
@media (max-width: 1366px) {
  .gx-news-tags-right {
    width: 130px;
  }
}
.gx-currentplan-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.gx-currentplan-col {
  width: 50%;
  padding: 0 5px;
}
@media (max-width: 1366px) {
  .gx-currentplan-col {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .gx-currentplan-col {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .gx-currentplan-col {
    width: 100%;
  }
}
.gx-currentplan-right {
  border-left: solid 1px #e8e8e8;
}
@media (max-width: 1366px) {
  .gx-currentplan-right {
    border-left: 0 none !important;
    border-top: solid 1px #e8e8e8;
    padding-top: 10px;
  }
}
@media (max-width: 991px) {
  .gx-currentplan-right {
    border-left: solid 1px #e8e8e8 !important;
    border-top: 0 none;
    padding-top: 0;
  }
}
@media (max-width: 575px) {
  .gx-currentplan-right {
    border-left: 0 none !important;
    border-top: solid 1px #e8e8e8;
    padding-top: 22px;
  }
}
.gx-currentplan-right-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  height: 100%;
  text-align: right;
}
@media (max-width: 1366px) {
  .gx-currentplan-right-content {
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .gx-currentplan-right-content {
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    text-align: right;
  }
}
@media (max-width: 575px) {
  .gx-currentplan-right-content {
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}
.gx-oth-plans-down {
  display: none;
}
@media (max-width: 1366px) {
  .gx-oth-plans-down {
    display: block;
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .gx-oth-plans-down {
    display: none;
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .gx-oth-plans-down {
    display: block;
    margin-top: 20px;
  }
}
@media (max-width: 1366px) {
  .gx-oth-plans-up {
    display: none;
  }
}
@media (max-width: 991px) {
  .gx-oth-plans-up {
    display: block;
  }
}
@media (max-width: 575px) {
  .gx-oth-plans-up {
    display: none;
  }
}
.gx-revenue-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  color: #000000;
}
@media (max-width: 1366px) {
  .gx-revenue-title {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .gx-revenue-title {
    padding-top: 15px;
  }
}
.gx-dealclose-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.gx-dealclose-header-right {
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1366px) {
  .gx-dealclose-header-right {
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .gx-dealclose-header-right {
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .gx-dealclose-header-right {
    margin-left: 0;
  }
}
@media (max-width: 1366px) {
  .gx-dealclose-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (max-width: 991px) {
  .gx-dealclose-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  .gx-dealclose-header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1366px) {
  .gx-order-history .ant-table {
    font-size: 12px;
  }
}
.gx-icon-img {
  width: 60px;
  height: 60px;
}
@media screen and (max-width: 1366px) {
  .gx-icon-img {
    width: 40px;
    height: 40px;
  }
}
.gx-smart-img {
  max-height: 90px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 575px) {
  .gx-smart-img {
    max-height: 180px;
  }
}
.gx-ayurveda-thumb {
  max-height: 150px;
}
.gx-ayurveda-thumb > img {
  object-fit: cover;
}
.gx-dash-search {
  position: relative;
}
@media screen and (max-width: 575px) {
  .gx-dash-search {
    padding-right: 30px;
  }
  .gx-dash-search .gx-search-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
.gx-bg-dark-primary {
  background-color: #038fde;
  position: relative;
  overflow: hidden;
}
.gx-bg-dark-primary > * {
  position: relative;
  z-index: 1;
}
.gx-bg-dark-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.72);
}
@media screen and (max-width: 1366px) {
  .recharts-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 575px) {
  .gx-list-item .ant-list-item-content {
    flex-direction: column;
  }
}
/*Algolia Styles*/
.gx-algolia-sidebar {
  -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 32px;
  width: 280px !important;
}
@media screen and (max-width: 767px) {
  .gx-algolia-sidebar {
    width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-algolia-sidebar {
    margin-bottom: 20px;
  }
}
.gx-algolia-sidebar [class^="ais-"] {
  font-size: 14px;
}
.gx-algolia-sidebar-content {
  padding: 24px;
}
.gx-algolia-sidebar .ais-ClearRefinements {
  margin-bottom: 20px;
}
.gx-algolia-sidebar .ais-HierarchicalMenu {
  margin-bottom: 20px;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list li {
  margin-bottom: 5px;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list li a {
  color: #444;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list li a:hover,
.gx-algolia-sidebar .ais-HierarchicalMenu-list li a:focus {
  color: #038fde;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-count {
  padding: 0 5px;
  font-size: 12px;
  color: #8c8c8c;
  background-color: transparent;
  border-radius: 0;
}
.gx-algolia-sidebar .ais-Panel {
  margin-bottom: 20px;
}
.gx-algolia-sidebar .ais-Panel-header {
  margin-bottom: 8px;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 0 none;
}
.gx-algolia-sidebar .ais-RefinementList-list li {
  margin-bottom: 5px;
}
.gx-algolia-sidebar .ais-RefinementList-checkbox {
  margin-right: 12px;
}
.gx-algolia-sidebar .ais-RefinementList-labelText {
  font-size: 14px;
}
.gx-algolia-sidebar .ais-RefinementList-count {
  padding: 0 5px;
  font-size: 14px;
  color: #8c8c8c;
  background-color: transparent;
  border-radius: 0;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Breadcrumb-item--selected,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item--selected,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Menu-item--selected {
  font-weight: normal;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Breadcrumb-item--selected > a,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item--selected > a,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Menu-item--selected > a {
  color: #038fde;
}
.gx-algolia-sidebar .ais-RatingMenu-item--selected {
  font-weight: 600;
}
.gx-algolia-layout-has-sider {
  flex-wrap: nowrap;
}
@media screen and (max-width: 767px) {
  .gx-algolia-layout-has-sider {
    flex-wrap: wrap;
  }
}
.gx-algolia-category-title {
  font-size: 14px;
  margin-bottom: 10px;
  color: #8c8c8c;
}
.gx-algolia-main {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 30px;
  margin-bottom: 32px;
}
.gx-algolia-main [class^="ais-"] {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .gx-algolia-main {
    padding-left: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-algolia-main {
    margin-bottom: 20px;
  }
}
.gx-algolia-header {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.gx-algolia-sort-by {
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-algolia-sort-by label {
  margin-right: 15px;
}
@media screen and (max-width: 400px) {
  .gx-algolia-sort-by {
    margin-left: 0;
  }
}
.gx-algolia-footer {
  padding: 10px;
}
.gx-algolia-footer .ais-Pagination-list {
  flex-wrap: wrap;
}
.gx-algolia-footer .ais-Pagination-list li {
  margin-bottom: 5px;
}
.gx-algolia-content .ais-Stats {
  margin-bottom: 10px;
}
.gx-product-name [class^="ais-"] {
  font-size: 16px;
}
.gx-algolia-refinementList {
  width: 100%;
}
.gx-algolia-refinementList li.ais-RefinementList-item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ais-RangeInput-submit,
.ais-ClearRefinements-button,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  background-color: #038fde;
}
.ais-RangeInput-submit:focus,
.ais-ClearRefinements-button:focus,
.ais-CurrentRefinements-reset:focus,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-reset:focus,
.ais-HierarchicalMenu-showMore:focus,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteResults-loadMore:focus,
.ais-Menu-showMore:focus,
.ais-RefinementList-showMore:focus,
.ais-RangeInput-submit:hover,
.ais-ClearRefinements-button:hover,
.ais-CurrentRefinements-reset:hover,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:hover,
.ais-HierarchicalMenu-showMore:hover,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteResults-loadMore:hover,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:hover {
  background-color: #038fde;
}
.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
  color: #038fde;
}
.ais-Breadcrumb-link:focus,
.ais-HierarchicalMenu-link:focus,
.ais-Menu-link:focus,
.ais-Pagination-link:focus,
.ais-RatingMenu-link:focus,
.ais-Breadcrumb-link:hover,
.ais-HierarchicalMenu-link:hover,
.ais-Menu-link:hover,
.ais-Pagination-link:hover,
.ais-RatingMenu-link:hover {
  color: #038fde;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  background-color: #038fde;
  border-color: #038fde;
}
/*Error Styles*/
.gx-page-error-container {
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.gx-page-error-content {
  margin: 0 auto;
  width: 380px;
  max-width: 94%;
}
.gx-page-error-content h2 {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .gx-page-error-content h2 {
    margin-bottom: 20px;
  }
}
.gx-error-code {
  color: #595959;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8);
}
@media screen and (max-width: 991px) {
  .gx-error-code {
    font-size: 130px;
  }
}
@media screen and (max-width: 767px) {
  .gx-error-code {
    font-size: 100px;
  }
}
@media screen and (max-width: 575px) {
  .gx-error-code {
    font-size: 80px;
  }
}
/*Editor Styles*/
.rdw-editor-toolbar .rdw-inline-wrapper {
  flex-wrap: wrap;
}
@media screen and (max-width: 399px) {
  .rdw-editor-toolbar .rdw-inline-wrapper .rdw-option-wrapper {
    margin-bottom: 8px;
  }
}
/*Testimonial Styles*/
.gx-testimonial {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .gx-testimonial {
    margin-bottom: 20px;
  }
}
.gx-classic-testimonial .ant-avatar {
  margin: 0 auto;
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
}
.gx-classic-testimonial .ant-avatar img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 575px) {
  .gx-classic-testimonial .ant-avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
}
.gx-testimonial-bg {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media screen and (max-width: 575px) {
  .gx-testimonial-bg {
    padding: 16px;
  }
}
.gx-testimonial-bg .ant-avatar {
  display: inline-block;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border: 4px solid #038fde;
}
.gx-testimonial-bg .ant-avatar img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 450px) {
  .gx-testimonial-bg .ant-avatar {
    width: 50px;
    height: 50px;
  }
}
.gx-testimonial-bg .gx-description {
  color: #d9d9d9;
  font-style: italic;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 15px;
}
.gx-testimonial-bg .gx-title {
  color: #038fde;
  margin-bottom: 2px;
}
.gx-testimonial-des {
  position: relative;
  padding-left: 30px;
}
.gx-testimonial-des:before {
  font-family: "gaxon";
  font-size: 20px;
  content: "\e066";
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 475px) {
  .gx-standard .gx-media {
    flex-direction: column;
  }
}
.agreement-content {
  padding: 40px 0px;
  text-align: center;
  width: 100%;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}
.dashboard-view {
  max-width: 400px;
  overflow: hidden;
  padding: 4px;
}
.dashboard-index-container {
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.dashboard-large .dashboard-index-container {
  padding: 15px;
}
.dashboard-index-container.no-border {
  border-bottom: none;
}
.button-container {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .button-container {
    justify-content: center;
  }
}
.ttp-name {
  min-width: 120px;
  display: inline-block;
}
.ttp-value {
  text-align: right;
  font-weight: bold;
  float: right;
  display: block;
}
.tt-color {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
}
.message-list {
  overflow-y: auto;
  height: 100%;
}
.message-list.with-selected-message {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.message-row {
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  height: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
}
.message-row:hover {
  background-color: #f3f3f3;
}
.dark-theme .message-row:hover {
  background-color: #2d353c;
}
.dark-theme .message-row {
  border-bottom: 1px solid #23293c !important;
}
.dark-theme .dashboard-index-container {
  border-bottom: 1px solid #23293c;
}
.dark-theme .selected-message {
  background-color: #2d353c !important;
}
.message-text {
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
  text-overflow: ellipsis;
}
.message-main {
  overflow-y: auto;
  height: calc(100% - 60px);
}
.selected-message {
  background-color: #dfdfdf;
}
.message-content {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.message-date {
  font-size: 12px;
  color: #aaa;
}
.message-details {
  padding: 10px 20px;
  width: 100%;
}
.message-inner-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}
.message-header {
  padding: 10px 8px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.colored-mesage-title {
  min-width: 98px;
}
.message-page {
  display: flex;
}
.web-dashboard-card {
  width: 100%;
  margin-bottom: 0px !important;
}
@media screen and (max-width: 767px) {
  .web-dashboard-card {
    width: 100%;
  }
}
.web-subpanel {
  flex-direction: column;
  padding-top: 56px;
  min-width: 380px;
}
@media screen and (max-width: 767px) {
  .web-subpanel {
    flex-direction: column;
    height: auto !important;
    padding-top: 0px;
    width: 100%;
  }
}
.web-messages {
  height: calc(100% - 318px);
}
@media screen and (max-width: 767px) {
  .web-messages {
    height: 400px;
    width: 100% !important;
  }
}
.bott-messages {
  height: 720px;
}
@media screen and (max-width: 767px) {
  .message-details {
    width: 100%;
  }
}
.message-list.with-selected-message {
  width: 0%;
  min-width: 0px;
}
@media screen and (max-width: 767px) {
  .message-list.with-selected-message {
    width: 0%;
    min-width: 0px;
  }
}
@media screen and (max-width: 767px) {
  .colored-mesage-title {
    display: none;
  }
}
.web-index-container {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 767px) {
  .web-index-container {
    height: auto !important;
    max-height: 100% !important;
    flex-direction: column;
  }
}
.web-chart-parent {
  width: calc(100% - 350px);
  min-width: calc(100% - 400px);
  height: "100%";
  padding-top: "15px";
  position: relative;
}
@media screen and (max-width: 767px) {
  .web-chart-parent {
    width: 100%;
    min-height: 500px;
  }
}
.multi-chart-switch {
  display: flex;
  float: right;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .multi-chart-switch {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .gauge-container {
    grid-template-columns: 1fr !important;
  }
}
.web-chart-container {
  padding-top: 5px;
}
.web-chart-container .ant-card {
  margin-bottom: 5px !important;
}
@media screen and (max-width: 767px) {
  .web-chart-container {
    height: 500px !important;
  }
}
.web-hide-index {
  position: absolute;
  bottom: 68px;
  right: 105px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 5px 20px;
  color: #6e6e6e;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.web-hide-index:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 767px) {
  .web-hide-index {
    display: none;
  }
}
.web-chart-parent .layout-splitter {
  background-color: #e4e4e4 !important;
  height: 3px !important;
}
.web-chart-parent .layout-splitter:hover {
  background-color: #aaa !important;
}
.chart-menu-buttons {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .chart-menu-buttons {
    width: 100%;
    overflow: scroll;
  }
}
.data-grid-container {
  height: 100%;
  overflow-y: scroll;
  display: block;
}
.data-grid-container th {
  font-size: 0.8em;
  padding: 3px 3px;
}
.data-grid-container tr:nth-child(even) {
  background: #f8f8f8;
}
.data-grid-container table {
  width: 100%;
}
.data-grid th:first-child {
  width: 155px;
}
@media screen and (max-width: 767px) {
  .data-grid table tr td:first-child {
    width: 80px !important;
  }
  .data-grid table tr td:first-child span {
    overflow: hidden;
  }
}
@media screen and (max-width: 767px) {
  .data-grid th:first-child {
    width: 80px !important;
  }
}
.data-grid th {
  position: sticky;
  top: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.value-viewer {
  padding: 4px 5px;
}
.dark-theme .data-grid-container tr:nth-child(even) {
  background: #23293a;
  color: #cdcdcd;
}
.dark-theme .data-grid-container tr:nth-child(odd) {
  background: #1a1f2d;
  color: #cdcdcd;
}
.dark-theme .data-grid-container th {
  background: #171b28;
  color: #cdcdcd;
  border: 1px solid #161a1d;
}
.dark-theme .data-grid-container td {
  border: 1px solid #31384a !important;
}
.desktop-notification-permission {
  font-size: 13px;
  float: right;
  cursor: pointer;
  font-weight: normal;
}
.dark-theme .desktop-notification-permission {
  color: #e0e0e0;
}
.hidden-chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 20px;
  filter: drop-shadow(3px 3px 4px #999);
  cursor: pointer;
}
.hidden-chat:hover {
  filter: drop-shadow(2px 2px 3px #999);
}
.visible-chat {
  z-index: 999;
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 350px;
  height: 400px;
  background-color: white;
  box-shadow: 0 0 13px #d2d2d2;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.chat-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: #3464eb;
  padding: 6px 5px 6px 15px;
  color: #fff;
  height: 40px;
  max-height: 40px;
  align-items: center;
}
.messages {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  flex: 1;
  height: auto;
  overflow-y: scroll;
  padding-bottom: 40px;
}
.empty-messages {
  justify-content: flex-start;
}
.chat-message-user {
  flex-grow: 0;
  padding: 5px 10px;
  background-color: #f3f3f3;
  max-width: 85%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.chat-message-system {
  flex-grow: 0;
  padding: 5px 10px;
  background-color: #f1f3ff;
  max-width: 85%;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  gap: 3px;
}
.chat-time {
  font-size: 12px;
  font-weight: normal;
  text-align: right;
  align-self: flex-end;
  color: #999;
}
.chat-message-system > .chat-time {
  text-align: left;
  align-self: flex-start;
}
.chat-input {
  height: 50px;
  max-height: 50px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-top: 1px solid #eee;
  padding-right: 10px;
}
.chat-input > textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 5px 15px;
  resize: none;
  outline: none;
  font-size: 16px;
  color: #666;
}
.chat-input > textarea::placeholder {
  color: #ccc;
}
.close-icon {
  cursor: pointer;
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gx-layout-footer-content > button {
  right: 30px !important;
  bottom: 10px !important;
}
.empty-message {
  border-radius: 10px;
  padding: 15px;
  background-color: #f1f3ff;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom-right-radius: 0px;
}
.send-button {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.chat-error {
  margin: 20px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-size: 13px;
  background-color: #ffdede;
}
@media screen and (max-width: 600px) {
  .visible-chat {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    width: auto;
    height: auto;
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 0 13px #c2c2c2;
    overflow: hidden;
  }
  .close-icon {
    height: 48px;
    display: flex;
    align-items: center;
  }
  .chat-header {
    height: 48px;
    max-height: 48px;
  }
}
.dark-theme .message-list-container {
  border-right: 1px solid #2f374f !important;
}
.dark-theme .message-list-item {
  border-bottom: 1px solid #2f374f !important;
}
.dark-theme .message-list-item:hover {
  background-color: #2f374f !important;
}
.dark-theme .message-list-item-content {
  color: #fff !important;
}
.dark-theme .message-text-line {
  color: #fff;
}
.dark-theme .message-content-header {
  color: #fff !important;
}
.dark-theme .selected-message-item {
  background-color: #333c56 !important;
}
.dark-theme .locked-message-item {
  color: #aaa !important;
}
.message-page {
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
  overflow: hidden;
}
@media screen and (max-width: 575px) {
  .message-page {
    max-height: auto;
    min-height: auto;
    overflow: scroll;
  }
}
.message-page .locked-message-item {
  color: #999;
}
.message-page .selected-message-item {
  background-color: #f3f3f3;
}
.message-page .hidden-on-desktop {
  padding: 10px 0px;
  display: none;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 575px) {
  .message-page .hidden-on-desktop {
    display: flex;
  }
}
.message-page .message-content-header {
  display: flex;
  gap: 10px;
  align-items: center;
}
.message-page .message-content-header img {
  width: 48px;
  height: 48px;
}
.message-page .message-content-header > div {
  font-weight: bold;
}
.message-page .message-content-header > div .message-date {
  font-weight: normal;
}
.message-page .message-list-container {
  width: 400px;
  min-width: 400px;
  overflow-y: hidden;
  border-right: 1px solid #d9d9d9;
  padding-right: 20px;
}
@media screen and (max-width: 575px) {
  .message-page .message-list-container {
    width: 100%;
    height: 100%;
    min-width: auto;
  }
  .message-page .message-list-container.mobile-list-hidden {
    display: none !important;
  }
}
.message-page .message-list-container .message-list-search {
  margin-bottom: 10px;
}
.message-page .message-list-container .message-list-item {
  padding: 15px 10px;
  border-bottom: 1px solid #d9d9d9;
  overflow-y: hidden;
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: #333;
  align-items: center;
}
.message-page .message-list-container .message-list-item:hover {
  background-color: #f3f3f3;
}
.message-page .message-list-container .message-list-item-content-container {
  display: flex;
  flex-direction: column;
  width: 90%;
}
@media screen and (max-width: 575px) {
  .message-page .message-list-container .message-list-item-content-container {
    overflow: hidden;
    width: auto;
  }
}
.message-page .message-list-container .message-list-item-content {
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
  text-overflow: ellipsis;
  font-size: 14px;
}
.message-page .message-symbol {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #888;
  text-decoration-thickness: 1px;
}
.message-page .message-symbol > img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 0px 5px;
}
.message-page .message-text-line {
  line-height: 27px;
}
.new-dashboard-box {
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.dark-theme .new-dashboard-box {
  background-color: #222736;
  box-shadow: none;
}
.new-dashboard-box .ant-tag {
  width: 56px !important;
  text-align: center;
}
.new-dashboard-box .data-grid th:first-child {
  border-left: none;
}
.new-dashboard-box .data-grid th:last-child {
  border-right: none;
}
.new-dashboard-widget-list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.dark-theme .new-dashboard-widget-list-item {
  color: #fff;
}
.new-dashboard-widget-list-body {
  padding: 20px 0px !important;
  padding-right: 20px !important;
  max-height: 342px;
  overflow: auto;
}
.dashboard-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.dashboard-widget-title {
  padding: 10px 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: grab;
}
.full-screen-dashboard {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
}
.dark-theme .full-screen-dashboard {
  background-color: #131722;
}
.dashboard-intro-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.stock-selection-list-item {
  cursor: pointer;
  padding: 10px 10px;
}
.stock-selection-list-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.dark-theme .stock-selection-list-item {
  color: #fff;
}
.dashboard-minimal-title {
  font-size: 14px;
}
.dashboard-minimal-order {
  font-size: 11px;
}
.line-chart-sentiment-container {
  position: absolute;
  right: 30px;
  top: 38px;
  display: flex;
  border-radius: 10px;
  color: #555;
  gap: 8px;
  font-size: 13px;
  font-weight: bold;
}
.dark-theme .line-chart-sentiment-container {
  color: #fff;
}
.dashboard-title {
  display: flex;
  gap: 0px;
  justify-content: center;
  align-items: center;
  margin-bottom: 9px;
}
.dashboard-title h3 {
  margin-right: 5px;
  padding-bottom: 0px;
  margin-bottom: 0px !important;
}
.dashboard-title span {
  font-size: 16px;
  font-weight: bold;
}
.dashboard-title .dashboard-title-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboard-widget-title-text {
  display: flex;
  align-items: center;
  gap: 12px;
}
.react-resizable-handle {
  background-image: url("../assets/images/resize-handle.png") !important;
  right: 2px !important;
  bottom: 2px !important;
}
.dashboard-intro {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 23px 30px;
  align-items: center;
  max-width: 600px;
}
.dashboard-intro-content {
  display: flex;
  gap: 20px;
  align-items: center;
}
.dark-theme .modal-line-title {
  color: #fff !important;
}
.chart-container-wrapper .ant-modal-content {
  box-shadow: none;
}
.price-type-select {
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 5px;
  box-shadow: inset 0 0 2px #b6b6b5;
  position: relative;
}
.dark-theme .price-type-select {
  box-shadow: inset 0 0 2px #464545;
  background-color: #1a1f2d;
}
.price-type {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .price-type {
    justify-content: center;
  }
}
.price-type-select > div {
  padding: 2px 10px;
  cursor: pointer;
  user-select: none;
}
.selected-price-type {
  background-color: #3052fe;
  color: #fff;
  border-radius: 10px;
}
.package-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.package-card > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  max-width: 250px;
}
.package-price-string {
  font-size: 1.5rem;
  text-align: center;
  color: #645e80;
}
.package-price-type-text {
  font-size: 1rem;
  text-align: center;
}
.package-buy-button {
  text-align: center;
  padding-top: 30px;
}
.package-buy-button > button:disabled {
  background-color: #999 !important;
}
.package-buy-button > button {
  background-color: #0067ff;
  color: #fff;
  padding: 7px 35px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 1rem;
}
.package-buy-button > button:hover {
  background-color: #0055d5;
}
.package-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  color: #645e80;
  min-height: 135px;
}
.most-popular {
  background: #48f495;
  padding: 5px 10px;
  font: 12px var(--zf-primary-regular);
  color: #000;
  border-radius: 16px;
  border-top-left-radius: 0;
  position: absolute;
  right: -10px;
  top: 6px;
}
.most-popular-button > button {
  background-color: #f0483e;
}
.most-popular-button > button:hover {
  background-color: #d73d35;
}
.price-card-container {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 12px;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .price-card-container {
    flex-direction: column;
    align-items: center;
  }
}
.price-card-container-initial {
  padding-top: 50px;
}
.package-price-string-small {
  padding-top: 10px;
  text-align: center;
  color: #888;
  font-size: 16px;
}
.payment-amount {
  background-color: #f2f1f1;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.customer-form {
  max-width: 1000px;
  width: 1000px;
  margin: auto;
  padding-top: 40px;
}
.payment-header {
  font-weight: bold;
  padding: 20px 0px;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.payment-page {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.payment-page > div {
  max-width: 1042px;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.5;
    transform: scale(0.975);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.fadein-element {
  opacity: 0;
  transform: scale(0.95);
  animation-name: fadeInAnimation;
  animation-timing-function: linear;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.fadein-element-delay-0 {
  animation-delay: 0s;
}
.fadein-element-delay-1 {
  animation-delay: 0.1s;
}
.fadein-element-delay-2 {
  animation-delay: 0.2s;
}
.fadein-element-delay-3 {
  animation-delay: 0.3s;
}
.savings {
  position: absolute;
  bottom: -20px;
  right: -130px;
  font-size: 12px;
  color: #0060ff;
  font-weight: bold;
}
.saving-arrow {
  width: 40px;
}
.balance-warning {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
}
.balance-warning > div {
  display: flex;
  gap: 5px;
  color: #888;
  font-size: 13px;
}
.upgrade-details {
  font-size: 16px;
  padding: 10px;
  line-height: 24px;
}
.coupon-code-link {
  color: #888;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}
.cupon-modal-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.cupon-modal-container > button {
  margin-bottom: 0px;
}
.cupon-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.coupon-valid {
  display: flex;
  gap: 5px;
  align-items: center;
}
.package-coupon {
  display: flex;
  color: #888;
  justify-content: flex-start;
  font-size: 13px;
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.remove-coupon {
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
}
.desktop-coupon-code {
  width: 190px;
  display: flex;
  gap: 3px;
}
@media screen and (max-width: 767px) {
  .desktop-coupon-code {
    display: none;
  }
}
.payment-down-arrow {
  position: absolute;
  display: flex;
  top: 25px;
  left: 33px;
  white-space: pre;
  gap: 6px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .payment-down-arrow {
    display: none;
  }
}
.dark-theme .payment-down-arrow {
  color: #fff;
}
.payment-white-arrow {
  display: none;
}
.dark-theme .payment-arrow {
  display: none;
}
.dark-theme .payment-white-arrow {
  display: block;
}
.payment-down-arrow > img {
  height: 16px;
}
.mobile-coupon-code {
  display: none;
  gap: 3px;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .mobile-coupon-code {
    display: flex;
  }
}
.account-discord-steps .ant-steps-item-content {
  padding-bottom: 20px;
}
.account-discord-steps.aggrement-not-accepted .ant-steps-item-title {
  color: #aaa !important;
  cursor: default !important;
}
.account-discord-steps.aggrement-not-accepted .ant-steps-item-description a {
  color: #aaa !important;
  cursor: default !important;
}
.rtl {
  direction: rtl;
  text-align: right;
  unicode-bidi: bidi-override;
  /*Status style*/
  /* Customizer Style */
  /* & .ant-popover-placement-bottomRight {
    & > .ant-popover-content {
      & > .ant-popover-arrow {
        right: auto;
        left: 10px;
      }
    }
  }*/
}
.rtl ol,
.rtl ul,
.rtl dl {
  padding-left: 0;
  padding-right: 30px;
}
.rtl .gx-mr-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}
.rtl .gx-ml-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}
.rtl .gx-mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}
.rtl .gx-ml-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}
.rtl .gx-mr-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}
.rtl .gx-ml-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}
.rtl .gx-mr-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}
.rtl .gx-ml-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}
.rtl .gx-mr-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}
.rtl .gx-ml-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}
.rtl .gx-pr-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}
.rtl .gx-pl-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}
.rtl .gx-pr-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}
.rtl .gx-pl-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}
.rtl .gx-pr-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}
.rtl .gx-pl-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}
.rtl .gx-pr-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}
.rtl .gx-pl-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}
.rtl .gx-pr-5 {
  padding-right: 0 !important;
  padding-left: 2rem !important;
}
.rtl .gx-pl-5 {
  padding-left: 0 !important;
  padding-right: 2rem !important;
}
.rtl .gx-mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}
.rtl .gx-ml-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}
.rtl .gx-float-left {
  float: right !important;
}
.rtl .gx-float-right {
  float: left !important;
}
.rtl .gx-customizer-option {
  right: auto;
  left: 0;
}
.rtl .gx-customizer-option .ant-btn {
  -webkit-border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  border-radius: 0 30px 30px 0;
  margin-left: 0;
}
.rtl .gx-app-nav {
  padding-right: 0;
}
.rtl .gx-border-left {
  border-left: 0 none;
  border-right: solid 1px #e8e8e8;
}
.rtl .gx-border-right {
  border-right: 0 none;
  border-left: solid 1px #e8e8e8;
}
.rtl .gx-social-link {
  padding-right: 0;
}
.rtl .gx-left-0 {
  left: auto !important;
  right: 0 !important;
}
.rtl .gx-right-0 {
  right: auto !important;
  left: 0 !important;
}
.rtl .gx-status-pos .gx-status {
  left: auto;
  right: 0;
}
.rtl .gx-status-pos .gx-status.gx-status-rtl {
  right: auto;
  left: -4px;
}
@media screen and (max-width: 991px) {
  .rtl .framed-layout .ant-drawer-left {
    right: 0;
    left: auto;
  }
}
@media screen and (max-width: 991px) {
  .rtl .framed-layout .ant-drawer-right {
    left: 0;
    right: auto;
  }
}
.rtl .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  -webkit-border-radius: 11px 0 0 11px;
  -moz-border-radius: 11px 0 0 11px;
  border-radius: 11px 0 0 11px;
}
.rtl .ant-drawer-right .ant-drawer-content-wrapper {
  right: auto;
  left: 0;
  transform: translateX(-100%) !important;
}
.rtl .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  transform: translateX(0) !important;
}
.rtl .ant-drawer-left .ant-drawer-content-wrapper {
  right: 0;
  left: auto;
  transform: translateX(100%) !important;
}
.rtl .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  transform: translateX(0) !important;
}
.rtl .ant-rate,
.rtl .ant-menu-rtl,
.rtl .ant-list-items,
.rtl .ant-menu-rtl ul,
.rtl .ant-list-item-action,
.rtl .ant-transfer-list-content {
  padding-right: 0 !important;
}
.rtl .ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 30px;
  padding-right: 20px;
}
.rtl .ant-menu-item .icon {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .ant-menu-inline,
.rtl .ant-menu-vertical,
.rtl .ant-menu-vertical-left {
  border-right: 0 none;
  border-left: 1px solid #e8e8e8;
}
.rtl .ant-layout-sider .ant-menu-inline,
.rtl .ant-layout-sider .ant-menu-vertical,
.rtl .ant-layout-sider .ant-menu-vertical-left,
.rtl .gx-drawer-sidebar .ant-menu-inline,
.rtl .gx-drawer-sidebar .ant-menu-vertical,
.rtl .gx-drawer-sidebar .ant-menu-vertical-left {
  border-left: 0 none;
}
.rtl .ant-menu-vertical-right {
  border-right: 0 none;
  border-left: 1px solid #e8e8e8;
}
.rtl .ant-menu-sub.ant-menu-inline {
  border-left: none !important;
}
.rtl .ant-menu-item .anticon,
.rtl .ant-menu-submenu-title .anticon {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 30px !important;
  padding-right: 54px !important;
}
.rtl .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.rtl .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 30px !important;
  padding-right: 80px !important;
}
.rtl .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.rtl .ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 30px !important;
  padding-right: 106px !important;
}
.rtl .ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding-left: 30px !important;
  padding-right: 20px !important;
  text-align: right;
}
.rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.rtl .gx-app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .gx-app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .gx-app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .gx-app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: auto;
  right: 22px;
}
.rtl .gx-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .gx-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .gx-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: auto;
  right: 60px;
}
.rtl .ant-menu-vertical .ant-menu-item:after,
.rtl .ant-menu-vertical-left .ant-menu-item:after,
.rtl .ant-menu-vertical-right .ant-menu-item:after,
.rtl .ant-menu-inline .ant-menu-item:after {
  border-right: 0 none;
  border-left: 3px solid #038fde;
}
.rtl .ant-menu-vertical .ant-menu-item,
.rtl .ant-menu-vertical-left .ant-menu-item,
.rtl .ant-menu-vertical-right .ant-menu-item,
.rtl .ant-menu-inline .ant-menu-item,
.rtl .ant-menu-vertical .ant-menu-submenu-title,
.rtl .ant-menu-vertical-left .ant-menu-submenu-title,
.rtl .ant-menu-vertical-right .ant-menu-submenu-title,
.rtl .ant-menu-inline .ant-menu-submenu-title {
  padding: 0 20px 0 30px !important;
}
.rtl .ant-menu-vertical .ant-menu-item .icon,
.rtl .ant-menu-vertical-left .ant-menu-item .icon,
.rtl .ant-menu-vertical-right .ant-menu-item .icon,
.rtl .ant-menu-inline .ant-menu-item .icon,
.rtl .ant-menu-vertical .ant-menu-submenu-title .icon,
.rtl .ant-menu-vertical-left .ant-menu-submenu-title .icon,
.rtl .ant-menu-vertical-right .ant-menu-submenu-title .icon,
.rtl .ant-menu-inline .ant-menu-submenu-title .icon {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .ant-layout-sider .ant-menu-vertical .ant-menu-item,
.rtl .ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.rtl .ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.rtl .ant-layout-sider .ant-menu-inline .ant-menu-item,
.rtl .ant-drawer .ant-menu-vertical .ant-menu-item,
.rtl .ant-drawer .ant-menu-vertical-left .ant-menu-item,
.rtl .ant-drawer .ant-menu-vertical-right .ant-menu-item,
.rtl .ant-drawer .ant-menu-inline .ant-menu-item,
.rtl .ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.rtl .ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.rtl .ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
.rtl .ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 30px !important;
  padding-right: 40px !important;
}
.rtl .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.rtl .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
.rtl .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
.rtl .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
.rtl .ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
.rtl .ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
.rtl .ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
.rtl .ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
  padding: 0 calc((80px - 20px) / 2) !important;
}
.rtl .ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 30px !important;
  padding-right: 54px !important;
}
.rtl .ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-layout-sider.ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.rtl .ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 30px !important;
  padding-right: 80px !important;
}
.rtl .ant-menu-inline .ant-menu-submenu-title {
  padding-right: 20px !important;
  padding-left: 30px !important;
}
.rtl .ant-menu-item-group-list .ant-menu-item,
.rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.rtl .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 80px 0 30px;
}
.rtl .ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.rtl .ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.rtl .ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.rtl .ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item .icon {
  margin-right: 0;
  margin-left: 12px;
}
.rtl .ant-menu-dark .ant-menu-item .icon {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .ant-btn:not(:last-child) {
  margin-right: 0;
  margin-left: 15px;
}
@media screen and (max-width: 575px) {
  .rtl .ant-btn:not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
  }
}
.rtl .ant-btn-group .ant-btn:not(:last-child),
.rtl .ant-transfer-operation .ant-btn:not(:last-child) {
  margin-left: 0;
}
@media (max-width: 1599px) {
  .rtl .gx-product-footer .ant-btn:not(:last-child) {
    margin-right: 0;
    margin-left: 6px;
  }
}
.rtl .ant-radio-button-wrapper:last-child {
  border-radius: 6px 0 0 6px;
}
.rtl .ant-radio-button-wrapper:first-child {
  border-radius: 0 6px 6px 0;
}
.rtl .ant-table-thead > tr > th,
.rtl .ant-table table {
  text-align: right;
}
.rtl .ant-table-pagination.ant-pagination {
  float: left;
}
.rtl .ant-pagination-item,
.rtl .ant-pagination-prev,
.rtl .ant-pagination-jump-prev,
.rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.rtl .anticon-left,
.rtl .anticon-right {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rtl .ant-table-thead > tr > th.ant-table-selection-column,
.rtl .ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.rtl .ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: 0;
  margin-left: -15px;
}
.rtl .ant-table-selection-select-all-custom {
  margin-right: 0 !important;
  margin-left: 4px !important;
}
.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 16px !important;
  padding-left: 30px !important;
}
.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-has-filters {
  padding-right: 16px !important;
  padding-left: 54px !important;
}
.rtl .ant-table-thead > tr > th .ant-table-column-sorter {
  right: auto;
  left: 6px;
}
.rtl .ant-table-thead > tr > th.ant-table-column-has-filters .ant-table-column-sorter {
  right: auto;
  left: 34px;
}
.rtl .ant-table-thead > tr > th .anticon-filter,
.rtl .ant-table-thead > tr > th .ant-table-filter-icon {
  right: auto;
  left: 0;
}
.rtl .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 8px;
  padding-left: 0;
}
.rtl .ant-table-filter-dropdown-link.confirm {
  float: right;
}
.rtl .ant-table-filter-dropdown-link.clear {
  float: left;
}
.rtl .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.rtl .ant-table-fixed-left {
  left: auto;
  right: 0;
  -webkit-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.rtl .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.rtl .ant-table-fixed-right {
  right: auto;
  left: 0;
  -webkit-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.rtl .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.rtl .ant-select-selection-selected-value {
  float: right;
  padding-right: 0;
  padding-left: 20px;
}
.rtl .ant-select-arrow {
  right: auto;
  left: 11px;
}
.rtl .ant-badge:not(:last-child),
.rtl .gx-badge:not(:last-child) {
  margin-right: 0;
  margin-left: 12px;
}
.rtl .gx-user-list .gx-avatar-img {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .gx-user-list .gx-link[class*="gx-meta-"] i {
  margin-right: 0;
  margin-left: 6px;
}
.rtl .gx-user-list .gx-btn-list li:not(:last-child) {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .gx-card-list .gx-card-list-footer {
  padding-left: 0;
  padding-right: 15px;
}
.rtl .gx-card-list .gx-card-list-footer .ant-btn {
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  .rtl .gx-card-list .gx-card-list-footer {
    padding-left: 0;
    padding-right: 0;
  }
  .rtl .gx-card-list .gx-card-list-footer .ant-btn {
    margin-left: 12px;
    margin-right: 0;
  }
}
.rtl .ant-timeline {
  padding-right: 0;
}
.rtl .ant-timeline-item-head-custom {
  margin-right: 0;
  right: 0;
  left: auto;
}
.rtl .gx-timeline-panel::before {
  border-width: 10px 0 10px 17px;
  left: auto;
  right: -18px;
}
.rtl .gx-timeline-panel::after {
  border-width: 10px 0 10px 16px;
  left: auto;
  right: -16px;
}
.rtl .gx-timeline-item {
  padding-right: 80px;
  padding-left: 0;
}
.rtl .gx-timeline-item::before {
  right: 25px;
  left: auto;
}
.rtl .gx-timeline-badge {
  right: 0;
  left: auto;
}
.rtl .gx-timeline-badge .icon {
  display: inline !important;
}
.rtl .gx-timeline-center .gx-timeline-item {
  float: left;
  padding-left: 0;
  padding-right: 55px;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-item {
    float: none;
    padding-right: 80px;
  }
}
.rtl .gx-timeline-center .gx-timeline-item .gx-timeline-badge {
  right: -25px;
  left: auto;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-item .gx-timeline-badge {
    right: 0;
  }
}
.rtl .gx-timeline-center .gx-timeline-item::before {
  right: 0;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-item::before {
    right: 25px;
  }
}
.rtl .gx-timeline-center .gx-timeline-time {
  left: auto;
  right: -150px;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-time {
    right: auto;
  }
}
.rtl .gx-timeline-center .gx-timeline-inverted {
  float: right;
  padding-left: 55px;
  padding-right: 0;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-inverted {
    float: none;
    padding-left: 0;
    padding-right: 80px;
    text-align: right;
  }
}
.rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-badge {
  left: -25px;
  right: auto;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-badge {
    left: auto;
    right: 0;
  }
}
.rtl .gx-timeline-center .gx-timeline-inverted::before {
  left: -2px;
  right: auto;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-inverted::before {
    left: auto;
    right: 25px;
  }
}
.rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel::before {
  border-width: 10px 16px 10px 0;
  left: -18px;
  right: auto;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel::before {
    border-width: 10px 0 10px 17px !important;
    right: -18px;
    left: auto;
  }
}
.rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel::after {
  border-width: 10px 0 10px 16px;
  left: -16px;
  right: auto;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel::after {
    right: -16px;
    left: auto;
    border-width: 10px 0 10px 16px !important;
  }
}
.rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-time {
  left: -150px;
  right: auto;
}
@media screen and (max-width: 767px) {
  .rtl .gx-timeline-center .gx-timeline-inverted .gx-timeline-time {
    left: auto;
  }
}
.rtl .gx-timeline-left {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}
.rtl .gx-timeline-right {
  float: left;
  margin-right: 20px;
  margin-left: 0;
}
.rtl .gx-customizer > div:first-child,
.rtl .gx-wall-scroll > div:first-child,
.rtl .gx-popover-scroll > div:first-child,
.rtl .gx-popover-lang-scroll > div:first-child,
.rtl .gx-module-side-scroll > div:first-child,
.rtl .gx-module-content-scroll > div:first-child,
.rtl .gx-layout-sider-scrollbar > div:first-child,
.rtl .gx-chat-sidenav-scroll-tab-1 > div:first-child,
.rtl .gx-chat-sidenav-scroll-tab-2 > div:first-child {
  margin-left: -17px;
  margin-right: 0 !important;
}
.rtl .gx-customizer > div:last-child,
.rtl .gx-wall-scroll > div:last-child,
.rtl .gx-popover-scroll > div:last-child,
.rtl .gx-popover-lang-scroll > div:last-child,
.rtl .gx-module-side-scroll > div:last-child,
.rtl .gx-module-content-scroll > div:last-child,
.rtl .gx-layout-sider-scrollbar > div:last-child,
.rtl .gx-chat-sidenav-scroll-tab-1 > div:last-child,
.rtl .gx-chat-sidenav-scroll-tab-2 > div:last-child {
  right: auto !important;
  left: 2px;
}
.rtl .icon:not(.gx-icon-btn) {
  display: inline-block;
  vertical-align: middle;
}
.rtl .ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}
.rtl .icon-ellipse-v::before {
  content: "\7b";
}
.rtl .icon-editor::before {
  content: "\7d";
}
.rtl .gx-module-sidenav {
  border-left: solid 1px #e8e8e8;
  border-right: none;
}
.rtl .gx-module-list-icon {
  padding-right: 0;
  padding-left: 10px;
}
.rtl .gx-module-nav {
  padding-right: 0;
}
.rtl .gx-module-nav li i {
  margin-left: 16px;
  margin-right: 0;
}
.rtl .gx-module-box-topbar label.ant-checkbox-wrapper,
.rtl .gx-module-box-topbar .ant-dropdown-trigger {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .gx-mail-list-info {
  padding-right: 10px;
  padding-left: 0;
}
@media screen and (max-width: 575px) {
  .rtl .gx-mail-list-info {
    padding-right: 0;
  }
}
.rtl .gx-chat-sidenav {
  border-left: solid 1px #e8e8e8;
  border-right: none;
}
.rtl .gx-profile-banner-avatar {
  margin-right: 0;
  margin-left: 1.5rem;
}
@media (max-width: 575px) {
  .rtl .gx-profile-banner-avatar {
    margin-left: 0;
  }
}
.rtl .gx-profile-banner-top-right {
  margin-right: auto;
  margin-left: 0;
}
.rtl .gx-profile-banner .gx-navbar-nav {
  padding-right: 0;
}
.rtl .gx-profile-banner .gx-follower-list {
  padding-right: 0;
}
.rtl .gx-profile-banner .gx-follower-list li:not(:first-child) {
  border-left: none;
  border-right: solid 1px #ffffff;
}
.rtl .gx-profile-setting {
  margin-left: 0;
  margin-right: auto;
}
.rtl .gx-algolia-sidebar ul {
  padding-right: 0;
}
.rtl .gx-algolia-sidebar .ais-RefinementList-checkbox {
  margin-left: 12px;
  margin-right: 0;
}
.rtl .gx-algolia-sidebar .ais-RefinementList-labelText {
  display: inline-block;
  vertical-align: top;
}
.rtl .gx-algolia-main {
  padding-right: 30px;
  padding-left: 0;
}
@media screen and (max-width: 767px) {
  .rtl .gx-algolia-main {
    padding-right: 0;
  }
}
.rtl .gx-algolia-sort-by {
  margin-right: auto;
  margin-left: 0;
}
.rtl .gx-algolia-sort-by label {
  margin-right: 0;
  margin-left: 15px;
}
.rtl .gx-algolia-header .gx-lt-icon-search-bar {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .ais-RangeInput-submit {
  margin-right: 0.3rem;
  margin-left: 0;
}
.rtl .gx-testimonial-des {
  padding-right: 30px;
  padding-left: 0;
}
.rtl .gx-testimonial-des:before {
  left: auto;
  right: 0;
}
.rtl .slick-slider .gx-slide-item {
  direction: rtl;
}
.rtl .login-form-forgot {
  float: left;
}
.rtl .ant-input-number {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .ant-input-group > [class*="col-"] {
  float: right !important;
  padding-left: 8px;
  padding-right: 0;
}
.rtl .ant-input-group > [class*="col-"]:last-child {
  padding-left: 0;
}
.rtl .gx-search-bar:not(.gx-lt-icon-search-bar-lg) input[type="search"] {
  padding-left: 35px;
  padding-right: 9px;
}
.rtl .gx-search-bar:not(.gx-lt-icon-search-bar-lg) .gx-search-icon {
  right: auto;
  left: 0;
}
.rtl .gx-header-search .ant-select-selection,
.rtl .gx-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.rtl .gx-header-search .gx-lt-icon-search-bar-lg input[type="search"] {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.rtl .ant-input-group > .ant-input:last-child,
.rtl .ant-input-group-addon:last-child {
  border-radius: 6px 0 0 6px;
}
.rtl .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 6px 0 0 6px;
}
.rtl .rbc-btn-group button + button {
  margin-right: -1px;
  margin-left: 0;
}
.rtl .rbc-btn-group button:last-child:not(:first-child) {
  border-radius: 4px 0 0 4px;
}
.rtl .rbc-btn-group button:first-child:not(:last-child) {
  border-radius: 0 4px 4px 0;
}
.rtl .rbc-header + .rbc-header,
.rtl .rbc-day-bg + .rbc-day-bg {
  border-right: 1px solid #DDD;
  border-left: none;
}
.rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-right: 1px solid #DDD;
}
.rtl .gx-entry-title::before {
  right: 0;
  left: auto;
}
.rtl .ant-btn {
  margin-left: 8px;
  margin-right: 0;
}
.rtl .ant-btn-group:not(:last-child) {
  margin-left: 15px;
  margin-right: 0;
}
.rtl .ant-menu {
  text-align: right;
}
.rtl .ant-dropdown-menu {
  padding-right: 4px;
}
.rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.rtl .ant-pagination,
.rtl .ant-cascader-menu,
.rtl .ant-menu-item-group-list {
  padding-right: 0;
}
.rtl .gx-package-items li i {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.rtl .gx-product-row {
  text-align: left;
}
.rtl .gx-product-row.even {
  text-align: right;
}
.rtl .gx-product-row .ant-btn {
  margin-left: 0;
}
.rtl .amcharts-export-menu ul {
  padding-right: 0;
}
.rtl .amcharts-export-menu .export-main > a,
.rtl .amcharts-export-menu .export-drawing > a,
.rtl .amcharts-export-menu .export-delayed-capturing > a {
  text-indent: 13333337px;
}
.rtl .public-DraftStyleDefault-ltr {
  direction: rtl;
  text-align: right;
}
.rtl .rdw-dropdown-carettoopen {
  left: 10%;
  right: auto;
}
.rtl .gx-dragndrop-item .gx-draggable-icon {
  margin-right: -16px;
  margin-left: 0;
}
.rtl .ant-select-dropdown-menu,
.rtl .gx-fnd-gallery-list,
.rtl .gx-agents-list,
.rtl .gx-sub-popover,
.rtl .gx-user-popover,
.rtl .gx-app-nav {
  padding-right: 0;
}
.rtl .gx-form-inline-label-up .ant-form-item-label {
  text-align: right;
}
.rtl .ant-form-rtl.ant-form-inline .ant-form-item {
  margin-left: 0;
}
.rtl .gx-news-thumb {
  margin-right: 0;
  margin-left: 25px;
}
@media (max-width: 1366px) {
  .rtl .gx-news-thumb {
    margin-right: 0;
    margin-left: 15px;
  }
}
@media (max-width: 575px) {
  .rtl .gx-news-thumb {
    margin-left: 0;
  }
}
.rtl .gx-news-tags-right {
  padding-left: 0;
  padding-right: 8px;
  text-align: left;
}
@media screen and (max-width: 575px) {
  .rtl .gx-card-ticketlist .gx-task-item-content {
    padding-left: 0;
  }
}
.rtl .gx-task-item-content .gx-task-date {
  text-align: left;
}
@media screen and (max-width: 575px) {
  .rtl .gx-task-item-content .gx-task-date {
    right: auto;
    left: 0;
  }
}
@media screen and (max-width: 575px) {
  .rtl .gx-task-item-content-left {
    padding-right: 0;
    padding-left: 60px;
  }
}
@media screen and (max-width: 575px) {
  .rtl .gx-card-ticketlist .gx-task-item-content-left {
    padding-left: 0;
  }
}
.rtl .gx-task-item-content-right {
  padding-left: 0;
  padding-right: 10px;
}
@media screen and (max-width: 575px) {
  .rtl .gx-task-item-content-right {
    padding-right: 0;
  }
}
.rtl .gx-card-testimonial-img {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .gx-card-testimonial-content {
  margin-left: 0;
  margin-right: 10px;
}
.rtl .gx-card-testimonial-content:before {
  left: auto;
  right: -10px;
  border-right: 0 none;
  border-left: 10px solid #f5f5f5;
}
.rtl .gx-progress-task-list-content {
  margin-left: 0;
  margin-right: 20px;
}
.rtl .gx-act-task-content label.ant-checkbox-wrapper span.ant-checkbox {
  float: right;
  padding-right: 0;
  padding-left: 5px;
}
.rtl .gx-act-task-btn-view {
  margin-left: 0;
  margin-right: 8px;
  text-align: left;
}
@media screen and (max-width: 575px) {
  .rtl .gx-act-task-btn-view {
    margin-right: 0;
  }
}
.rtl .gx-line-indicator {
  padding-right: 0;
}
.rtl .gx-line-indicator-col {
  padding-right: 0;
  padding-left: 10px;
}
@media screen and (max-width: 767px) {
  .rtl .gx-line-indicator-col {
    padding-left: 0;
  }
}
.rtl .gx-overview-description {
  margin-left: -24px;
  margin-right: 0;
  border-left: 0 none;
  border-right: solid 1px #e8e8e8;
}
@media screen and (max-width: 767px) {
  .rtl .gx-overview-description {
    border-right: 0 none !important;
  }
}
.rtl .gx-revenu-col:not(:last-child) {
  border-right: 0 none;
  border-left: solid 1px #e8e8e8;
}
.rtl .gx-rechart-prod {
  left: 0;
  right: 30px;
}
@media (max-width: 1366px) {
  .rtl .gx-rechart-prod {
    left: 0;
    right: 20px;
  }
}
.rtl .gx-dot-arrow {
  right: auto;
  left: 0;
  padding-right: 0;
  padding-left: 10px;
}
.rtl .gx-hover-arrow {
  left: 0;
  right: 15px;
  -webkit-border-radius: 0 50% 50% 0;
  -moz-border-radius: 0 50% 50% 0;
  border-radius: 0 50% 50% 0;
}
.rtl .gx-dot-arrow-hover:hover .gx-hover-arrow {
  left: auto;
  right: -15px;
}
.rtl .gx-user-wid img {
  left: auto;
  right: 0;
}
.rtl .gx-widget-badge {
  right: auto;
  left: 0;
  -webkit-border-radius: 10px 0 10px 0;
  -moz-border-radius: 10px 0 10px 0;
  border-radius: 10px 0 10px 0;
}
.rtl .gx-fnd-content {
  padding-right: 0;
  padding-left: 40px;
}
@media (max-width: 1366px) {
  .rtl .gx-fnd-content {
    padding-left: 0;
  }
}
.rtl .gx-fillchart-content {
  left: auto;
  right: 0;
}
.rtl .gx-fillchart .gx-chart-up,
.rtl .gx-fillchart .gx-chart-down {
  margin-right: 0;
  margin-left: 6px;
}
.rtl .gx-fillchart-btn,
.rtl .gx-fillchart-btn-close {
  right: 0;
  left: 10px;
}
.rtl .gx-fillchart-nocontent .ant-card-head-title {
  left: 0;
  right: 20px;
}
.rtl .gx-featured-thumb {
  margin-right: 0;
  margin-left: 25px;
}
@media (max-width: 575px) {
  .rtl .gx-featured-thumb {
    margin-left: 0;
  }
}
.rtl .gx-featured-thumb .ant-tag {
  left: auto;
  right: 15px;
}
.rtl .gx-featured-content-right {
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}
@media (max-width: 767px) {
  .rtl .gx-featured-content-right {
    margin-right: 0;
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .rtl .gx-card-profile .gx-featured-content-right {
    text-align: right;
    margin-right: 0;
  }
}
.rtl .gx-currentplan-right {
  border-left: 0 none;
  border-right: solid 1px #e8e8e8;
}
@media (max-width: 1366px) {
  .rtl .gx-currentplan-right {
    border-right: 0 none !important;
  }
}
@media (max-width: 991px) {
  .rtl .gx-currentplan-right {
    border-left: 0 none !important;
    border-right: solid 1px #e8e8e8 !important;
  }
}
@media (max-width: 575px) {
  .rtl .gx-currentplan-right {
    border-right: 0 none !important;
  }
}
.rtl .gx-currentplan-right-content {
  text-align: left;
}
@media (max-width: 1366px) {
  .rtl .gx-currentplan-right-content {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .rtl .gx-currentplan-right-content {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .rtl .gx-currentplan-right-content {
    text-align: right;
  }
}
.rtl .gx-dealclose-header-right {
  margin-left: 0;
  margin-right: auto;
}
@media (max-width: 1366px) {
  .rtl .gx-dealclose-header-right {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .rtl .gx-dealclose-header-right {
    margin-left: 0;
    margin-right: auto;
  }
}
@media (max-width: 575px) {
  .rtl .gx-dealclose-header-right {
    margin-right: 0;
  }
}
@media screen and (max-width: 575px) {
  .rtl .gx-dash-search {
    padding-right: 0;
    padding-left: 30px;
  }
  .rtl .gx-dash-search .gx-search-icon {
    right: auto;
    left: 0;
  }
}
.rtl .gx-bg-dark-primary:before {
  left: auto;
  right: 0;
}
.rtl .icon-long-arrow-right:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rtl .icon-search-new:before,
.rtl .icon-search:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
}
.rtl span.gx-chart-up,
.rtl span.gx-chart-down {
  display: inline-block;
}
.rtl .gx-slick-slider-dot-top {
  padding-left: 24px;
  padding-right: 0;
}
@media screen and (max-width: 575px) {
  .rtl .gx-slick-slider-dot-top {
    padding-right: 24px;
  }
}
.rtl .gx-customizer-option {
  right: auto;
  left: 0;
}
.rtl .full-scroll.framed-layout .gx-customizer-option {
  right: auto;
  left: 20px;
}
@media screen and (max-width: 991px) {
  .rtl .full-scroll.framed-layout .gx-customizer-option {
    left: 0;
  }
}
.rtl .gx-color-option {
  padding-right: 0;
}
.rtl .gx-color-option li .gx-link:before,
.rtl .gx-color-option li .gx-link:after {
  left: auto;
  right: 0;
}
.rtl .gx-cus-customiz {
  padding-right: 0;
  padding-left: 20px;
}
.rtl .gx-header-notifications {
  padding-right: 0;
}
.rtl .gx-header-notifications > li:not(:last-child) {
  margin-right: 0;
  margin-left: 42px;
}
@media screen and (max-width: 991px) {
  .rtl .gx-header-notifications > li:not(:last-child) {
    margin-right: 0;
    margin-left: 16px;
  }
}
.rtl .gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.rtl .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 0;
  margin-left: 30px;
}
@media screen and (max-width: 991px) {
  .rtl .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .rtl .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 0;
    margin-left: 16px;
  }
}
.rtl .gx-header-notifications > li.gx-notify {
  margin-right: 0;
  margin-left: 22px;
}
@media screen and (max-width: 991px) {
  .rtl .gx-header-notifications > li.gx-notify {
    margin-right: 0;
    margin-left: 16px;
  }
}
.rtl .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 0;
  margin-left: 30px;
}
@media screen and (max-width: 991px) {
  .rtl .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 0;
    margin-left: 16px;
  }
}
.rtl .gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 0;
  margin-left: 20px;
}
.rtl .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 0;
  margin-right: 50px;
}
@media screen and (max-width: 991px) {
  .rtl .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-right: 0;
  }
}
@media screen and (max-width: 1199px) {
  .rtl .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-right: 0;
  }
}
.rtl .gx-header-horizontal-dark:before {
  right: auto;
  left: 169px;
}
@media screen and (max-width: 1049px) {
  .rtl .gx-header-horizontal-dark:before {
    right: auto;
    left: 110px;
  }
}
@media screen and (max-width: 1199px) {
  .rtl .gx-header-horizontal-dark:before {
    right: auto;
    left: 80px;
  }
}
@media screen and (max-width: 991px) {
  .rtl .gx-header-horizontal-dark:before {
    right: auto;
    left: 140px;
  }
}
@media screen and (max-width: 575px) {
  .rtl .gx-header-horizontal-dark:before {
    right: auto;
    left: 60px;
  }
}
.rtl .gx-header-horizontal-top-left {
  margin-right: 0;
  margin-left: 15px;
}
@media screen and (max-width: 767px) {
  .rtl .gx-header-horizontal-top-left {
    margin-left: 0;
  }
}
@media screen and (max-width: 575px) {
  .rtl .gx-header-horizontal-main .gx-w-logo {
    padding-left: 0;
  }
}
.rtl .gx-inside-header-horizontal .gx-logo {
  margin-right: 0;
  margin-left: 25px;
}
.rtl .gx-login-list {
  margin-left: 0;
  margin-right: auto;
  padding-right: 0;
}
.rtl .gx-login-list li:not(:first-child) {
  border-left: 0 none;
  border-right: solid 1px #e8e8e8;
}
.rtl .gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
.rtl .gx-header-search {
  margin-left: 0;
  margin-right: 28px;
}
.rtl .gx-header-horizontal-dark .gx-header-search .ant-select-selection {
  margin-left: 0;
  margin-right: 1px;
}
.rtl .gx-popover-horizantal .ant-btn {
  border-right: 0 none;
  border-left: solid 1px #e8e8e8;
}
@media screen and (max-width: 575px) {
  .rtl .gx-popover-horizantal {
    left: auto !important;
    right: 0 !important;
  }
  .rtl .gx-popover-horizantal .gx-sub-popover {
    padding-left: 0;
    padding-right: 5px;
  }
}
.rtl .gx-no-header-horizontal {
  padding-right: 51px;
  padding-left: 18px;
}
.rtl .gx-no-header-horizontal .gx-linebar {
  left: auto;
  right: 18px;
}
@media screen and (max-width: 575px) {
  .rtl .gx-no-header-horizontal .gx-linebar {
    left: auto;
    right: 15px;
  }
}
@media screen and (max-width: 575px) {
  .rtl .gx-no-header-horizontal {
    padding-right: 60px;
    padding-left: 15px;
  }
}
.rtl .gx-layout-sider-header {
  padding-right: 70px;
  padding-left: 30px;
}
.rtl .gx-layout-sider-header .gx-linebar {
  left: auto;
  right: 20px;
}
@media screen and (max-width: 991px) {
  .rtl .gx-layout-sider-header {
    padding-right: 30px;
  }
}
.rtl .ant-layout-sider-collapsed .gx-layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.rtl .gx-drawer-sidebar .gx-layout-sider-header,
.rtl .gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.rtl .gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.rtl .gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  border-right: 1px solid #e8e8e8;
}
.rtl .ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: auto;
  right: 20px;
}
.rtl .gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.rtl .gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  border-right: 0 none;
  border-left: 1px solid #e8e8e8;
}
.rtl .gx-sidebar-content {
  border-right: 0 none;
  border-left: 1px solid #e8e8e8;
}
.rtl .gx-layout-sider-dark .gx-sidebar-content,
.rtl .gx-drawer-sidebar-dark .gx-sidebar-content {
  border-left: 0 none;
}
.rtl .gx-avatar-row .ant-avatar {
  left: auto;
  right: 0;
}
.rtl .ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  left: auto;
  right: -10px;
}
.rtl .gx-list-group,
.rtl .gx-dot-list,
.rtl .gx-fnd-list,
.rtl .gx-follower-list,
.rtl .gx-gallery-list,
.rtl .gx-package-items,
.rtl .gx-list-inline {
  padding-right: 0;
}
.rtl .gx-list-group li i,
.rtl .gx-list-group li .anticon {
  margin-left: 12px;
  margin-right: 0;
}
.rtl .gx-dot-list {
  margin-right: -3px !important;
}
.rtl .gx-text-left {
  text-align: right !important;
}
.rtl .gx-text-right {
  text-align: left !important;
}
@media (min-width: 576px) {
  .rtl .gx-mr-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .gx-ml-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .gx-mr-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .gx-ml-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .gx-mr-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .gx-ml-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .gx-mr-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .gx-ml-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .gx-mr-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .gx-ml-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .gx-pr-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .gx-pl-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .gx-pr-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .gx-pl-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .gx-pr-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .gx-pl-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .gx-pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .rtl .gx-pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .rtl .gx-pr-sm-5 {
    padding-right: 0 !important;
    padding-left: 2rem !important;
  }
  .rtl .gx-pl-sm-5 {
    padding-left: 0 !important;
    padding-right: 2rem !important;
  }
  .rtl .gx-mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .gx-ml-sm-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  .rtl .gx-float-sm-left {
    float: right !important;
  }
  .rtl .gx-float-sm-right {
    float: left !important;
  }
}
@media (min-width: 768px) {
  .rtl .gx-mr-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .gx-ml-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .gx-mr-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .gx-ml-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .gx-mr-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .gx-ml-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .gx-mr-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .gx-ml-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .gx-mr-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .gx-ml-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .gx-pr-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .gx-pl-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .gx-pr-md-2,
  .rtl .gx-px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .gx-pl-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .gx-pr-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .gx-pl-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .gx-pr-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .gx-pl-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .gx-pr-md-5 {
    padding-right: 0 !important;
    padding-left: 2rem !important;
  }
  .rtl .gx-pl-md-5 {
    padding-left: 0 !important;
    padding-right: 2rem !important;
  }
  .rtl .gx-mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .gx-ml-md-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  .rtl .gx-float-md-left {
    float: right !important;
  }
  .rtl .gx-float-md-right {
    float: left !important;
  }
  .rtl .ant-col-rtl.ant-col-md-15.gx-pr-md-2 {
    padding-right: 16px !important;
  }
}
@media (min-width: 992px) {
  .rtl .gx-mr-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .gx-ml-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .gx-mr-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .gx-ml-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .gx-mr-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .gx-ml-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .gx-mr-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .gx-ml-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .gx-mr-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .gx-ml-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .gx-pr-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .gx-pl-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .gx-pr-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .gx-pl-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .gx-pr-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .gx-pl-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .gx-pr-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .gx-pl-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .gx-pr-lg-5 {
    padding-right: 0 !important;
    padding-left: 2rem !important;
  }
  .rtl .gx-pl-lg-5 {
    padding-left: 0 !important;
    padding-right: 2rem !important;
  }
  .rtl .gx-mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .gx-ml-lg-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  .rtl .gx-float-lg-left {
    float: right !important;
  }
  .rtl .gx-float-lg-right {
    float: left !important;
  }
}
@media (min-width: 1200px) {
  .rtl .gx-mr-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .gx-ml-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .gx-mr-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .gx-ml-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .gx-mr-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .gx-ml-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .gx-mr-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .gx-ml-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .gx-mr-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .gx-ml-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .gx-pr-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .gx-pl-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .gx-pr-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .gx-pl-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .gx-pr-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .gx-pl-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .gx-pr-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .gx-pl-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .gx-pr-xl-5 {
    padding-right: 0 !important;
    padding-left: 2rem !important;
  }
  .rtl .gx-pl-xl-5 {
    padding-left: 0 !important;
    padding-right: 2rem !important;
  }
  .rtl .gx-mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .gx-ml-xl-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  .rtl .gx-float-xl-left {
    float: right !important;
  }
  .rtl .gx-float-xl-right {
    float: left !important;
  }
}
@media (min-width: 1368px) {
  .rtl .gx-mr-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  .rtl .gx-ml-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  .rtl .gx-mr-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  .rtl .gx-ml-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  .rtl .gx-mr-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  .rtl .gx-ml-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .rtl .gx-mr-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }
  .rtl .gx-ml-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }
  .rtl .gx-mr-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }
  .rtl .gx-ml-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }
  .rtl .gx-pr-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  .rtl .gx-pl-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  .rtl .gx-pr-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  .rtl .gx-pl-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  .rtl .gx-pr-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .rtl .gx-pl-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  .rtl .gx-pr-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
  .rtl .gx-pl-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .rtl .gx-pr-xxl-5 {
    padding-right: 0 !important;
    padding-left: 2rem !important;
  }
  .rtl .gx-pl-xxl-5 {
    padding-left: 0 !important;
    padding-right: 2rem !important;
  }
  .rtl .gx-mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .rtl .gx-ml-xxl-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
}
