.web-dashboard-card {
  width: 100%;
  margin-bottom: 0px !important;

  @media screen and (max-width: @screen-sm-max) {
    width: 100%;
  }
}

.web-subpanel {
  flex-direction: column;
  padding-top: 56px;
  min-width: 380px;

  @media screen and (max-width: @screen-sm-max) {
    flex-direction: column;
    height: auto !important;
    padding-top: 0px;
    width: 100%;
  }
}

.web-messages {
  height: calc(100% - 318px);

  @media screen and (max-width: @screen-sm-max) {
    height: 400px;
    width: 100% !important;
  }
}

.bott-messages {
  height: 720px;
}

.message-details {
  @media screen and (max-width: @screen-sm-max) {
    width: 100%;
  }
}

.message-list.with-selected-message {
  width: 0%;
  min-width: 0px;

  @media screen and (max-width: @screen-sm-max) {
    width: 0%;
    min-width: 0px;
  }
}

.colored-mesage-title {
  @media screen and (max-width: @screen-sm-max) {
    display: none;
  }
}

.web-index-container {
  display: flex;
  gap: 20px;

  @media screen and (max-width: @screen-sm-max) {
    height: auto !important;
    max-height: 100% !important;
    flex-direction: column;
  }
}

.web-chart-parent {
  width: calc(100% - 350px);
  min-width: calc(100% - 400px);
  height: "100%";
  padding-top: "15px";
  position: relative;

  @media screen and (max-width: @screen-sm-max) {
    width: 100%;
    min-height: 500px;
  }
}

.multi-chart-switch {
  display: flex;
  float: right;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: @screen-sm-max) {
    display: none;
  }

  margin-top: 5px;
}

.gauge-container {
  @media screen and (max-width: @screen-sm-max) {
    grid-template-columns: 1fr !important;
  }
}

.web-chart-container {
  padding-top: 5px;

  .ant-card {
    margin-bottom: 5px !important;
  }

  @media screen and (max-width: @screen-sm-max) {
    height: 500px !important;
  }
}

.web-hide-index {
  position: absolute;
  bottom: 68px;
  right: 105px;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 5px 20px;
  color: #6e6e6e;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    background-color: rgb(0 0 0 / 15%);
  }

  @media screen and (max-width: @screen-sm-max) {
    display: none;
  }
}

.web-chart-parent .layout-splitter {
  background-color: #e4e4e4 !important;
  height: 3px !important;
}

.web-chart-parent .layout-splitter:hover {
  background-color: #aaa !important;
}

.chart-menu-buttons {
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media screen and (max-width: @screen-sm-max) {
    width: 100%;
    overflow: scroll;
  }
}